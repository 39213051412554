import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonMenu,
  IonMenuToggle,
  IonFooter,
  IonToggle,
  IonSelect,
  IonSelectOption,
  // IonSplitPane,
  IonIcon,
  IonRouterLink,
  IonModal,
  // IonPicker,
  IonButtons,
  IonButton,
  // IonPickerColumn,
  // IonPickerColumnOption,
  IonTextarea,
  IonLoading,
  IonToast,
  IonInput
} from '@ionic/react';

// import { Keyboard } from '@capacitor/keyboard';
// import { isPlatform } from '@ionic/core';
import newlogo from '../images/logomarktwo.png';
import { arrowForwardOutline, chatbubbleEllipsesOutline, chevronBack, chevronForward, cloudUploadOutline, locationOutline, settingsOutline } from 'ionicons/icons';

import { database } from '../lib/init-firebase';
import { ref, update, push } from 'firebase/database';

// import { useLocation } from 'react-router-dom';
import './Menu.css';
import '../theme/splash.css';
import '../theme/notifications.css';



const MIN_LOADING_DURATION = 1000; // 1 second in milliseconds


const Menu: React.FC<{
  // gridNo: any;
  // pickerIsOpen:any;
  // setPickerIsOpen:any;
  // closePickerSelectCity:any;
  // cityList:any;
  isWeb: any;
  citySelected: any;
  cityListN: any;
  citiesNotifs: any;
  handleToggleCity: any;
  openPickerSelectCity: any;
  setDefCity: any;
  defCity: any;
  parties: any;
  // festivals: any;
  barsAndClubs: any;
  saunas: any;
  festivals: any;
  festivalsCirc: any;
  festivalsPr: any;
  people: any;
  dragExpos: any;
  dragTours: any;
  cruises: any;
}> = ( props ) => {

  const location = useLocation();

  // const modalPicker = useRef<HTMLIonModalElement>(null);


  const [settingsIsOpen, setSettingsIsOpen] = useState(false);
  // const [menuType, setMenuType] = useState("main");
  const [isModalFB, setIsModalFB] = useState(false);

  const [isModalEv, setIsModalEv] = useState(false);

  // const [pickerVal, setPickerVal] = useState<any>("");
  // const [appFeedback, setAppFeedback] = useState("");
  // const [isLoadingFB, setIsLoadingFB] = useState(false);
  // const [alertFB, setAlertFB] = useState({ message: '', type: '' });

  const [message, setMessage] = useState('');
  const [messageEv, setMessageEv] = useState('');
  const [emailEv, setEmailEv] = useState('');


  // const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastColor, setToastColor] = useState('');


  // const [keyboardHeight, setKeyboardHeight] = useState(0);


  const modalAddFeedback = useRef<HTMLIonModalElement>(null);
  const modalAddEvent = useRef<HTMLIonModalElement>(null);


  // useEffect(() => {
  //   if (isPlatform('ios')) {
  //     Keyboard.addListener('keyboardWillShow', (info) => {
  //       setKeyboardHeight(info.keyboardHeight);
  //     });

  //     Keyboard.addListener('keyboardWillHide', () => {
  //       setKeyboardHeight(0);
  //     });
  //   }

  //   return () => {
  //     if (isPlatform('ios')) {
  //       Keyboard.removeAllListeners();
  //     }
  //   };
  // }, []);

  const closeClEv = () => {
    setIsModalEv(false);
    setMessageEv('');
  }

const closeCl = () => {
  setIsModalFB(false);
  setMessage('');
}


const addEvent = async () => {
  const startTime = Date.now();
  setShowLoading(true);

  try {
    // const db = getDatabase();
    const feedbackRef = ref(database, 'data/event_submissions');
    
    const newFeedbackKey = push(feedbackRef).key;
    
    const feedbackData = {
      id: newFeedbackKey,
      email: emailEv.trim(),
      website: messageEv.trim(),
      timestamp: Date.now()
    };

    const updates: { [key: string]: any } = {};
    updates[`data/event_submissions/${newFeedbackKey}`] = feedbackData;
    await update(ref(database), updates);

    setToastMessage('Event submitted successfully!');
    setToastColor('success');
    // setMessage('');
  } catch (error) {
    console.error("Error submitting event:", error);
    setToastMessage('Failed to submit event. Please try again.');
    setToastColor('danger');
  } finally {
    const endTime = Date.now();
    const elapsedTime = endTime - startTime;
    if (elapsedTime < MIN_LOADING_DURATION) {
      await new Promise(resolve => setTimeout(resolve, MIN_LOADING_DURATION - elapsedTime));
    }
    setShowLoading(false);
    setIsModalEv(false);
    setMessage('');
    setShowToast(true);
  }
};

  const addFeedback = async () => {
    const startTime = Date.now();
    setShowLoading(true);

    try {
      // const db = getDatabase();
      const feedbackRef = ref(database, 'data/feedback');
      
      const newFeedbackKey = push(feedbackRef).key;
      
      const feedbackData = {
        id: newFeedbackKey,
        message: message.trim(),
        timestamp: Date.now()
      };

      const updates: { [key: string]: any } = {};
      updates[`data/feedback/${newFeedbackKey}`] = feedbackData;
      await update(ref(database), updates);

      setToastMessage('Feedback submitted successfully!');
      setToastColor('success');
      // setMessage('');
    } catch (error) {
      console.error("Error submitting feedback:", error);
      setToastMessage('Failed to submit feedback. Please try again.');
      setToastColor('danger');
    } finally {
      const endTime = Date.now();
      const elapsedTime = endTime - startTime;
      if (elapsedTime < MIN_LOADING_DURATION) {
        await new Promise(resolve => setTimeout(resolve, MIN_LOADING_DURATION - elapsedTime));
      }
      setShowLoading(false);
      setIsModalFB(false);
      setMessage('');
      setShowToast(true);
    }
  };
  

  // const addFeedbackOld = async () => {
  //   setIsLoadingFB(true);
  //   setAlertFB({ message: '', type: '' });

  //   try {
      
  //     const feedbackRef = ref(database, 'data/feedback');
      
  //     const newFeedbackKey = push(feedbackRef).key;
      
  //     const feedbackData = {
  //       id: newFeedbackKey,
  //       message: appFeedback.trim(),
  //       timestamp: Date.now()
  //     };

  //     const updates: { [key: string]: any } = {};
  //     updates[`data/feedback/${newFeedbackKey}`] = feedbackData;
  //     await update(ref(database), updates);

  //     setAlertFB({ message: 'Feedback submitted successfully!', type: 'success' });
  //     setAppFeedback('');
  //   } catch (error) {
  //     console.error("Error submitting feedback:", error);
  //     setAlertFB({ message: 'Failed to submit feedback. Please try again.', type: 'error' });
  //   } finally {
  //     setIsLoadingFB(false);
  //     setTimeout(() => setAlertFB({ message: '', type: '' }), 5000);
  //   }
  // };


  
  const partiesCount = useMemo(() => props.parties.length, [props.parties.length]);
  // const nonCircFests = useMemo(() => {
  //   return props.festivals.filter((festival:any) => !festival.circuit);
  // }, [props.festivals]);

  const nonCircFests = useMemo(() => {
    const { festivals, parties } = props;
  
    // Step 1: Create a set of festival names used in parties
    const festivalNamesInParties = new Set(parties.map((party: any) => party.festival));
  
    // Step 2: Filter festivals based on the given conditions
    return festivals.filter((festival: any) => {
      // Step 3: Check if the festival is not a circuit and if it appears in parties
      return !festival.circuitbool && festivalNamesInParties.has(festival.name);
    });
  }, [props.festivals, props.parties]);


  const memoizedNumBoxValue = useMemo(() => {
    return props.people.filter((person:any) => (
      props.parties.filter((party:any) => party.entertainers !== "").filter((party:any) => party.entertainers.some((entertainer:any) => entertainer === person.name)).length > 0
      ||
      props.dragExpos.filter((drag: any) => drag.entertainers !== "").filter((drag:any) => drag.entertainers.some((entertainer:any) => entertainer === person.name)).length > 0
    )).length;
  }, [props.people, props.parties, props.dragExpos]);

  const peopleCount = useMemo(() => props.people.length, [props.people.length]);


  return (
    
    <IonMenu
    contentId="main"
    // type='overlay'
    type="push"
    >

      <IonHeader>
        <IonToolbar className="menutoolbarbg">
          <div className="menutoolbar">
            <div className="menuheaderbox">
              {/* <div className="menuletter">T</div>
              <div className="menuletter">H</div>
              <div className="menuletter">E</div>&nbsp;
              <img className="menutriangle" src={triimg} alt="" />
              <div className="menuletter">G</div>
              <div className="menuletter">E</div>
              <div className="menuletter">N</div>
              <div className="menuletter">D</div>
              <div className="menuletter">A</div> */}
                {/* <span className="combineul">THE<span className="gaybit">GAY</span>AGENDA</span> */}
                <img className="menutriangle" src={newlogo} alt="" />
            </div>
          </div>
              {
            !settingsIsOpen
            &&
          <div className="cccopt">
                      <IonIcon icon={locationOutline} className="seticon2"></IonIcon>
                      
                      {props.citySelected === "San Francisco" ? "San Fran" : props.citySelected}

                      {/* <IonIcon icon={openOutline} className="seticon4"></IonIcon> */}
                      <div className="chcty" onClick={props.openPickerSelectCity}>CHANGE</div>
                      {/* <div className="chcty" onClick={()=>props.setPickerIsOpen(true)}>CHANGE</div> */}
              </div>
            }

        </IonToolbar>

        {/* move below into toolbar a la parties date city info */}
      
      </IonHeader>

      <IonModal
          ref={modalAddEvent}
          // id="example-modalPartiesNotifications"
          isOpen={isModalEv}
          onDidDismiss={closeClEv}
          className="notifmodal2"
          >
                  <IonToolbar className="notiftoolbar">
                              <IonButtons slot="secondary">
                                      <IonButton
                                      // className={message.trim() === '' ? "sbmtbtn btncolb" : "sbmtbtn btncolr"}
                                    onClick={closeClEv}
                                    // disabled={showLoading || message.trim() === ''}
                                    >
                          
                                      Close
                                      {/* <IonIcon icon={arrowForwardOutline} className="clickawayarrow" /> */}
                                                  
                                  </IonButton>
                              </IonButtons>
                      <div className="notifheader">Submit Event</div>
                      <IonButtons slot="primary">
                              <IonButton
                              // className={message.trim() === '' ? "sbmtbtn btncolb" : "sbmtbtn btncolp"}
                              onClick={addEvent}
                              disabled={showLoading || messageEv.trim() === ''}
                              >
                            
                                Submit
                                {/* <IonIcon icon={arrowForwardOutline} className="clickawayarrow" /> */}
                                            
                            </IonButton>
                      </IonButtons>
                  </IonToolbar>

                  <IonContent fullscreen className="ioncnotif">

                    <div className="notifgradient">
                      <div className="emailbox">
                              <IonInput
                                className="txtarea"
                                // rows={1}
                                value={emailEv}
                                onIonInput={(e:any)=>setEmailEv(e.detail.value)}
                                // counter={true}
                                maxlength={100}
                                type="email"
                                placeholder="Add email for follow-up (optional)."
                                disabled={showLoading}
                                />
                        </div>

                      <div className="txtbox">
                                <IonTextarea
                                className="txtarea"
                                rows={11}
                                value={messageEv}
                                onIonInput={(e:any)=>setMessageEv(e.detail.value)}
                                counter={true}
                                maxlength={300}
                                placeholder="Please provide a link to the event's details. Every effort will be made to add the event to the app in a timely fashion. You can also reach out on Instagram at @thegayagenda_official."
                                disabled={showLoading}
                                />
                                <div className="plslimit">URL cannot exceed 300 characters.</div>
                      </div>

                      <div className="btnsfeedback">
                            
                          
                        </div>

                                  
                    </div>

                                    

                              

              </IonContent>
                              <IonLoading
                                isOpen={showLoading}
                                message={'Submitting event...'}
                                className="custom-loading"
                              />
        </IonModal>

      <IonModal
          ref={modalAddFeedback}
          // id="example-modalPartiesNotifications"
          isOpen={isModalFB}
          onDidDismiss={closeCl}
          className="notifmodal2"
          >
                  <IonToolbar className="notiftoolbar">
                              <IonButtons slot="secondary">
                                      <IonButton
                                      // className={message.trim() === '' ? "sbmtbtn btncolb" : "sbmtbtn btncolr"}
                                    onClick={closeCl}
                                    // disabled={showLoading || message.trim() === ''}
                                    >
                          
                                      Close
                                      {/* <IonIcon icon={arrowForwardOutline} className="clickawayarrow" /> */}
                                                  
                                  </IonButton>
                              </IonButtons>
                      <div className="notifheader">Feedback</div>
                      <IonButtons slot="primary">
                              <IonButton
                              // className={message.trim() === '' ? "sbmtbtn btncolb" : "sbmtbtn btncolp"}
                              onClick={addFeedback}
                              disabled={showLoading || message.trim() === ''}
                              >
                            
                                Submit
                                {/* <IonIcon icon={arrowForwardOutline} className="clickawayarrow" /> */}
                                            
                            </IonButton>
                      </IonButtons>
                  </IonToolbar>

                  <IonContent fullscreen className="ioncnotif">

                    <div className="notifgradient">

                      <div className="txtbox">
                                <IonTextarea
                                className="txtarea"
                                rows={13}
                                value={message}
                                onIonInput={(e:any)=>setMessage(e.detail.value)}
                                counter={true}
                                maxlength={300}
                                placeholder="Please provide feedback on the app's current features and content, areas for improvement, and suggestions for future updates. The response will remain anonymous."
                                disabled={showLoading}
                                />
                                <div className="plslimit">Please limit the response to 300 characters.</div>
                      </div>

                      <div className="btnsfeedback">
                            
                          
                        </div>

                                  
                    </div>

                                    

                              

              </IonContent>
                              <IonLoading
                                isOpen={showLoading}
                                message={'Submitting feedback...'}
                                className="custom-loading"
                              />
        </IonModal>


                              <IonToast
                                isOpen={showToast}
                                onDidDismiss={() => setShowToast(false)}
                                message={toastMessage}
                                duration={3000}
                                color={toastColor}
                              />

      
      

          {/* <IonModal
          ref={modalPicker}
          isOpen={props.pickerIsOpen}
          onDidDismiss={(e) => {
            if (e.detail.role === 'confirm') {
              props.closePickerSelectCity(pickerVal);
            } else {
              props.setPickerIsOpen(false);
            }
          }}
          >
            <IonToolbar>
              <IonButtons>
                <IonButton onClick={() => modalPicker.current!.dismiss(null, 'cancel')}>Cancel</IonButton>
              </IonButtons>
              <IonButtons slot="end">
                <IonButton onClick={() => modalPicker.current!.dismiss(pickerVal, 'confirm')}>Done</IonButton>
              </IonButtons>
            </IonToolbar>
            <IonPicker>
              
              <IonPickerColumn value={pickerVal} onIonChange={(e) => setPickerVal(e.detail.value)}>
                {props.cityList.map((city:any, index:number) => (
                  <IonPickerColumnOption key={index} value={city.city}>
                    {city.city}
                  </IonPickerColumnOption>
                ))}
              </IonPickerColumn>
            </IonPicker>
            </IonModal> */}

      <IonContent fullscreen>
        {
        !settingsIsOpen
        &&
        <div className="menumainsetwidth">

          {/* <div className="cityheaderbox">
            <div className="menufirstlinecont">
              <div className="menutopline"></div>
              <div className="menubotline"></div>
            </div>
            <div className="menucitytitle">{props.citySelected === "San Francisco" ? "SAN FRAN" : props.citySelected.toUpperCase()}</div>
            <div className="menuthirdlinecont">
              <div className="menutopline"></div>
              <div className="menubotline"></div>
            </div>
          </div> */}

          {/* assume you always have at least parties */}
          <div className="menusection smalltop">
            <div className="menusectheader">EVENTS</div>
          </div>

          <IonMenuToggle autoHide={false} style={{width: '100%'}}>
            {/* is link okay vs the router attribute that I was there initially? */}
            <IonRouterLink routerLink="/parties" style={{ display: 'contents' }}>
              <div className={location.pathname === '/parties' ? 'menusubheader menuselected' : 'menusubheader'}>
                <div className="menuoption">
                  All
                </div>
                <div className="numbox">
                  {/* {props.parties.length} */}
                  {partiesCount}
                  </div>
              </div>
            </IonRouterLink>
          </IonMenuToggle>

         

          {/* {
            props.festivals.length > 0
            &&
            <div className="menusection">
              <div className="menusectheader">FESTIVALS</div>
            </div>
          } */}
         

          {
            props.festivals.filter((fest:any) => props.parties.some((party:any) => party.festival === fest.name))
            // nonCircFests.filter((fest:any) => fest.city === props.citySelected)
            .map((festival:any, index:number) => {
              return <IonMenuToggle key={index} autoHide={false} style={{width: '100%'}}>
                <IonRouterLink routerLink={`/parties-fest/${festival.festparam}`} style={{ display: 'contents' }}>
                  <div className={location.pathname === `/parties-fest/${festival.festparam}` ? 'menusubheader menuselected' : 'menusubheader'}>
                    {/* {
                      festival.name_short === ""
                      &&
                    <div className="menuoption">
                      {festival.name === "Sydney Mardi Gras 2024" ? "Mardi Gras 2024" : festival.name}
                    </div>
                    }
                    {
                      festival.name_short !== ""
                      &&
                    <div className="menuoption">
                      {festival.name === "Sydney Mardi Gras 2024" ? "Mardi Gras 2024" : festival.name_short}
                    </div>
                    } */}
                    <div className="menuoption">
                      {festival.menu_name ? festival.menu_name : festival.name}
                    </div>
                    <div className="numbox">
                      {/* {festival.party_left} */}

                      {props.parties.filter((party:any) => party.festival === festival.name).length}
                      {/* can memoise or event set state in useeffect above? */}
                      </div>
                  </div>
                </IonRouterLink>
              </IonMenuToggle>
            })
          }
              
          

          {/* assume you always have at least bars and clubs */}
          <div className="menusection extratop">
            <div className="menusectheader">PLACES</div>
          </div>

          <IonMenuToggle autoHide={false} style={{width: '100%'}}>
            <IonRouterLink routerLink={`/barsandclubs`} style={{ display: 'contents' }}>
              <div className={location.pathname === '/barsandclubs' ? 'menusubheader menuselected' : 'menusubheader'}>
                <div className="menuoption">
                  Bars & Clubs
                </div>
                <div className="numbox">{props.barsAndClubs.length}</div>
              </div>
            </IonRouterLink>
          </IonMenuToggle>

          {
          props.saunas.length > 0
          &&
          <IonMenuToggle autoHide={false} style={{width: '100%'}}>
            <IonRouterLink routerLink={`/saunas`} style={{ display: 'contents' }}>
              <div className={location.pathname === '/saunas' ? 'menusubheader menuselected' : 'menusubheader'}>
                <div className="menuoption">
                  Saunas
                </div>
                <div className="numbox">{props.saunas.length}</div>
              </div>
            </IonRouterLink>
          </IonMenuToggle>
          }

          {
            peopleCount > 0
            &&
            <div className="menusection extratop">
              <div className="menusectheader">PEOPLE</div>
            </div>
          }
          {
            peopleCount > 0
            &&
              <IonMenuToggle autoHide={false} style={{width: '100%'}}>
              <IonRouterLink routerLink={`/people`} style={{ display: 'contents' }}>
                <div className={location.pathname === '/people' ? 'menusubheader menuselected' : 'menusubheader'}>
                  <div className="menuoption">
                    Performers
                  </div>
                  <div className="numbox">
                    {/* {props.people.filter((person:any) => (
                    props.parties.filter((party:any) => party.entertainers !== "").filter((party:any) => party.entertainers.some((entertainer:any) => entertainer === person.name)).length > 0
                    ||
                    props.dragExpos.filter((drag: any) => drag.entertainers !== "").filter((drag:any) => drag.entertainers.some((entertainer:any) => entertainer === person.name)).length > 0
                 
                  )).length
                  } */}
                  {peopleCount}
                    </div>
                </div>
              </IonRouterLink>
            </IonMenuToggle>
          }
          

          
          {
            (props.dragExpos.length > 0 || props.dragTours.length > 0)
            &&
            <div className="menusection extratop">
              <div className="menusectheader">DRAG</div>
            </div>
          }

          
          {/* {
            props.dragExpos.length > 0
            &&
          <IonMenuToggle autoHide={false} style={{width: '100%'}}>
            <Link to={`/dragexpos`} style={{ display: 'contents' }}>
              <div className={location.pathname === '/dragexpos' ? 'menusubheader menuselected' : 'menusubheader'}>
                <div className="menuoption">
                  Conventions
                </div>
                <div className="numbox">{props.dragExpos.length}</div>
              </div>
            </Link>
          </IonMenuToggle>
          } */}
          
          {
            // props.dragTours.length > 0
            // &&
            //below now checks whether there are tours or conventions
            props.dragExpos.length > 0
            &&
          <IonMenuToggle autoHide={false} style={{width: '100%'}}>
            <IonRouterLink routerLink={`/drag`} style={{ display: 'contents' }}>
              <div className={location.pathname === '/drag' ? 'menusubheader menuselected' : 'menusubheader'}>
                {
                  props.dragExpos.length === props.dragTours.length
                  &&
                  <div className="menuoption">
                    Tours
                  </div>
                }
                {
                  props.dragTours.length === 0
                  &&
                  <div className="menuoption">
                    Conventions
                  </div>
                }
                {
                  props.dragTours.length !== props.dragExpos.length
                  &&
                  <div className="menuoption">
                    Tours & Conventions
                  </div>
                }
                
                <div className="numbox">{props.dragExpos.length}</div>
              </div>
            </IonRouterLink>
          </IonMenuToggle>
          }

          

          {/* add conditional to below global title to make sure only appears if theres cruises or drag convention */}
          {/* and make all of the sub categories conditionals as well */}
          {/* {
            (props.cruises.length > 0 || props.festivalsCirc.length > 0)
            &&
            <div className="cityheaderbox">
              <div className="menufirstlinecont">
                <div className="menutopline2"></div>
                <div className="menubotline2"></div>
              </div>
              <div className="menuglobaltitle">GLOBAL</div>
              <div className="menuthirdlinecont">
                <div className="menutopline2"></div>
                <div className="menubotline2"></div>
              </div>
          </div>
          } */}
          
          {
            (props.cruises.length > 0 || props.festivalsCirc.length > 0 || props.festivalsPr.length > 0)
            &&
          <div className="menusection extratop">
            <div className="menusectheader">TRAVEL</div>
          </div>
          }

          {
            props.festivalsPr.length > 0
            &&
          <IonMenuToggle autoHide={false} style={{width: '100%'}}>
            <IonRouterLink routerLink={`/pride`} style={{ display: 'contents' }}>
              <div className={location.pathname === '/pride' ? 'menusubheader menuselected' : 'menusubheader'}>
                <div className="menuoption">
                  Pride Festivals
                </div>
                <div className="numbox">{props.festivalsPr.length}</div>
              </div>
            </IonRouterLink>
          </IonMenuToggle>
          }

          {
            props.festivalsCirc.length > 0
            &&
          <IonMenuToggle autoHide={false} style={{width: '100%'}}>
            <IonRouterLink routerLink={`/circuit`} style={{ display: 'contents' }}>
              <div className={location.pathname === '/circuit' ? 'menusubheader menuselected' : 'menusubheader'}>
                <div className="menuoption">
                  Circuit Festivals
                </div>
                <div className="numbox">{props.festivalsCirc.length}</div>
              </div>
            </IonRouterLink>
          </IonMenuToggle>
          }

          {
            props.cruises.length > 0
            &&
          <IonMenuToggle autoHide={false} style={{width: '100%'}}>
            <IonRouterLink routerLink={`/cruises`} style={{ display: 'contents' }}>
              <div className={location.pathname === '/cruises' ? 'menusubheader menuselected' : 'menusubheader'}>
                <div className="menuoption">
                  Cruises
                </div>
                <div className="numbox">{props.cruises.length}</div>
              </div>
            </IonRouterLink>
          </IonMenuToggle>
          }
          <div className="dividermenucont">
            <div className="dividermenu"></div>
          </div>

          <div className="menusection extratop">
            <div className="menusectheader">OTHER</div>
          </div>


          <div className='menusubheader' onClick={() => setIsModalEv(true)}>
              <div className="menuoption">
                {/* <IonIcon icon={cloudUploadOutline} className="seticoncl"></IonIcon> */}
                  Submit Event
                  
                </div>
                {/* <IonIcon icon={arrowForwardOutline} className="seticonclarr" /> */}
                <div className="numbox2">
                    <IonIcon icon={arrowForwardOutline} className="seticonclnew" />
                </div>
            </div>

            <div className='menusubheader' onClick={() => setIsModalFB(true)}>
            <div className="menuoption">
                {/* <IonIcon icon={chatbubbleEllipsesOutline} className="seticoncl"></IonIcon> */}
                  Provide Feedback
                </div>
                {/* <IonIcon icon={arrowForwardOutline} className="seticonclarr" /> */}
                <div className="numbox2">
                    <IonIcon icon={arrowForwardOutline} className="seticonclnew" />
                </div>
            </div>

            {/* <div className='menusubheader' onClick={() => setSettingsIsOpen(true)}>
              <div className="menuoption">
                  Settings
                </div>
                <div className="numbox2">
                    <IonIcon icon={chevronForward} className="seticonclnew2" />
                </div>
            </div> */}










          {/* <div className="menufooter">
            <div className="selectacitybtnmenu" onClick={props.openPickerSelectCity}>CHANGE CITY</div>
          </div> */}




          {/* <div className="menusection extratop">
            <div className="menusectheader"></div>
          </div>
          

         
        
              <div className="menusubheader"
              >
                <div className="menuoption">
                <IonIcon icon={chevronForward} className="seticoncl"></IonIcon>
                  Feedback
                </div>
                
                 
          
              </div>
              <div className="menusubheader"
              >
                <div className="menuoption">
                <IonIcon icon={chevronForward} className="seticoncl"></IonIcon>
                  Submit Event
                </div>
                
                  
                
              </div>
              <div className="menusubheader"
              >
                <div className="menuoption">
                  <IonIcon icon={settingsOutline} className="seticoncl"></IonIcon>
                  Settings
                </div>
                
                  
                
              </div> */}
      
          





        </div>
        }
{/* 
{
      menuType === "feedback"
      &&
      <IonContent>
        <div className="menumainsetwidth">
         <div className="topfeedback">
                <div className="customtxtfdb">FEEDBACK:</div>
                <IonButton className={message.trim() === '' ? "sbmtbtn2" : "sbmtbtn"}
                      onClick={addFeedback}
                      disabled={showLoading || message.trim() === ''}
                      >
                          
                              SUBMIT
                                          
                  </IonButton>
         </div>
         <div className="txtbox">
                                <IonTextarea
                                className="txtarea"
                                rows={15}
                                value={message}
                                onIonInput={(e:any)=>setMessage(e.detail.value)}
                                counter={true}
                                maxlength={300}
                                placeholder="Please provide feedback on the app's current features and content, areas for improvement, and suggestions for future updates. The response will remain anonymous."
                                disabled={showLoading}
                                />
                                <div className="plslimit">Please limit response to 300 characters.</div>
            </div>
            
            
      

            <IonLoading
              isOpen={showLoading}
              message={'Submitting feedback...'}
              className="custom-loading"
            />

            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowToast(false)}
              message={toastMessage}
              duration={3000}
              color={toastColor}
            />
            
            </div>
        </IonContent>
      } */}

      {
      settingsIsOpen
      &&
      <div className="menumainsetwidth">
         {/* <div className="selecttextbox">
          <div className="eventsinmenu-settings">Select a default city to skip the opening screen:</div>
          
        </div> */}

        {/* <div className="selectopbox">
        
          <div>
            <IonSelect aria-label="default-city" interface="popover" placeholder="Pick a city">
            <IonSelectOption value="none">None</IonSelectOption>
              <IonSelectOption value="sydney">Sydney</IonSelectOption>
              <IonSelectOption value="london">London</IonSelectOption>
              <IonSelectOption value="san francisco">San Francisco</IonSelectOption>
            </IonSelect>
          </div>
          
          
        </div> */}

        {/* <div className="custombox"> */}
          <div className="menusectionalt">
              {/* <div className="customtxt">Select a default city:</div> */}

              <IonSelect aria-label="default-city" justify="space-between" label="DEFAULT:" color="medium" interface="popover"
              value={props.defCity}
              onIonChange={(e) => props.setDefCity(e.detail.value)}
              >
                {/* <IonSelectOption value="">NONE</IonSelectOption> */}

                {
                  props.cityListN.map((city: any, index: number) => {
                    return <IonSelectOption
                    key={index}
                    value={city}
                    >
                      {/* {city.toUpperCase()} */}
                      {city}
                    </IonSelectOption>
                  })
                }
                  {/* <IonSelectOption value="Sydney">Sydney</IonSelectOption>
                  <IonSelectOption value="London">London</IonSelectOption>
                  <IonSelectOption value="Melbourne">Melbourne</IonSelectOption> */}
            </IonSelect>
          </div>
          {
            !props.isWeb
            &&
          <div className="customtxt">CUSTOMISE NOTIFICATIONS:</div>
          }
          

          
            {
              !props.isWeb
              &&
              props.cityListN.map((city: any, index: number) => {
                return <div key={index} className="filtersinnerfbmenu2">
                    <div className="filtermenu">{city}</div>
                    <IonToggle
                    slot="end"
                    value={city}
                    checked={props.citiesNotifs.includes(city) ? true : false}
                    onIonChange={props.handleToggleCity}
                    >
                    </IonToggle>
              </div>
              })
            }
                
                
            
          
        {/* </div> */}
        
      </div>
      }
      </IonContent>

      {
      !settingsIsOpen
      &&
      <div className="menufooter botpd">
       
          

              {/* <div className="menusubheaderbt" onClick={() => setIsModalEv(true)}
              >
                <div className="menuoptionbt">
                <IonIcon icon={cloudUploadOutline} className="seticoncl"></IonIcon>
                  Submit Event
                  
                </div>
                
                <IonIcon icon={arrowForwardOutline} className="seticonclarr" />
          
              </div>
              <div className="menusubheaderbt" onClick={() => setIsModalFB(true)}
              >
                <div className="menuoptionbt">
                <IonIcon icon={chatbubbleEllipsesOutline} className="seticoncl"></IonIcon>
                  Provide Feedback
                </div>
                
                <IonIcon icon={arrowForwardOutline} className="seticonclarr" />
                
              </div> */}
              <div className="menusubheaderbt" onClick={() => setSettingsIsOpen(true)}
              >
                <div className="menuoptionbt">
                  <IonIcon icon={settingsOutline} className="seticoncl"></IonIcon>
                  Settings
                </div>
                
                <IonIcon icon={chevronForward} className="seticoncl"></IonIcon>
                
              </div>
      </div>
      }
      {
      settingsIsOpen
      &&
      // <IonFooter className="menufooter botpd">
      <div className="menufooter">

          {/* <div className="settingsopt" onClick={() => setMenuType("main")}>
                  <IonIcon icon={chevronBack} className="seticoncl"></IonIcon>
                  <div className="stretchcl">Return to Main Menu</div>
                  
                </div> */}
                <div className="menusubheader2" onClick={() => setSettingsIsOpen(false)}
              >
                <div className="menuoptionbt">
                <IonIcon icon={chevronBack} className="seticonclnew3"></IonIcon>
                  Main Menu
                  
                </div>
                
                {/* <IonIcon icon={chevronForward} className="seticoncl"></IonIcon> */}
          
              </div>
      </div>
      
      }

      

    </IonMenu>
    
  );
};

export default Menu;
