// search probably needs to be updated for live events - see what I did for spot cards in CalView

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
// import { useParams } from 'react-router';

//firebase
import { databaseData } from '../lib/init-firebase';
import { set, ref, runTransaction, increment, update } from 'firebase/database';

import {
  IonButtons,
  IonContent,
  IonHeader,
  // IonRefresher,
  // IonRefresherContent,
  IonMenuButton,
  IonPage,
  // IonTitle,
  IonToolbar,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonModal,
  IonToggle,
  IonSearchbar,
  IonButton,
  // IonFab,
  // IonFabButton,

  IonicSlides,
} from '@ionic/react';

import {
  // locationOutline,
  options,
  calendarOutline,
  searchOutline,
  heartSharp,
  notificationsOutline,
  heartOutline,
  lockClosedOutline,
  reorderThreeOutline,
  // informationOutline,
  // headsetSharp,
  gridOutline,
  // trash,
  // optionsOutline,
  chevronForwardOutline,
  chevronDown,
  addOutline
} from 'ionicons/icons';

import CalViewParties from './CalViewParties';

import { Swiper, SwiperSlide } from 'swiper/react';
// import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';
// so in tgamarknine i actually back-graded to a 9 version of Swiper so I could continue with how I was doing it before. above doesn't work now.
import { Navigation, Pagination } from 'swiper/modules';

import logohq from '../images/tgalogo_hq.png';
// import fulllogo from '../images/logomarktwo.png';


import 'swiper/css';
import 'swiper/css/bundle';
// import 'swiper/css/pagination';

// import '@ionic/react/css/ionic-swiper.css';

import '../theme/gridview.css';
import '../theme/notifications.css';
import '../theme/infomodal.css';
import '../theme/searchbars.css';
import '../theme/spotcards.css';
import '../theme/filtermodals.css';
import '../theme/swipers.css';
import '../theme/segments.css';
import '../theme/calview.css';

// import ExploreContainer from '../components/ExploreContainer';


// const cityMap: { [key: string]: string } = {
//   "Sydney": "SYD",
//   "San Francisco": "SF",
//   "Melbourne": "MEL",
//   "London": "LDN"
// };


// Accent-folding function
const removeAccents = (str:string) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};



const Parties: React.FC<{
  isWeb: any;
  text: any;
  gridNo: any;
  isWide: any;
  parties: any;
  partiesFeat: any;
  // topParties: any;
  citySelected: any;
  currentDateAndTime: any;
  // openPickerSelectCity: any;
  likedParties: any;
  setLikedParties: any;
  tagsParties: any;
  // handlePullRefresherWithPause: any;
  notifications: any;
  setNotifications: any;
  viewedNotifications: any;
  setViewedNotifications: any;
  viewedNotificationsAreLoaded: any;
  handleIncrement: any;
  handleDecrement: any;
  // isLikesOnP: any;
}> = ( props ) => {

  const [partyView, setPartyView] = useState("gridview");
  

  const [filteredParties, setFilteredParties] = useState<any>(null);
  const [filteredPartiesInProgressAndToday, setFilteredPartiesInProgressAndToday] = useState<any>(null);
  // const [topParties, setTopParties] = useState<any>(null);
  // const [isTopOn, setIsTopOn] = useState(true);
  const [isTopOnF, setIsTopOnF] = useState(true);
  // f is for Featured

  const [dateOfLastParty, setDateOfLastParty] = useState<any>(null);

  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isInfoOpenP, setIsInfoOpenP] = useState(false);

  
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [tagsSelected, setTagsSelected] = useState<any[]>([]);
  const [isLikedPartiesTagOn, setIsLikedPartiesTagOn] = useState(false);
  const [tagsShown, setTagsShown] = useState<any[]>([]);
  const [associatedSections, setAssociatedSections] = useState<any[]>([]);

  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchText2, setSearchText2] = useState("");

  const [searchedForParties, setSearchedForParties] = useState([]);
  const [isSeeAllOpen, setIsSeeAllOpen] = useState(false);

  // const [presentingElement, setPresentingElement] = useState<HTMLElement | undefined>(undefined);


  // const pageParties = useRef(undefined);
  const modalNotificationsParties= useRef<HTMLIonModalElement>(null);
  // const modalInfoParties= useRef<HTMLIonModalElement>(null);
  const modalTagsParties= useRef<HTMLIonModalElement>(null);
  const modalSearchParties = useRef<HTMLIonModalElement>(null);
  const modalSeeAll = useRef<HTMLIonModalElement>(null);




  const handleViewChange = (viewClicked: string) => {
    if (viewClicked === partyView) return;
    if (viewClicked === "listview") setPartyView("listview");
    if (viewClicked === "gridview") setPartyView("gridview");
    if (viewClicked === "calview") setPartyView("calview");
  };

  
  const handleOpenNotifications = () => {
    setIsNotificationsOpen(true);
    const arrayOfNotificationsNotAlreadyInState: any = [];
    if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.hash === "hash1").length === 0) {
      // const obj1 = {}
      arrayOfNotificationsNotAlreadyInState.push({hash: "hash1"});
    }
    if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.hash === "hash2").length === 0) {
      // const obj1 = {}
      arrayOfNotificationsNotAlreadyInState.push({hash: "hash2"});
    }
    for (let i = 0; i < props.notifications.length; i++) {
      if (props.viewedNotifications.filter((viewednotification: any) => viewednotification.hash === props.notifications[i].hash).length === 0) {
        arrayOfNotificationsNotAlreadyInState.push(props.notifications[i])
      }
    }
    props.setViewedNotifications((prev: any) => [...prev, ...arrayOfNotificationsNotAlreadyInState]);
  }

  const handleCloseNotifications = () => {
    setIsNotificationsOpen(false);
  }

  const handleSubmitLikeToDB = (hash:any) => {
    const postRef = ref(databaseData, `/likes/parties/${hash}`);

    runTransaction(postRef, (hasheditem:any) => {
      if (hasheditem) {
        
          hasheditem.likes++;
          //currently not getting to this section ever.
      } else if (!hasheditem) {
        set(ref(databaseData, `/likes/parties/${hash}`), {
          likes: 1
        })
        return;
      }
      console.log(hasheditem, 'hashed item')
      return hasheditem;
      //not sure why i would need the above return statement.. actually i think i could put the guess in there (why wasnt the example doing that)...in any case i can use increment()
    })
    
  }

  const handleTogView = () => {
    if (partyView === "gridview") setPartyView("calview")
    if (partyView === "calview") setPartyView("gridview")
  }

  // const handleIncrement = (hash:any) => {
  //   const dbRef = ref(databaseData);

  //   const updates:any = {};
  //   updates[`/likes/parties/${hash}/like_count`] = increment(1);
    
  //   update(dbRef, updates);
  // }

  // const handleDecrement = (hash:any) => {
  //   const dbRef = ref(databaseData);

  //   const updates:any = {};
  //   updates[`/likes/parties/${hash}/like_count`] = increment(-1);
    
  //   update(dbRef, updates);
  // }

  const handleLikeParty = (event: any, partyhash: any) => {
    event.preventDefault();
    // event.stopPropagation();
    // console.log(event.target.value, 'does this cause reset23');
    props.setLikedParties((prev: any)=>[...prev, partyhash]);
    // handleSubmitLikeToDB(partyhash);
    props.handleIncrement("parties", partyhash);
  }

  

  
  // const handleSubmitLikeToDB = (hash:any) => {
  //   const postRef = ref(databaseData, `/likes/parties/${hash}`);

  //   runTransaction(postRef, (hasheditem:any) => {
  //     if (hasheditem) {
  //       if (props.likedParties.includes(hasheditem)) {
  //         hasheditem.likes++;
  //       } else {
  //         hasheditem.likes--;
  //       }
  //     } else if (!hasheditem) {

  //     }
  //     console.log(hasheditem, 'hashed item')
  //     return hasheditem;
  //   })
  //   set(ref(databaseData, `/likes/parties/${hash}`), {
  //     likes: 1
  //   })
  // }

  const handleUnlikeParty = (event: any, partyhash: any) => {
    event.preventDefault();
    // event.stopPropagation();
    props.setLikedParties(props.likedParties.filter((partylikedhash:any) => partylikedhash !== partyhash));
    props.handleDecrement("parties", partyhash);
  }

  const handleOpenSearchBar = () => {
    // if (props.parties.length > 0) {
    setIsSearchBarOpen(true);
    // }
  }

  const handleCloseSearchBar = () => {
    setIsSearchBarOpen(false);
    setSearchText("");
    setSearchedForParties(props.parties);
  }

  const handleSearchText = ({ target }:any) => {
    setSearchText(target.value);
  }
  //above seems inefficient

  const handleSearchText2 = ({ target }:any) => {
    setSearchText2(target.value);
  }

  const handleOpenTags = () => {
    // if (props.parties.length > 0) {
    setIsFiltersOpen(true);
    // }
  }

  const addTags = (tag: string) => {
    if (tagsSelected.includes(tag)) {
      // is includes just as efficient as filter. if (tagsSelected.filter(ele => ele === tag).length > 0)
      const tagSelectedOneLess = tagsSelected.filter((ele: any) => ele !== tag);
      setTagsSelected(tagSelectedOneLess);
    } else {
      setTagsSelected((prevTags: any) => {
        return prevTags.concat(tag);
      });
    }
  };

  const handleTurnOnTag = (event: CustomEvent) => {
    addTags(event.detail.value);
  };


  const compare = (a:any, b:any) => {
    const startA = a.daterealstart;
    const startB = b.daterealstart;
    const finishA = a.daterealfinish;
    const finishB = b.daterealfinish;

    const aHasStarted = startA <= props.currentDateAndTime;
    const bHasStarted = startB <= props.currentDateAndTime;

    if (aHasStarted && bHasStarted) {
      if (finishA < finishB) return -1;
      if (finishA > finishB) return 1;
    }

    if (aHasStarted && !bHasStarted) return -1;
    if (!aHasStarted && bHasStarted) return 1;

    if (startA < startB || (startA.getTime() === startB.getTime() && finishA < finishB)) return -1;
    if (startA > startB || (startA.getTime() === startB.getTime() && finishA > finishB)) return 1;

    return 0;
  };

  

  const handleFilterParties = () => {


 


    if (isLikedPartiesTagOn || tagsSelected.length !== 0
    ) {
      setIsTopOnF(false);
    } else {
      setIsTopOnF(true);
    }
  

    let tempArrayOfFilteredParties:any = [];

    if (!isLikedPartiesTagOn) {
      tempArrayOfFilteredParties = props.parties.filter((party: any) => (
        (tagsSelected.every(tag => party.tags_array.includes(tag)) || tagsSelected.length === 0)
       
      ));
    }

    if (isLikedPartiesTagOn) {
      tempArrayOfFilteredParties = props.parties.filter((party: any) => (
        (tagsSelected.every(tag => party.tags_array.includes(tag)) || tagsSelected.length === 0)
        &&
        props.likedParties.filter((ele: any) => ele === party.hash).length > 0
      
      ));
    }

    let tempArrayOfFilteredPartiesInProgress:any = [];
    let tempArrayOfFilteredPartiesToday:any = [];

    if (!isLikedPartiesTagOn) {
    tempArrayOfFilteredPartiesInProgress = props.parties.filter((party: any) => (
      party.daterealstart <= props.currentDateAndTime
      &&
      (tagsSelected.every(tag => party.tags_array.includes(tag)) || tagsSelected.length === 0)
    ));


    tempArrayOfFilteredPartiesToday = props.parties.filter((party: any) => (
      !(party.daterealstart <= props.currentDateAndTime)
      &&
      party.daterealstart.getMonth() === props.currentDateAndTime.getMonth()
      &&
      party.daterealstart.getDate() === props.currentDateAndTime.getDate()
      && 
      party.daterealstart.getFullYear() === props.currentDateAndTime.getFullYear()
      &&
      (tagsSelected.every(tag => party.tags_array.includes(tag)) || tagsSelected.length === 0)
    ));
    }

    if (isLikedPartiesTagOn) {
      tempArrayOfFilteredPartiesInProgress = props.parties.filter((party: any) => (
        party.daterealstart <= props.currentDateAndTime
        &&
        (tagsSelected.every(tag => party.tags_array.includes(tag)) || tagsSelected.length === 0)
        &&
        props.likedParties.filter((ele: any) => ele === party.hash).length > 0
      ));
  
  
      tempArrayOfFilteredPartiesToday = props.parties.filter((party: any) => (
        !(party.daterealstart <= props.currentDateAndTime)
        &&
        party.daterealstart.getMonth() === props.currentDateAndTime.getMonth()
        &&
        party.daterealstart.getDate() === props.currentDateAndTime.getDate()
        && 
        party.daterealstart.getFullYear() === props.currentDateAndTime.getFullYear()
        &&
        (tagsSelected.every(tag => party.tags_array.includes(tag)) || tagsSelected.length === 0)
        &&
        props.likedParties.filter((ele: any) => ele === party.hash).length > 0
      ));
      }

    const inProgressAndToday = tempArrayOfFilteredPartiesInProgress.concat(tempArrayOfFilteredPartiesToday);
      inProgressAndToday.sort((a:any, b:any) => compare(a, b));


    if (tempArrayOfFilteredParties.length > 0) {
      const lastEvent = tempArrayOfFilteredParties?.slice(-1);
      const lastDate = lastEvent[0]?.daterealstart;
      setDateOfLastParty(lastDate);
    }

    setFilteredPartiesInProgressAndToday(inProgressAndToday);
    
    setFilteredParties(tempArrayOfFilteredParties);
  }

  const handleFilterPartiesNew = () => {
    // Determine if the top content should be shown
    setIsTopOnF(!(isLikedPartiesTagOn || tagsSelected.length !== 0));
  
    // Prepare variables for efficient lookups
    const currentDateAndTime = props.currentDateAndTime;
    const tagsSelectedLength = tagsSelected.length;
    const likedPartiesSet = new Set(props.likedParties);
  
    // Prepare search text
    const searchText2Trimmed = searchText2.trim(); // Assuming searchText2 is available in scope
    const hasSearchText = searchText2Trimmed !== '';
    const transformedSearchText:any = hasSearchText
      ? removeAccents(searchText2Trimmed.toLowerCase())
      : null;
  
    // Initialize arrays to collect filtered parties
    const filteredParties = [];
    const filteredPartiesInProgress = [];
    const filteredPartiesToday = [];
  
    // Iterate over all parties once
    for (const party of props.parties) {
      // Check if the party includes all selected tags or if no tags are selected
      const hasAllTags =
        tagsSelectedLength === 0 ||
        tagsSelected.every((tag) => party.tags_array.includes(tag));
  
      // Check if the party is liked
      const isLiked = likedPartiesSet.has(party.hash);
  
      // Decide whether to include the party based on `isLikedPartiesTagOn`
      const includeInMainFilter = hasAllTags && (!isLikedPartiesTagOn || isLiked);
  
      if (includeInMainFilter) {
        // Now check if the party's name matches the search text, if applicable
        let matchesSearchText = true;
        if (hasSearchText) {
          const partyNameTransformed = removeAccents(party.name.toLowerCase());
          matchesSearchText = partyNameTransformed.includes(transformedSearchText);
        }
  
        if (matchesSearchText) {
          // Add to the main filtered parties list
          filteredParties.push(party);
  
          // Determine if the party is in progress or today
          const isInProgress = party.daterealstart <= currentDateAndTime;
          const partyDate = party.daterealstart;
  
          const isToday =
            !isInProgress &&
            partyDate.getFullYear() === currentDateAndTime.getFullYear() &&
            partyDate.getMonth() === currentDateAndTime.getMonth() &&
            partyDate.getDate() === currentDateAndTime.getDate();
  
          if (isInProgress) {
            filteredPartiesInProgress.push(party);
          } else if (isToday) {
            filteredPartiesToday.push(party);
          }
        }
      }
    }
  
    // Combine in-progress and today's parties and sort them
    const inProgressAndToday = filteredPartiesInProgress.concat(
      filteredPartiesToday
    );
    inProgressAndToday.sort((a, b) => compare(a, b));
  
    // Update the date of the last party if available
    if (filteredParties.length > 0) {
      const lastEvent = filteredParties[filteredParties.length - 1];
      const lastDate = lastEvent.daterealstart;
      setDateOfLastParty(lastDate);
    }
  
    // Update state with the filtered lists
    setFilteredPartiesInProgressAndToday(inProgressAndToday);
    setFilteredParties(filteredParties);
  };

  // useEffect(() => {
  //   if (!props.isWide && searchText2 !== "") {
  //     console.log('eg2');
  //     const existing = searchText2;
      
  //     setSearchText(existing);
  //     setIsSearchBarOpen(true);
  //     setSearchText2("");
  //   }
  //   else if (props.isWide && isSearchBarOpen && searchText !== "") {
  //     console.log('eg1');
  //     const existing = searchText;
  //     handleCloseSearchBar();
  //     setSearchText2(existing);
      
  //   } else if (props.isWide && isSearchBarOpen && searchText === "") {
  //     setIsSearchBarOpen(false);
  //   }
  // }, [props.isWide])

  useEffect(() => {
    if (!props.isWide && searchText2 !== "") {
  
      setSearchText2("");
    }
    else if (props.isWide && isSearchBarOpen && searchText !== "") {
      console.log('why not heee');
      handleCloseSearchBar();
      
    } else if (props.isWide && isSearchBarOpen && searchText === "") {
      setIsSearchBarOpen(false);
    }
  }, [props.isWide])

  useEffect(() => {
    // console.log('nottt 1', props.parties.length, props.parties);
    // console.log('nottt 2', props.tagsParties, props.tagsParties.length);
    // console.log('nottt 3', props.citySelected);
    // console.log('nottt 4', props.currentDateAndTime);
    // console.log('nottt 5', props.likedParties);
    // console.log('nottt 6', tagsSelected);
    // console.log('nottt 7', isLikedPartiesTagOn);

    handleFilterPartiesNew();
  }, [
    props.parties,
      props.tagsParties,
      // think more about whether above is required. yes because if you say change a filter like pool party it would need to be removed if pool party tag is on.
      // hmm what about tagsParties. could that create an error
      tagsSelected,





      // props.citySelected,






      props.currentDateAndTime,
      // is the above required? yes i think so but TMA. what about when city changes. well that will cause time to change but why have i used city then in useeffect below re resetting tags...
      // ive added it because setting state is async...
      isLikedPartiesTagOn,
      props.likedParties,
      //the above i think is for where you have filter for liked parties on and you like or unlike a party... you need it to update.



      searchText2

      //below was for top 10
      // props.isLikesOnP
    ]);

  // useEffect(() => {
  //   setPresentingElement(pageParties.current);
  // }, []);

  useEffect(() => {

    // if (!searchText) return;
    
    const tempArrayOfSearchedForParties = props.parties.filter((party: any) => {
      const newName = removeAccents(party.name.toLowerCase());
        return newName.includes(searchText.toLowerCase())
      }
    );
      // console.log(tempArrayOfSearchedForParties, 'search parties something whats different')
      setSearchedForParties(tempArrayOfSearchedForParties)
  }, [searchText, props.parties])
  // previous version had parties as dependent... could have it... but does it matter... if you are in the middle of search does it matter if new party gets added... ill put it in for now

  useEffect(() => {
    setTagsSelected([]);
  }, [props.citySelected]);

  useEffect(() => {
    const tagsShownArray = [];
    const sectionsArray = [];

    for (let i = 0; i < props.tagsParties.length; i++) {
      if (props.parties.some((party: any) => party.tags_array && (party.tags_array.includes(props.tagsParties[i].name) || tagsSelected.includes(props.tagsParties[i].name)))) {
        tagsShownArray.push(props.tagsParties[i].name);
        sectionsArray.push(props.tagsParties[i].section);
      }
    }
    // console.log(tagsShownArray, 'doesthislookright');
    // console.log(sectionsArray, 'doesthislookright2');

    setTagsShown(tagsShownArray);
    setAssociatedSections(sectionsArray);
  }, [props.parties, props.tagsParties]);
  // i added tagsParties. didnt have it in mark 9...


  // useEffect(() => {
  //   console.log('party component city selected', props.citySelected);
  //   console.log('party component reloaded2', filteredPartiesInProgressAndToday);   
  // })


  return (
    <IonPage
    // ref={pageParties}
    >
      <IonHeader>
        <IonToolbar className="transparenttoolbar">
          <div className="toolbar2">
                      
                        
                    <div className="abscontionmenu">
                              <IonIcon
                              icon={reorderThreeOutline}
                              className="toolbaricon3"
                              />
                    <div className="abstlb">
                          <IonButtons className="btnposition">
                            <IonMenuButton color="" className="toolbaricontransparent" />
                          </IonButtons>

                          {/* <IonIcon
                            icon={reorderThreeOutline}
                            className="toolbaricon3"
                            /> */}
                      </div>
                          
                    </div>
                    <div className="abscontionmenu">
                            <IonIcon
                        icon={notificationsOutline}
                        className="toolbaricon"
                      
                        onClick={handleOpenNotifications}
                        />
                                        {
                              (
                                (props.viewedNotificationsAreLoaded)
                                &&
                              
                                (
                                (props.notifications.filter((ele: any) => !props.viewedNotifications.some((ele2: any) => ele.hash === ele2.hash))).length > 0
                                ||
                                !props.viewedNotifications.some((ele2: any) => "hash1" === ele2.hash)
                                ||
                                !props.viewedNotifications.some((ele2: any) => "hash2" === ele2.hash)
                                )
                              )
                              &&
                              <div className="filtercounter">{props.notifications.filter((ele: any) => !props.viewedNotifications.some((ele2: any) => ele.hash === ele2.hash)).length + (!props.viewedNotifications.some((ele2: any) => "hash1" === ele2.hash) ? 1 : 0) + (!props.viewedNotifications.some((ele2: any) => "hash2" === ele2.hash) ? 1 : 0)}</div>
                              }
                    </div>
            {/* <div className="toolbarsideele">

                  
              
             

              <div className="toolbariconcontaineralt">
                    
                    
                    <div className="abscontionmenu tlmr">
                          <IonButtons slot="start">
                            <IonMenuButton className="toolbaricontransparent" />
                          </IonButtons>
                         
                    </div>
                    <div className="abscontionmenu">
                            <IonIcon
                        icon={notificationsOutline}
                        className="toolbaricon"
                      
                        onClick={handleOpenNotifications}
                        />
                                        {
                              (
                                (props.viewedNotificationsAreLoaded)
                                &&
                              
                                (
                                (props.notifications.filter((ele: any) => !props.viewedNotifications.some((ele2: any) => ele.hash === ele2.hash))).length > 0
                                ||
                                !props.viewedNotifications.some((ele2: any) => "hash1" === ele2.hash)
                                ||
                                !props.viewedNotifications.some((ele2: any) => "hash2" === ele2.hash)
                                )
                              )
                              &&
                              <div className="filtercounter">{props.notifications.filter((ele: any) => !props.viewedNotifications.some((ele2: any) => ele.hash === ele2.hash)).length + (!props.viewedNotifications.some((ele2: any) => "hash1" === ele2.hash) ? 1 : 0) + (!props.viewedNotifications.some((ele2: any) => "hash2" === ele2.hash) ? 1 : 0)}</div>
                              }
                    </div>
              </div>

              
              
           
            </div> */}
            <div className="abscontionmenu">
              <div className="toolbarother">
                        <img className="trihq" src={logohq} alt="" />
                        {/* <div className="logocirc">

                          <img className="trihq" src={logohq} alt="" />
                        </div> */}
              
              </div>
            </div>
            <div className="abscontionmenu">
                              {
                              props.parties.length > 0
                              &&
                              <IonIcon
                              icon={searchOutline}
                              className="toolbaricon"
                              onClick={handleOpenSearchBar}
                              />
                              }
                              {
                              props.parties.length === 0
                              &&
                              <IonIcon
                              icon={searchOutline}
                              className="toolbaricon opaquetoolbar"
                              />
                              }
            </div>
            <div className="abscontionmenu">
                          {
                          props.parties.length > 0
                          &&
                          <IonIcon
                          icon={options}
                          className="toolbaricon"              
                          onClick={handleOpenTags}
                          />
                          }
                          {
                          props.parties.length === 0
                          &&
                          <IonIcon
                          icon={options}
                          className="toolbaricon opaquetoolbar"
                          />
                          }

                          {
                          (tagsSelected.length > 0 || isLikedPartiesTagOn)
                          &&
                          props.parties.length > 0
                          
                          &&
                          <div className="filtercounter">{tagsSelected.length + (isLikedPartiesTagOn ? 1 : 0)}</div>
                          }
              </div>
            {/* <div className="toolbarsideele">

                    <div className="toolbariconcontaineralt">
                 
                    
                    <div className="abscontionmenu">
                              {
                              props.parties.length > 0
                              &&
                              <IonIcon
                              icon={searchOutline}
                              className="toolbaricon"
                              onClick={handleOpenSearchBar}
                              />
                              }
                              {
                              props.parties.length === 0
                              &&
                              <IonIcon
                              icon={searchOutline}
                              className="toolbaricon opaquetoolbar"
                              />
                              }
                    </div>
                    <div className="abscontionmenu tlml">
                    {
                          props.parties.length > 0
                          &&
                          <IonIcon
                          icon={options}
                          className="toolbaricon"              
                          onClick={handleOpenTags}
                          />
                          }
                          {
                          props.parties.length === 0
                          &&
                          <IonIcon
                          icon={options}
                          className="toolbaricon opaquetoolbar"
                          />
                          }

                          {
                          (tagsSelected.length > 0 || isLikedPartiesTagOn)
                          &&
                          props.parties.length > 0
                          
                          &&
                          <div className="filtercounter">{tagsSelected.length + (isLikedPartiesTagOn ? 1 : 0)}</div>
                          }
                              </div>
              </div>
                      

            </div> */}
          </div>
          {props.parties.length > 0
          &&
        <div className="toolbar3">
                  <div className="searchtoolbarweb">
                    <IonSearchbar showClearButton="focus" animated={true} placeholder="Search for an event . . ."
                    onIonInput={(event)=>handleSearchText2(event)}
                    value={searchText2}
                    className={searchText2 === "" ? "searchbarpartiesweb" : "searchbarpartiesweb2"}
                    >
                    </IonSearchbar>
                    
                  </div>

                  <div className="menusectb">



                      

                      {
                              (tagsSelected.length === 0)
                      
                              &&
                      <div className="menuicncontalt" onClick={handleOpenTags}>
                      <IonIcon icon={options} className="menicn2"></IonIcon>

                          
                              <div className="filtcirc">
                          
                              <IonIcon icon={addOutline} className="menicnfilt"></IonIcon>

                            </div>
                              
                    
                        
                            {/* Filter */}
                      </div>
                        }
                        {
                              (tagsSelected.length > 0)
                      
                              &&
                      <div className="menuicncontalt2" onClick={handleOpenTags}>
                      <IonIcon icon={options} className="menicn22"></IonIcon>

                          
                            
                              
                      
                          
                              <div className="filtcirc">
                                <div className="menicnfiltnum">{tagsSelected.length}</div>
                              

                            </div>
                            
                        
                        
                      </div>
                        }

                      <div className="newviewtog">
                        <div className={partyView === "gridview" ? "segtext segcolored" : "segtext"}
                        onClick={() => handleViewChange("gridview")}
                        >
                          <IonIcon icon={gridOutline} className="grdic"></IonIcon>
                        </div>
                        <div className="segtog">
                          <IonToggle
                          className="grdcaltog"
                          value={partyView}
                          checked={partyView === "calview"}
                          onIonChange={handleTogView}
                          >
                          </IonToggle>
                        </div>
                        <div className={partyView === "calview" ? "segtext segcolored" : "segtext"}
                        onClick={() => handleViewChange("calview")}
                        >
                          <IonIcon icon={calendarOutline} className="calic"></IonIcon>
                        </div>
                        
                      </div>
                
              </div>
          </div>
          }
          




          
              <div className="mgdatesoth">

                  <div className="testincldate">
                    <div className="gridviewsectionheader6">
                          <div className="extbox pinkcol">
                          {/* {props.citySelected === "San Francisco" ? "SF" : props.citySelected} */}
                          {/* {cityMap[props.citySelected] || props.citySelected} */}
                          {props.citySelected.name_abbr || props.citySelected.city}
                          </div>
                          <IonIcon icon={chevronForwardOutline} className="icncont"></IonIcon>
                          <div className="extbox whitecol">
                          {props.text}
                          </div>

                          
            
          
                          
                 
                  
                  
                      {/* <div className="sechead3"> 
                      <IonIcon icon={helpCircleOutline} className="infoiconhlp"></IonIcon>
                      </div> */}
                      
                    </div>


                    <div className="gridviewsectionheader6">
                        <div className="extbox pinkcolub endclip">
                        {/* {props.currentDateAndTime.getDate()}{props.currentDateAndTime.getMonth()}{props.currentDateAndTime.getHours()}:{props.currentDateAndTime.getMinutes()}{", "} */}
                          
                            {props.currentDateAndTime.toLocaleString("en-US", { weekday: "short", day: "numeric",month: "short", hour: 'numeric', minute: '2-digit' })}
                          </div>
                          

                          
            
          
                          
                 
                  
                  
                      {/* <div className="sechead3"> 
                      <IonIcon icon={helpCircleOutline} className="infoiconhlp"></IonIcon>
                      </div> */}
                      
                    </div>


                </div>



              </div>
              
        </IonToolbar>
      </IonHeader>
      {/* <IonHeader>
        <IonToolbar className="transparenttoolbar">
          
          <div className="toolbar2">
            
                    
                    <div className="">
                      <div className="toolbariconcontaineralt">
                        <div className="abscontionmenu3">
                          <IonIcon
                          icon={reorderThreeOutline}
                          className="toolbariconmenu2"
                          />
                          </div>
                         
                      </div>
                    
                    </div>
                    <div className="toolbtm">
                          Events
                    </div>
                    <div className="toolbariconcontaineralt2">
                      <div className="logocirc">

                        <img className="trihq" src={logohq} alt="" />
                      </div>
                      
                    </div>
            
            
          </div>
          
         
              
        </IonToolbar>
      </IonHeader> */}

      <IonContent fullscreen>


      {/* NOTIFICATIONS MODAL */}
          <IonModal
          ref={modalNotificationsParties}
          // id="example-modalPartiesNotifications"
          isOpen={isNotificationsOpen}
          onDidDismiss={handleCloseNotifications}
          className="notifmodal"
          >
                  <IonToolbar className="notiftoolbar">
                      <div className="notifheader">Notifications</div>
                  </IonToolbar>

                  <IonContent fullscreen className="ioncnotif">

                  <div className="notifgradient">

                                    <div className="notifcard">
                                          <div className="notifcardtop">                                   
                                            <div className="notifcardheadingcont">
                                              <div className="notifcardheading">
                                                Welcome
                                              </div>
                                              
                                              <div className="notifcardlockcont">
                                                  <IonIcon icon={lockClosedOutline} className="notifcardlock"/>
                                              </div>
                                              
                                            </div>
                                          </div>
                                          <div className="notifcardmain">
                                            {/* <div className="notifcardmsg">Welcome to The Gay Agenda, a new app showcasing LGBTQIA+ events and businesses. We are committed to continuous improvement so stay tuned for exciting new features and city-by-city launches. Turn on notifications to receive up-to-date information about events, ticket availability, special deals and much more. Please reach out on Instagram <span className="tgainsta2" onClick={()=>window.open(`https://www.instagram.com/thegayagenda_official`, '_system', 'location=yes')}>{"@"}thegayagenda_official</span> with any suggestions or inquiries.</div> */}
                                            <div className="notifcardmsg">Welcome to The Gay Agenda, a new app showcasing LGBTQIA+ events and businesses. We are committed to continuous improvement, so stay tuned for exciting new features and city-by-city launches. Enable notifications to receive up-to-date information about events, ticket availability, special deals, and much more. Follow the Instagram account for even more exclusive content, updates, and community highlights:</div>
                                            <div className="tgainsta" onClick={()=>window.open(`https://www.instagram.com/thegayagenda_official`, '_system', 'location=yes')}>{"@"}thegayagenda_official</div>
                                          </div>            
                                            
                                          <div className="notifcardbot">
                                            <div className="notifcardbotcont">
                                              <div className="notifcardmeta">{"@"}thegayagenda_official</div>
                                              <div className="notifcardmeta">15 Nov 2023
                                                
                                              </div>
                                                  
                                            </div>
                                          </div>
                                      </div>
                                      <div className="notifcard">
                                          <div className="notifcardtop">                                   
                                            <div className="notifcardheadingcont">
                                              <div className="notifcardheading">
                                                Set City Preferences
                                              </div>
                                              
                                              <div className="notifcardlockcont">
                                                  <IonIcon icon={lockClosedOutline} className="notifcardlock"/>
                                              </div>
                                              
                                            </div>
                                          </div>
                                          <div className="notifcardmalt">
                                            {/* <div className="notifcardmsg">Go to the main menu and click settings:</div>
                                              <div className="setbtncont">
                                                <div className="selectsettbtnmalt">SETTINGS</div>
                                              </div>
                                            <div className="notifcardmsg">Set a default city for the app to load upon startup and select the city or cities for which you would like to receive notifications.</div> */}
                                            <div className="notifcardmsg">In Settings {"("}accessible from the side panel menu{")"} you can now set a default city to load upon startup as well as select the city or cities for which you would like to receive notifications.</div>

                                          </div>            
                                            
                                          <div className="notifcardbot">
                                            <div className="notifcardbotcont">
                                              <div className="notifcardmeta">{"@"}thegayagenda_official</div>
                                              <div className="notifcardmeta">9 Dec 2023
                                                
                                              </div>
                                                  
                                            </div>
                                          </div>
                                      </div>
                                {props.notifications?.map((ele2: any, index: number) => {
                                    return <div key={index} className="notifcard">
                                          <div className="notifcardtop">                                   
                                            <div className="notifcardheadingcont">
                                              <div className="notifcardheading">
                                                {ele2.heading}
                                              </div>
                                              {
                                              ele2.locked
                                              &&
                                              <div className="notifcardlockcont">
                                                  <IonIcon icon={lockClosedOutline} className="notifcardlock"/>
                                              </div>
                                              }
                                            </div>
                                          </div>
                                          <div className="notifcardmain">
                                            <div className="notifcardmsg">{ele2.message}</div>
                                          </div>            
                                            
                                          <div className="notifcardbot">
                                            <div className="notifcardbotcont">
                                              <div className="notifcardmeta">{ele2.author}</div>
                                              <div className="notifcardmeta">{ele2.date}{" "}{ele2.month.slice(0,3)}{" "}{ele2.year}
                                                
                                              </div>
                                                  
                                            </div>
                                          </div>
                                        </div>
                                  
                                }  )}
                            </div>

              </IonContent>
        </IonModal>

        {/* SEARCH MODAL */}
        <IonModal
        isOpen={isSearchBarOpen}
        ref={modalSearchParties}
        // presentingElement={presentingElement}
        // canDismiss={canDismiss}
        onDidDismiss={() => handleCloseSearchBar()}
        // not 100% sure the above will work or if its the best way. the problem was that swiming gesture wasnt resetting search bar. could always turn off swipe. Do i need both this and below...is it doubling up on functions?
        >
          <IonHeader>
            <IonToolbar className="searchtoolbar">
              <div className="searchtoolbardiv">
                <IonSearchbar showClearButton="focus" animated={false} placeholder="Search for an event . . ."
                onIonInput={(event)=>handleSearchText(event)}
                value={searchText}
                className="searchbarparties"
                >
                </IonSearchbar>
                <IonButtons slot="end" className="closebutton">
                  <IonButton className="inncl" color="primary" onClick={handleCloseSearchBar}>Close</IonButton>
                </IonButtons>
              </div>
            </IonToolbar>
          </IonHeader>
          <div className="searchmodalflexbox">
          {/* is the above needed? */}
                                {
                                  searchedForParties.length === 0
                                  &&
                                  <div className="noparties2">No results found.</div>
                                }
            <IonContent>
              {searchedForParties.map((party: any, index: number) => {
                return <Link key={index} to={`/parties/${party.id}`} style={{ display: 'contents' }}
                // onClick={() => dismiss()}
                onClick={() => handleCloseSearchBar()}
                >
                  <div className="searchspotcard">
                    <div className="searchspotfirstsection">
                      {
                      party.image_src
                      &&
                      <img className="searchfirstsectimg" src={party.image_src} alt=""
                      onError={(e: any) => e.target.src = party.image_url}
                      ></img>
                      }
                        {
                      !party.image_src
                      &&
                      <img className="searchfirstsectimg" src={party.image_url} alt=""></img>
                      }
                    </div>
                    <div className="searchspotmainsectionnew">
                        
                            <div className="searchspotmainsectinneroth">{party.name}</div>
                            <div className="searchspotmainsectinnerothb">
                              <div className="addcut">{party.venue}</div>
                              <div className="tinyball"></div>
                              <div className="grncol2">{party.start_date}{" "}{party.start_month.slice(0,3)}{" @ "}{party.start_time}</div>
                              {/* <div className="tinyball3"></div>
                              {party.start_time} */}
                            </div>
                       
                    </div>
                    {/* <div className="searchspotlastsection">
                        <div className="searchspotlastsectcont">
                            <div className="">{party.start_date}{" "}{party.start_month.slice(0,3)}</div>
                            
                        </div>
                        <div className="searchspotlastsectconb">
                          
                            <div className="">
                            
                              {party.start_time}</div>
                        </div>
                    </div> */}
                  </div>
                </Link> 
                })}
            </IonContent>
          </div>
        </IonModal>

        {/* FILTERS MODAL */}
        <IonModal
        isOpen={isFiltersOpen}
        ref={modalTagsParties}
        onDidDismiss={()=>setIsFiltersOpen(false)}
        // initialBreakpoint={1}
        // breakpoints={[0, .25, .5, .75, 1]}
        className="filtersmodalstd"
        //changed above from id to class. see if still works.
        >
          {/* <div className="filtersmodalcontainer"> */}
            <IonContent className="filtersmodalcontainer">
            {/* <div className="removeall"></div> */}
            {/* <div className="gridsectionheading-parties"> */}
              <div className="menusectheader2">GENERAL</div>
            {/* </div> */}
            <div className="filtersgrid">
              <div className="filtersinnerflexbox">
                <div className="filtername2">Tickets left</div>
                <IonToggle
                slot="end"
                value="Tickets left"
                checked={tagsSelected.includes("Tickets left") ? true : false}
                onIonChange={handleTurnOnTag}
                >
                </IonToggle>
              </div>
              {
                !props.isWeb
                &&
              <div className="filtersinnerflexbox">      
                <div className="filtername2">
                  Liked
                
                </div>
                <IonToggle
                slot="end"
                value="Liked parties"
                checked={isLikedPartiesTagOn}
                onIonChange={()=>setIsLikedPartiesTagOn(!isLikedPartiesTagOn)}
                >
                </IonToggle>
              </div>
              }
              
              <div className="filtersinnerflexbox">
                <div className="filtername2">Limited-time</div>
                <IonToggle
                slot="end"
                value="Special"
                checked={tagsSelected.includes("Special") ? true : false}
                onIonChange={handleTurnOnTag}
                >
                </IonToggle>
              </div>
            </div>
            {/* <div className="gridsectionheading-parties"> */}
              <div className="menusectheader2">PRICE</div>
            {/* </div> */}
            <div className="filtersgrid">
              <div className="filtersinnerflexbox">
                <div className="filtername2">Free</div>
                <IonToggle
                slot="end"
                value={"Free"}
                checked={tagsSelected.includes("Free") ? true : false}
                onIonChange={handleTurnOnTag}
                >
                </IonToggle>
              </div>
              <div className="filtersinnerflexbox">
                <div className="filtername2">{"< "}{props.parties[0]?.currency_symbol}100</div>
                <IonToggle
                slot="end"
                value={"100"}
                checked={tagsSelected.includes("100") ? true : false}
                onIonChange={handleTurnOnTag}
                >
                </IonToggle>
              </div>
            </div>
            {
            associatedSections.includes('includes')
            &&
            // <div className="gridsectionheading-parties">
            <div className="menusectheader2">INCLUDES</div>
            // </div>
            }
            {
            associatedSections.includes('includes')
            &&
            <div className="filtersgrid">
              {
              props.tagsParties.filter((ele: any) => ((ele.section === "includes") && tagsShown.filter(tag => tag === ele.name).length > 0))
              .map((tag: any, index: number) => {
                return <div
                key={index}
                className="filtersinnerflexbox"
                >
                  <div className="filtername2">{tag.name}</div>
                  <IonToggle
                  slot="end"
                  value={tag.name}
                  checked={tagsSelected.includes(tag.name) ? true : false}
                  onIonChange={handleTurnOnTag}
                  >
                  </IonToggle>
                </div>
              })
              }
            </div>
            }
            {
            associatedSections.includes('theme')
            &&
            // <div className="gridsectionheading-parties">
            <div className="menusectheader2">THEME</div>
            // </div>
            }
            {
            associatedSections.includes('theme')
            &&
            <div className="filtersgrid">
              {
              props.tagsParties.filter((ele: any) => ((ele.section === "theme") && tagsShown.filter(tag => tag === ele.name).length > 0))
                .map((tag: any, index: number) => {
                return <div
                key={index}
                className="filtersinnerflexbox"
                >
                  <div className="filtername2">{tag.name}</div>
                  <IonToggle
                  slot="end"
                  value={tag.name}
                  checked={tagsSelected.includes(tag.name) ? true : false}
                  onIonChange={handleTurnOnTag}
                  >
                  </IonToggle>
                </div>
              })
              }
            </div>
            }
            {
            associatedSections.includes('target')
            &&
            // <div className="gridsectionheading-parties">
            <div className="menusectheader2">GROUP</div>
              // note im using group not target as actual name
            // </div>
            }
            {
            associatedSections.includes('target')
            &&
            <div className="filtersgrid">
              {
              props.tagsParties.filter((ele: any) => ((ele.section === "target") && tagsShown.filter(tag => tag === ele.name).length > 0))
              .map((tag: any, index: number) => {
                return <div
                key={index}
                className="filtersinnerflexbox"
                >
                  <div className="filtername2">{tag.name}</div>
                  <IonToggle
                  slot="end"
                  value={tag.name}
                  checked={tagsSelected.includes(tag.name) ? true : false}
                  onIonChange={handleTurnOnTag}
                  // disabled
                  >
                  </IonToggle>
                </div>
              })
              }
            </div>
            }
            {
            associatedSections.includes('location')
            &&
            // <div className="gridsectionheading-parties">
            <div className="menusectheader2">LOCATION</div>
            // </div>
            }
            {
            associatedSections.includes('location')
            &&
            <div className="filtersgrid">
              {
              props.tagsParties.filter((ele: any) => ((ele.section === "location") && tagsShown.filter(tag => tag === ele.name).length > 0))
              .map((tag: any, index: number) => {
                return <div
                key={index}
                className="filtersinnerflexbox"
                >
                  <div className="filtername2">{tag.name}</div>
                  <IonToggle
                  slot="end"
                  value={tag.name}
                  checked={tagsSelected.includes(tag.name) ? true : false}
                  onIonChange={handleTurnOnTag}
                  >
                  </IonToggle>
                </div>
              })
              }
            </div>
            }
            {
            associatedSections.includes('meal')
            &&
            // <div className="gridsectionheading-parties">
            <div className="menusectheader2">MEAL</div>
            // </div>
            }
            {
            associatedSections.includes('meal')
            &&
            <div className="filtersgrid">
              {
              props.tagsParties.filter((ele: any) => ((ele.section === "meal") && tagsShown.filter(tag => tag === ele.name).length > 0))
              .map((tag: any, index: number) => {
                return <div
                key={index}
                className="filtersinnerflexbox"
                >
                  <div className="filtername2">{tag.name}</div>
                  <IonToggle
                  slot="end"
                  value={tag.name}
                  checked={tagsSelected.includes(tag.name) ? true : false}
                  onIonChange={handleTurnOnTag}
                  >
                  </IonToggle>
                </div>
              })
              }
            </div>
            }
            </IonContent>
          {/* </div> */}
        </IonModal>



        <IonModal
            ref={modalSeeAll}
            className="caldatepopupmodal"
            isOpen={isSeeAllOpen}
            onDidDismiss={() => setIsSeeAllOpen(false)}
            >
                
               
              
                  
                    <IonToolbar className="toolbarcalpopup">
                        <div className="calpopupheadercontainer">
                            <div className="calpopupleft">
                                
                            </div>
                            
                            <div className="calpopupheader">
                                {props.currentDateAndTime.toLocaleDateString("en-GB", {weekday: "short", year: 'numeric', month: 'short', day: 'numeric'})}
                            </div>
                            
                            <div className="calpopupright">
                                
                            </div>
                        </div>
                    </IonToolbar>
                    <IonContent fullscreen className="calcontent">
                    
                        <div className="ultimategridviewcontainer">
                     

                            {filteredPartiesInProgressAndToday?.map((partymatch: any, index: number) => {
                                return <Link key={index} to={`/parties/${partymatch.id}`} style={{ display: 'contents' }}>
                                    <div className="calviewspotcard"
                                    onClick={() => setIsSeeAllOpen(false)}
                                    >

                                                    <div className="searchspotfirstsection">
                                                        <img className="searchfirstsectimg" src={partymatch.image_src} alt=""></img>
                                                    
                                                    </div>
                                                    <div className="searchspotmainsectionnew">
                                                        
                                                            <div className="searchspotmainsectinneroth2">{partymatch.name}</div>
                                                            {
                                                              (props.currentDateAndTime >= partymatch.daterealstart)
                                                              &&
                                                              <div className="searchspotmainsectinnerothb2">
                                                                  <div className="addcut">{partymatch.venue}</div>
                                                                  <div className="tinyballw"></div>
                                                                  <div className="rdcol2">LIVE</div>
                                                        
                                                              </div>
                                                            }
                                                            {
                                                              (props.currentDateAndTime < partymatch.daterealstart)
                                                              &&
                                                              <div className="searchspotmainsectinnerothb2">
                                                                  <div className="addcut">{partymatch.venue}</div>
                                                                  <div className="tinyball"></div>
                                                                  <div className="grncol2">{partymatch.timestring}</div>
                                                        
                                                              </div>
                                                            }
                                                            
                                                    
                                                    </div>
                                        
                                  
                                       
                                    </div>
                                </Link> 
                            }  )}
                        </div>
                    
                    
                    </IonContent>
                
                
            </IonModal>

        {/* <IonModal
          ref={modalInfoParties}
          isOpen={isInfoOpenP}
          onDidDismiss={() => setIsInfoOpenP(false)}
          className="infomodal"
          >
            <div className="infomodmsg">
                Every effort has been made to ensure the accuracy of event and pricing information. Nonetheless, please check the website of the relevant event organiser or ticket vendor for confirmation. Displayed prices reflect the latest available price of a standard ticket, are rounded to the nearest whole number, and include sales tax where this information is readily accessible.
            </div>
          </IonModal> */}



        {/* <IonRefresher slot="fixed" onIonRefresh={props.handlePullRefresherWithPause}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher> */}




        {/* <IonFab slot="fixed" vertical="bottom" horizontal="end">
                    <IonFabButton size="small" onClick={()=>setIsInfoOpenP(true)} className="fabbord">
                      <IonIcon icon={informationOutline} className="infoicon"></IonIcon>
                    </IonFabButton>
          </IonFab> */}


          

          {/* <div className="gridviewsectionheader3">
            
            {props.citySelected}
            <IonIcon icon={chevronForwardOutline}></IonIcon>
            {props.text}
            
          </div> */}

          {
            props.citySelected.coming_soon
            &&
            <div className="ultimategridviewcontainer">

          
          <div className="gridviewsectionheader2 padmargadj"
          // style={isFiltersOpen ? {display: 'none'} : {display: 'flex'}}
          // style={{marginBottom: '7px'}}
          >
            <div className="gridviewsectionnamecs">
            Events are <span className="upcoming">coming soon</span>. In the meantime, please check out {props.citySelected.city}&apos;s <Link to={`/barsandclubs`} style={{ display: 'contents' }}><span className="plscs">Bars & Clubs.</span></Link>
            </div>
          </div>
          
          </div>
          }

        {
        !props.citySelected.coming_soon && partyView === "gridview"
        &&
        <div className="ultimategridviewcontainer">

          {
            // filteredPartiesInProgressAndToday?.length > 0
            // &&
            //above is to stop this section from loading first


            isTopOnF
            &&
          props.partiesFeat.length > 0
          &&
          // <div className="gridviewsectionheader">
          //   <div className="gridleftline">
          //     <div className="topline"></div>
          //     <div className="botline"></div>
          //   </div>
          //   <div className="gridviewsectionname">TODAY{" ("}{filteredPartiesInProgressAndToday?.length}{")"}</div>
          //   <div className="gridrightline">
          //     <div className="topline"></div>
          //     <div className="botline"></div>
          //   </div>
          //   <div className="gridviewsectionnamep">PULL DOWN TO REFRESH</div>
          //   <div className="gridrightlinefx">
          //     <div className="topline"></div>
          //     <div className="botline"></div>
          //   </div>
          // </div>
          <div className="gridviewsectionheaderft padmargadj"
          // style={isFiltersOpen ? {display: 'none'} : {display: 'flex'}}
          style={{marginBottom: '7px'}}
          >
            <div className="gridviewsectionname2">Featured
            {" ("}{props.partiesFeat.length}{")"}
            </div>
          </div>
          }
          

          
    

          {
            filteredPartiesInProgressAndToday?.length > 0
            &&
            //above is to stop this section from loading first
            isTopOnF
            &&
            (props.partiesFeat.length === 1)
            
            &&
            <div className="gridviewgridfix gvgaddmarginbot"
            // style={{gridTemplateColumns: `repeat(${props.gridNo}, calc(100% / ${props.gridNo}))`}}
            >
              {
              props.partiesFeat.map((party: any, index: number) => {
                return <Link key={index} to={`/parties/${party.id}`} style={{ display: 'contents' }}>
                  <div className="gridviewcardstdfeat">
                  {
                    party.image_src_feat
                    &&
                    <img className="gridviewcardimage" src={party.image_src_feat} alt=""
                    onError={(e: any) => e.target.src = party.image_url}
                    // the above doesnt seem to be working for my particular use cases but I'll keep it there anyway in addition to the conditional
                    />
                    }
                    {
                      !party.image_src_feat
                      &&
                    party.image_src
                    &&
                    <img className="gridviewcardimage" src={party.image_src} alt=""
                    onError={(e: any) => e.target.src = party.image_url}
                    // the above doesnt seem to be working for my particular use cases but I'll keep it there anyway in addition to the conditional
                    />
                    }
                    {
                    !party.image_src
                    &&
                    <img className="gridviewcardimage" src={party.image_url} alt="" />
                    }

                      <div className="gridviewcardimgcoverfeat">

                        {
                          party.sponsored
                          
                          &&
                          <div className="sponscont">
                          Sponsored
                        </div>
                        }
                        
                        

                        
                        <div className="gridviewbottomcontfeat">

                              <div className="gridviewtitlecontainer2">
                              {
                                party.name_short === ""
                                &&
                                <div className="gridviewtitlefull2">{party.name}</div>
                              }
                              {
                                party.name_short !== ""
                                &&
                                <div className="gridviewtitlefull2">{party.name_short}</div>
                              }
                            </div>
                            {
                                (
                                  party.daterealstart <= props.currentDateAndTime
                                  
                              )
                                &&
                            <div className="midcont">
                                <div className="midcontdatelive">
                                    LIVE
                                </div>
                                <div className="tinyballr">

                                </div>
                                <div className="midconttimer">
                                    Ends {party.finish_time}
                                </div>
                            </div>
                            }
                              {
                                (
                                  party.daterealstart > props.currentDateAndTime
                                  &&
                                  party.daterealstart.getMonth() === props.currentDateAndTime.getMonth()
                                &&
                                party.daterealstart.getDate() === props.currentDateAndTime.getDate()
                                && 
                                party.daterealstart.getFullYear() === props.currentDateAndTime.getFullYear()
                              )
                                &&
                            <div className="midcont">
                                <div className="midcontdate">
                                    Today from {party.start_time}
                                </div>
                                {/* <div className="tinyball">

                                </div>
                                <div className="midconttime">
                                    {party.start_time}
                                </div> */}
                            </div>
                            }
                            {
                              party.daterealstart > props.currentDateAndTime
                              &&
                                !(
                                  party.daterealstart.getMonth() === props.currentDateAndTime.getMonth()
                                &&
                                party.daterealstart.getDate() === props.currentDateAndTime.getDate()
                                && 
                                party.daterealstart.getFullYear() === props.currentDateAndTime.getFullYear()
                              )
                                &&
                            <div className="midcont">
                                <div className="midcontdate">
                                    {party.start_day.slice(0,3)}{", "}{party.start_date}{" "}{party.start_month.slice(0,3)}
                                </div>
                                <div className="tinyball">

                                </div>
                                <div className="midconttime">
                                    {party.start_time}
                                </div>
                            </div>
                            }

                            <div className="largedesccont">
                                {party.description}
                            </div>


                          
                        


                          {/* <div className="gridviewtimecontaineroth">
                            
                          

                            {
                            (props.currentDateAndTime >= party.daterealstart)
                            &&
                            <div className="gridviewtimebubblelive">
                              <span className="blinkingtextlive">LIVE</span>
                            </div>
                            }

                          </div> */}
                        </div>

                      </div>

                   
                  </div>
                </Link>
              })
              }
            </div>
          }

          {
            filteredPartiesInProgressAndToday?.length > 0
            &&
            //above is to stop this section from loading first

          // (filteredPartiesInProgressAndToday?.length > 2 || props.gridNo < 2)
          // > props.gridNo
          isTopOnF
            &&
          props.partiesFeat.length > 1
          &&
          <div className="swipercontainerstdft gvgaddmarginbotff">
            <Swiper
            // slidesPerView={props.gridNo > 1 ? props.gridNo + .1 : 1.1}
            slidesPerView={1}
            loop={false}
            autoplay={false}
            // cache={false}
            // direction={'horizontal'}
            modules={[IonicSlides, Pagination]}
            // centeredSlides={true}
            // spaceBetween={0}
            // slidesOffsetBefore={10}
            // slidesOffsetAfter={10}
            // cf to bar and club window. i added that class to it to create padding at bottom.
            // pagination={true}
            // navigation
            className="swipercls"
            pagination={{
              clickable: true,
              // el: 'swiper.pagination',
              type: 'bullets'
           }}
            >
              {
              props.partiesFeat.map((party:any, index:number) => {
                return <SwiperSlide key={index}>
                          <div className="swipercardfeat">
                            <Link to={`/parties/${party.id}`} style={{ display: 'contents' }}>
                            {/* note the div for the card and Link are inverted order. i think i had to do this for swiper section. */}
                            {
                              party.image_src_feat
                              &&
                              <img className="gridviewcardimage" src={party.image_src_feat} alt=""
                              onError={(e: any) => e.target.src = party.image_url}
                              // the above doesnt seem to be working for my particular use cases but I'll keep it there anyway in addition to the conditional
                              />
                              }
                              {
                                !party.image_src_feat
                                &&
                              party.image_src
                              &&
                              <img className="gridviewcardimage" src={party.image_src} alt=""
                              onError={(e: any) => e.target.src = party.image_url}
                              // the above doesnt seem to be working for my particular use cases but I'll keep it there anyway in addition to the conditional
                              />
                              }
                            {
                            !party.image_src
                            &&
                            <img className="gridviewcardimage" src={party.image_url} alt="" />
                            }

                            {/* <div className={party.tickets_available ? "gridviewcardimagecover" : "gridviewcardimagecoversoldout"}> */}
                            <div className="gridviewcardimgcoverfeat">


                                    {
                                  
                                    party.sponsored
                        
                                  &&
                                  <div className="sponscont">
                                  Sponsored
                                </div>
                                }
                              

                              
                              <div className="gridviewbottomcontfeat">

                                    <div className="gridviewtitlecontainer2">
                                    {
                                      party.name_short === ""
                                      &&
                                      <div className="gridviewtitlefull2">{party.name}</div>
                                    }
                                    {
                                      party.name_short !== ""
                                      &&
                                      <div className="gridviewtitlefull2">{party.name_short}</div>
                                    }
                                  </div>
                                    {
                                      (
                                        party.daterealstart.getMonth() === props.currentDateAndTime.getMonth()
                                      &&
                                      party.daterealstart.getDate() === props.currentDateAndTime.getDate()
                                      && 
                                      party.daterealstart.getFullYear() === props.currentDateAndTime.getFullYear()
                                    )
                                      &&
                                  <div className="midcont">
                                      <div className="midcontdate">
                                          Today from {party.start_time}
                                      </div>
                                      {/* <div className="tinyball">

                                      </div>
                                      <div className="midconttime">
                                          {party.start_time}
                                      </div> */}
                                  </div>
                                  }
                                  {
                                      !(
                                        party.daterealstart.getMonth() === props.currentDateAndTime.getMonth()
                                      &&
                                      party.daterealstart.getDate() === props.currentDateAndTime.getDate()
                                      && 
                                      party.daterealstart.getFullYear() === props.currentDateAndTime.getFullYear()
                                    )
                                      &&
                                  <div className="midcont">
                                      <div className="midcontdate">
                                          {party.start_day}{", "}{party.start_date}{" "}{party.start_month}
                                      </div>
                                      <div className="tinyball">

                                      </div>
                                      <div className="midconttime">
                                          {party.start_time}
                                      </div>
                                  </div>
                                  }

                                  <div className="largedesccont">
                                      {party.description}
                                  </div>


                                 
                               


                                {/* <div className="gridviewtimecontaineroth">
                                  
                                

                                  {
                                  (props.currentDateAndTime >= party.daterealstart)
                                  &&
                                  <div className="gridviewtimebubblelive">
                                    <span className="blinkingtextlive">LIVE</span>
                                  </div>
                                  }

                                </div> */}
                              </div>

                            </div>
                            </Link>
                          </div>
                        </SwiperSlide>
              })}   
            </Swiper>
          </div>
          }



{
          filteredPartiesInProgressAndToday
          &&
          // <div className="gridviewsectionheader">
          //   <div className="gridleftline">
          //     <div className="topline"></div>
          //     <div className="botline"></div>
          //   </div>
          //   <div className="gridviewsectionname">TODAY{" ("}{filteredPartiesInProgressAndToday?.length}{")"}</div>
          //   <div className="gridrightline">
          //     <div className="topline"></div>
          //     <div className="botline"></div>
          //   </div>
          //   <div className="gridviewsectionnamep">PULL DOWN TO REFRESH</div>
          //   <div className="gridrightlinefx">
          //     <div className="topline"></div>
          //     <div className="botline"></div>
          //   </div>
          // </div>
          <div className="gridviewsectionheader2 padmargadj">
            <div className="gridviewsectionname2">Today{" ("}{filteredPartiesInProgressAndToday.length}{")"}</div>
            {
              filteredPartiesInProgressAndToday.length > 2
              &&
          <div className="seeall" onClick={() => setIsSeeAllOpen(true)}>See all</div>
            }
            
          </div>
          }
          
          {
          !props.isWide
          &&
          filteredPartiesInProgressAndToday?.length === 0
          &&
          (tagsSelected.length > 0 || isLikedPartiesTagOn)
          &&
          <div className="noparties gridviewlineheightsm negm">
          There are no events <span className="today">today</span> that correspond to the selected filter criteria. Please remove one or more filters.
          </div>
          }
          {
          props.isWide
          &&
          filteredPartiesInProgressAndToday?.length === 0
          &&
          (searchText2 !== "" || tagsSelected.length > 0 || isLikedPartiesTagOn)
          &&
          //do you need props.isWide...can searchText somehow distinguish it
          <div className="noparties gridviewlineheightsm negm">
          There are no events <span className="today">today</span> that correspond to the selected filter and/or search criteria.
          </div>
          }
          {
            !isLikedPartiesTagOn
            &&
          filteredPartiesInProgressAndToday?.length === 0
          &&
          tagsSelected.length === 0
          &&
          searchText2 === ""
          // I dont think i need anymore additional to the above with isWide but TMA.
          &&
            <div className="noparties gridviewlineheightsm negm">
          There are no scheduled events <span className="today">today</span>.
          {/* Check out <Link to={`/barsandclubs`} style={{ display: 'contents' }}><div className="gridviewmiscbubble">Bars & Clubs</div></Link> for open venues. */}
          </div>
          }
          
          

          {
            (filteredPartiesInProgressAndToday?.length >= 1)
            &&
            (
            (filteredPartiesInProgressAndToday?.length === 1 && props.gridNo === 1)
            ||
            (filteredPartiesInProgressAndToday?.length <= 2 && props.gridNo === 2)
            ||
            (filteredPartiesInProgressAndToday?.length <= 3 && props.gridNo === 3)
            ||
            (filteredPartiesInProgressAndToday?.length <= 4 && props.gridNo === 4)
            ||
            (filteredPartiesInProgressAndToday?.length <= 5 && props.gridNo === 5)
            )
            // (filteredPartiesInProgressAndToday?.length === 1 || filteredPartiesInProgressAndToday?.length === 2)
            // &&
            // props.gridNo >= 2
            // (filteredPartiesInProgressAndToday?.length <= props.gridNo)
            &&
            <div className="gridviewgrid gvgaddmarginbot"
            // style={{gridTemplateColumns: `repeat(${props.gridNo}, calc(100% / ${props.gridNo}))`}}
            >
              {
              filteredPartiesInProgressAndToday?.map((party: any, index: number) => {
                return <Link key={index} to={`/parties/${party.id}`} style={{ display: 'contents' }}>
                  <div className="trybox">
                  <div className="gridviewcardstd">
                    {
                    party.image_src
                    &&
                    <img className="gridviewcardimage" src={party.image_src} alt=""
                    onError={(e: any) => e.target.src = party.image_url}
                    // the above doesnt seem to be working for my particular use cases but I'll keep it there anyway in addition to the conditional
                    />
                    }
                    {
                    !party.image_src
                    &&
                    <img className="gridviewcardimage" src={party.image_url} alt="" />
                    }

                {
                            !props.isWeb
                            &&
                        <div className="gridviewcardimagecover2">

                              



                             


                      {
                      
                        props.likedParties.filter((likedpartyhash: any) => likedpartyhash === party.hash).length === 0
                        &&
                        <div className="gridviewlikebuttoncontainer">
                          <IonIcon
                          icon={heartOutline}
                          className="gridviewlikebutton"
                          onClick={(event)=>handleLikeParty(event, party.hash)}
                          />
                        </div>
                        }
                        {
                         
                        props.likedParties.filter((likedpartyhash: any) => likedpartyhash === party.hash).length > 0
                        &&
                        <div className="gridviewlikebuttoncontainer"
                        
                        >
                          <IonIcon icon={heartSharp} color="danger" className="gridviewlikebutton"
                          onClick={(event)=>handleUnlikeParty(event, party.hash)}
                          />
                        </div>
                        }

                        



                        
                        

                      </div>
              }
                 
                  </div>



                  <div className="gridviewbottomcontainertry">

                                    <div className="gridviewtitlecontainer">
                                    {
                                      party.name_short === ""
                                      &&
                                      <div className="gridviewtitlefulloth">{party.name}</div>
                                    }
                                    {
                                      party.name_short !== ""
                                      &&
                                      <div className="gridviewtitlefulloth">{party.name_short}</div>
                                    }



                                {
                                  (props.currentDateAndTime < party.daterealstart)
                                  &&
                                  <div className="midcont2">
                                      
                                    <div className="midcontdate">
                                        Starts {party.start_time}
                                        {/* {party.timestring} */}
                                    </div>
                                    {/* <div className="tinyball">

                                    </div>
                                    <div className="midconttime">
                                        {party.start_time}
                                    </div> */}
                                </div>
                                }
                                {
                                (props.currentDateAndTime >= party.daterealstart)
                                &&
                                <div className="midcont2">
                                      
                                    <div className="midcontdatelive">
                                        LIVE
                                    </div>
                                    <div className="tinyballr">

                                    </div>
                                    <div className="midconttimer">
                                        Ends {party.finish_time}
                                    </div>
                                </div>
                                }
                                    
                                    

                                
                                <div className="midcontalt">
                                        {party.description}
                                </div>
                              </div>


                                 
                               


                                {/* <div className="gridviewtimecontaineroth">
                                  
                                

                                  {
                                  (props.currentDateAndTime >= party.daterealstart)
                                  &&
                                  <div className="gridviewtimebubblelive">
                                    <span className="blinkingtextlive">LIVE</span>
                                  </div>
                                  }

                                </div> */}
                          </div>







                  </div>
                </Link>
              })
              }
            </div>
          }

          {
          // (filteredPartiesInProgressAndToday?.length > 2 || props.gridNo < 2)
          // > props.gridNo
          filteredPartiesInProgressAndToday?.length >= 1
          &&
          filteredPartiesInProgressAndToday?.length > props.gridNo
          &&
          <div className="swipercontainerstd gvgaddmarginbot">
            <Swiper
            slidesPerView={props.gridNo > 1 ? props.gridNo + .1 : 1.1}
            loop={false}
            modules={[IonicSlides]}
            // centeredSlides={true}
            // spaceBetween={0}
            slidesOffsetBefore={10}
            slidesOffsetAfter={10}
            // cf to bar and club window. i added that class to it to create padding at bottom.
            >
              {
              filteredPartiesInProgressAndToday?.map((party:any, index:number) => {
                return <SwiperSlide key={index}>
                          <div className="trybox">
                          <div className="swipercardnew">
                            <Link to={`/parties/${party.id}`} style={{ display: 'contents' }}>
                            {/* note the div for the card and Link are inverted order. i think i had to do this for swiper section. */}
                            {
                            party.image_src
                            &&
                            <img className="gridviewcardimage" src={party.image_src} alt=""
                            onError={(e: any) => e.target.src = party.image_url}
                            // the above doesnt seem to be working for my particular use cases but I'll keep it there anyway in addition to the conditional
                            />
                            }
                            {
                            !party.image_src
                            &&
                            <img className="gridviewcardimage" src={party.image_url} alt="" />
                            }

                            {/* <div className={party.tickets_available ? "gridviewcardimagecover" : "gridviewcardimagecoversoldout"}> */}
                          {
                            !props.isWeb
                            &&
                            <div className="gridviewcardimagecover2">

                              



                             


                            {
                              props.likedParties.filter((likedpartyhash: any) => likedpartyhash === party.hash).length === 0
                              &&
                              <div className="gridviewlikebuttoncontainer">
                                <IonIcon
                                icon={heartOutline}
                                className="gridviewlikebutton"
                                onClick={(event)=>handleLikeParty(event, party.hash)}
                                />
                              </div>
                              }
                              {
                              props.likedParties.filter((likedpartyhash: any) => likedpartyhash === party.hash).length > 0
                              &&
                              <div className="gridviewlikebuttoncontainer"
                              
                              >
                                <IonIcon icon={heartSharp} color="danger" className="gridviewlikebutton"
                                onClick={(event)=>handleUnlikeParty(event, party.hash)}
                                />
                              </div>
                              }

                               



                              
                              

                            </div>
                      }
                            </Link>
                          </div>
                          <div className="gridviewbottomcontainertry">

                                    <div className="gridviewtitlecontainer">
                                    {
                                      party.name_short === ""
                                      &&
                                      <div className="gridviewtitlefulloth extop">{party.name}</div>
                                    }
                                    {
                                      party.name_short !== ""
                                      &&
                                      <div className="gridviewtitlefulloth">{party.name_short}</div>
                                    }



                                {
                                  (props.currentDateAndTime < party.daterealstart)
                                  &&
                                  <div className="midcont2">
                                      
                                    <div className="midcontdate">
                                        Starts {party.start_time}
                                        {/* {party.timestring} */}
                                    </div>
                                    {/* <div className="tinyball">

                                    </div>
                                    <div className="midconttime">
                                        {party.start_time}
                                    </div> */}
                                </div>
                                }
                                {
                                (props.currentDateAndTime >= party.daterealstart)
                                &&
                                <div className="midcont2">
                                      
                                    <div className="midcontdatelive">
                                        LIVE
                                    </div>
                                    <div className="tinyballr">

                                    </div>
                                    <div className="midconttimer">
                                        Ends {party.finish_time}
                                    </div>
                                </div>
                                }
                                    
                                    

                                
                                <div className="midcontalt">
                                        {party.description}
                                </div>
                              </div>


                                 
                               


                                {/* <div className="gridviewtimecontaineroth">
                                  
                                

                                  {
                                  (props.currentDateAndTime >= party.daterealstart)
                                  &&
                                  <div className="gridviewtimebubblelive">
                                    <span className="blinkingtextlive">LIVE</span>
                                  </div>
                                  }

                                </div> */}
                          </div>
                          </div>
                        </SwiperSlide>
              })}   
            </Swiper>
          </div>
          }




        
         
        {/* {
        filteredParties
        &&
        
          props.isLikesOnP
          &&
         
          isTopOn
          &&
      
          <div className="gridviewsectionheader2 padmargadj">
            <div className="gridviewsectionname2">Top Ten</div>
          </div>
          }
         
          {
          filteredParties
          &&
          props.isLikesOnP
          &&
      
          isTopOn
          &&
          <div className="swipercontainerstd2 gvgaddmarginbot">
            <Swiper
           
            className="uniqueswipe"
            slidesPerView={screen.width/200}
          
            loop={false}
            modules={[IonicSlides]}
           

            slidesOffsetBefore={-10}
            
            slidesOffsetAfter={90}
          
            >
              {
              props.topParties?.map((party:any, index:number) => {
                return <SwiperSlide key={index}>
                        <div className="controlswp"
                       
                        >
                          <div className="bignum">{index + 1}</div>
                          <div className="trybox">
                            <div className="swipercard2">
                              <Link to={`/parties/${party.id}`} style={{ display: 'contents' }}>
                             
                              {
                              party.image_src
                              &&
                              <img className="gridviewcardimage" src={party.image_src} alt=""
                              onError={(e: any) => e.target.src = party.image_url}
                              />
                              }
                              {
                              !party.image_src
                              &&
                              <img className="gridviewcardimage" src={party.image_url} alt="" />
                              }

                              <div className="gridviewcardimgcoveroth">
                                      
                                  <div className="gridviewbottomcontainertry">

                                      <div className="gridviewtitlecontainer">
                                      {
                                        party.name_short === ""
                                        &&
                                        <div className="gridviewtitlefullothtt">{party.name}</div>
                                      }
                                      {
                                        party.name_short !== ""
                                        &&
                                        <div className="gridviewtitlefullothtt">{party.name_short}</div>
                                      }

                                      </div>

                                      </div>
                                      
                                      


                              </div>
                              </Link>
                            </div>

                            

                          </div>
                          </div>
                        </SwiperSlide>
              })}   
            </Swiper>
          </div>
          } */}



          {
          filteredPartiesInProgressAndToday
          &&
          // i dont think above does anything. alert.
          <div className="gridviewsectionheader2 padmargadj">
           
            <div className="gridviewsectionname2">
              Upcoming{" ("}{filteredParties?.filter((ele: any) =>
              (ele.daterealstart > props.currentDateAndTime)
              &&
              !(
                ele.daterealstart.getMonth() === props.currentDateAndTime.getMonth()
                &&
                ele.daterealstart.getDate() === props.currentDateAndTime.getDate()
                && 
                ele.daterealstart.getFullYear() === props.currentDateAndTime.getFullYear()
              )
              ).length}{")"}
            </div>
           
          </div>
        }

          {/* <div className="gridviewgrid"
          >
            {
            filteredParties?.filter((ele: any) =>
            (ele.daterealstart > props.currentDateAndTime)
            &&
            !(
              ele.daterealstart.getMonth() === props.currentDateAndTime.getMonth()
              &&
              ele.daterealstart.getDate() === props.currentDateAndTime.getDate()
              && 
              ele.daterealstart.getFullYear() === props.currentDateAndTime.getFullYear()
            )
            )
            .map((party: any, index: number) => {
              return <Link key={index} to={`/parties/${party.id}`} style={{ display: 'contents' }}>
                      <div className="gridviewcardstd2">
                        

                        <div className="gridviewcardimagecover2">

                            {
                            party.image_src
                            &&
                            <img className="gridviewcardimage2" src={party.image_src} alt=""
                            onError={(e: any) => e.target.src = party.image_url}
                            />
                            }
                            {
                            !party.image_src
                            &&
                            <img className="gridviewcardimage2" src={party.image_url} alt="" />
                            }
                        </div>
                        <div className="gridviewbottomcontainer2">
                            <div className="gridviewtitlecontainer2">
                              {
                                party.name_short === ""
                                &&
                                <div className="gridviewtitlefull2">{party.name}</div>
                              }
                              {
                                party.name_short !== ""
                                &&
                                <div className="gridviewtitlefull2">{party.name_short}</div>
                              }
                            </div>
                            
                          </div>
                      </div>
                    </Link>
            })
            }
          </div> */}








          
          {/* FIX whats the point of the below conditional? */}
          {/* {
          filteredParties
          &&
          <div className="gridviewsectionheader padmargadj">
            <div className="gridleftline">
              <div className="topline"></div>
              <div className="botline"></div>
            </div>
            <div className="gridviewsectionname">UPCOMING{" ("}{filteredParties?.filter((ele: any) =>
              (ele.daterealstart > props.currentDateAndTime)
              &&
              !(
                ele.daterealstart.getMonth() === props.currentDateAndTime.getMonth()
                &&
                ele.daterealstart.getDate() === props.currentDateAndTime.getDate()
                && 
                ele.daterealstart.getFullYear() === props.currentDateAndTime.getFullYear()
              )
              ).length}{")"}
            </div>
            <div className="gridrightline">
              <div className="topline"></div>
              <div className="botline"></div>
            </div>
          </div>
          } */}
          

          {
          tagsSelected.length === 0
          &&
          !isLikedPartiesTagOn
          &&
          (
            filteredParties?.filter((ele: any) =>
            (ele.daterealstart > props.currentDateAndTime)
            &&
            !(
              ele.daterealstart.getMonth() === props.currentDateAndTime.getMonth()
              &&
              ele.daterealstart.getDate() === props.currentDateAndTime.getDate()
              && 
              ele.daterealstart.getFullYear() === props.currentDateAndTime.getFullYear()
            )
            // not sure whether to have this logic here. or just have separate grid view only filtered parties list. and have all filtered parties list specific to calendar and list view
            ).length === 0
          )
          &&
          searchText2 === ""
                    // I dont think i need anymore additional to the above with isWide but TMA.
          &&
          <div className="noparties gridviewlineheightsm">
            There are no <span className="upcoming">upcoming</span> events.
          </div>
          }
          {
            !props.isWide
            &&
          (tagsSelected.length > 0 || isLikedPartiesTagOn)
          &&
          (
            filteredParties?.filter((ele: any) =>
            (ele.daterealstart > props.currentDateAndTime)
            &&
            !(
              ele.daterealstart.getMonth() === props.currentDateAndTime.getMonth()
              &&
              ele.daterealstart.getDate() === props.currentDateAndTime.getDate()
              && 
              ele.daterealstart.getFullYear() === props.currentDateAndTime.getFullYear()
            )
            // not sure whether to have this logic here. or just have separate grid view only filtered parties list. and have all filtered parties list specific to calendar and list view
            ).length === 0
          )
          &&
          <div className="noparties gridviewlineheightsm negm">
            There are no <span className="upcoming">upcoming</span> events that correspond to the selected filter criteria. Please remove one or more filters.
          </div>
          }
          {
            props.isWide
            &&
          (searchText2 !== "" || tagsSelected.length > 0 || isLikedPartiesTagOn)
          &&
          (
            filteredParties?.filter((ele: any) =>
            (ele.daterealstart > props.currentDateAndTime)
            &&
            !(
              ele.daterealstart.getMonth() === props.currentDateAndTime.getMonth()
              &&
              ele.daterealstart.getDate() === props.currentDateAndTime.getDate()
              && 
              ele.daterealstart.getFullYear() === props.currentDateAndTime.getFullYear()
            )
            // not sure whether to have this logic here. or just have separate grid view only filtered parties list. and have all filtered parties list specific to calendar and list view
            ).length === 0
          )
          &&
          <div className="noparties gridviewlineheightsm negm">
            There are no <span className="upcoming">upcoming</span> events that correspond to the selected filter and/or search criteria.
          </div>
          }

          <div className="gridviewgrid"
          // style={{gridTemplateColumns: `repeat(${props.gridNo}, calc(100% / ${props.gridNo}))`}}
          >
            {
            filteredParties?.filter((ele: any) =>
            (ele.daterealstart > props.currentDateAndTime)
            &&
            // alert
            !(
              ele.daterealstart.getMonth() === props.currentDateAndTime.getMonth()
              &&
              ele.daterealstart.getDate() === props.currentDateAndTime.getDate()
              && 
              ele.daterealstart.getFullYear() === props.currentDateAndTime.getFullYear()
            )
            // not sure whether to have this logic here. or just have separate grid view only filtered parties list. and have all filtered parties list specific to calendar and list view
            )
            .map((party: any, index: number) => {
              return <Link key={index} to={`/parties/${party.id}`} style={{ display: 'contents' }}>
                      <div className="trybox2">
                      <div className="gridviewcardstdnewnew">
                        {
                        party.image_src
                        &&
                        <img className="gridviewcardimage" src={party.image_src} alt=""
                        // onError={(e: any) => e.target.src = party.image_url}
                        onError={(e: any) => e.target.src = logohq}
                        />
                        }
                        {
                        !party.image_src
                        &&
                        <img className="gridviewcardimage" src={party.image_url} alt="" />
                        // <div>hello</div>
                        }

                        {/* <div className={party.tickets_available ? "gridviewcardimagecover" : "gridviewcardimagecoversoldout"}> */}
                        {
                            !props.isWeb
                            &&
                        <div className="gridviewcardimagecover2">


                          {
                          props.likedParties.filter((likedpartyhash: any) => likedpartyhash === party.hash).length === 0
                          &&
                          <div className="gridviewlikebuttoncontainer">
                            <IonIcon
                            icon={heartOutline}
                            className="gridviewlikebutton"
                            onClick={(event)=>handleLikeParty(event, party.hash)}
                            />
                          </div>
                          }
                          {
                          props.likedParties.filter((likedpartyhash: any) => likedpartyhash === party.hash).length > 0
                          &&
                          <div className="gridviewlikebuttoncontainer"
                          
                          >
                            <IonIcon icon={heartSharp} color="danger" className="gridviewlikebutton"
                            onClick={(event)=>handleUnlikeParty(event, party.hash)}
                            />
                          </div>
                          }



                         
                        {/* {
                          !party.tickets_available
                          &&
                          <div className="gridviewextrasoldoutbox">
                            <div className="gridviewextrasoldouttext">SOLD OUT</div>
                          </div>
                        } */}

                        

                       
                          




                          {/* <div className="gridviewbottomcontainer3">
                            <div className="">
                              {
                                party.name_short === ""
                                &&
                                <div className="gridviewtitlefull">{party.name}</div>
                              }
                              {
                                party.name_short !== ""
                                &&
                                <div className="gridviewtitlefull">{party.name_short}</div>
                              }
                            </div>
                           
                          </div> */}


                          {/* <div className="gridviewunderbottomcontainer3">
                            <div className="underbub undmg">
                              {party.tags[3]}
                            </div>
                            <div className="underbub">
                              {party.tags[1]}
                            </div>
                           
                          </div> */}

                        </div>
                        }
                      </div>
                      <div className="gridviewbottomcontainertry">

                          <div className="gridviewtitlecontainer">
                          {
                            party.name_short === ""
                            &&
                            <div className="gridviewtitlefulloth">{party.name}</div>
                          }
                          {
                            party.name_short !== ""
                            &&
                            <div className="gridviewtitlefulloth">{party.name_short}</div>
                          }

                          <div className="midcont2">
                            <div className="midcontdate">
                                {/* {party.start_day.slice(0,3)}{", "} */}
                                {party.start_date}{" "}{party.start_month.slice(0,3)}
                            </div>
                            <div className="tinyball">

                            </div>
                            <div className="midconttime">
                                {party.start_time}
                            </div>
                          </div>

                          <div className="midcontalt">
                                        {party.description}
                          </div>

                          </div>

                          






                          {/* <div className="gridviewtimecontaineroth">



                          {
                          (props.currentDateAndTime >= party.daterealstart)
                          &&
                          <div className="gridviewtimebubblelive">
                          <span className="blinkingtextlive">LIVE</span>
                          </div>
                          }

                          </div> */}
                          </div>


                      </div>
                    </Link>
            })
            }
          </div>






          





        </div>
        }
        {
        partyView === "calview"
        &&
        <CalViewParties 
        currentDateAndTime={props.currentDateAndTime}
        dateOfLastParty={dateOfLastParty}
        filteredParties={filteredParties}
        filteredPartiesInProgressAndToday={filteredPartiesInProgressAndToday}
        // mardiGras={false}
        />
        }
      </IonContent>

      <div className="segmentcon">
        <IonSegment
        id="check"
        value={partyView}
        className="segment-inner-container"
        swipeGesture={false}
        >
          <IonSegmentButton value="gridview"
          layout="icon-top"
          onClick={() => handleViewChange("gridview")}
          >
            {/* <IonLabel>GRID</IonLabel> */}
            <IonLabel>Grid</IonLabel>
            <IonIcon icon={gridOutline}></IonIcon>
          </IonSegmentButton>
          {
            props.parties.length > 0
            &&
            <IonSegmentButton value="calview"
            onClick={() => handleViewChange("calview")}
          >
            {/* <IonLabel>CALENDAR</IonLabel> */}
            <IonLabel>Calendar</IonLabel>
            <IonIcon icon={calendarOutline}></IonIcon>
          </IonSegmentButton>
          }
          
          
        </IonSegment>
      </div>

    </IonPage>
  );
};

export default Parties;
