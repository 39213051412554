import React, { useState, useEffect, useCallback } from 'react';

import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Link } from 'react-router-dom';

import { Capacitor } from "@capacitor/core";

//ionic
import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact,

  IonSplitPane,
  
  IonHeader,
  IonIcon,

  IonToolbar,
  IonAlert,
  IonModal,
  
  IonToast,
 

  useIonPicker,
  IonContent,
  IonLoading,
  
} from '@ionic/react';

import { arrowForwardOutline, notificationsOutline, options, reorderThreeOutline, searchOutline } from 'ionicons/icons';

//firebase
import { database } from './lib/init-firebase';
import { onValue, ref, update, increment } from 'firebase/database';

//hooks (Ionic Storage, Network Status)
import useNetworkStatus from './hooks/useNetworkStatus'; // Import the custom hook
import { useStorage } from './hooks/useStorage';

import { isPlatform } from '@ionic/core';


// onesignal
import OneSignal from 'onesignal-cordova-plugin';

//Native-Market (to open app stores)
import { NativeMarket } from "@capacitor-community/native-market";

//components
import Menu from './components/Menu';

//pages
import LandingPage from './pages/LandingPage';
import Parties from './pages/Parties';
import PartyWindow from './pages/PartyWindow';
import PartiesFest from './pages/PartiesFest';
import CircFests from './pages/CircFests';
import CircWind from './pages/CircWind';
import BarsAndClubs from './pages/BarsAndClubs';
import Saunas from './pages/Saunas';
import BarAndClubWindow from './pages/BarAndClubWindow';
import SaunaWindow from './pages/SaunaWindow';
import People from './pages/People';
import PersonWindow from './pages/PersonWindow';
import DragTours from './pages/DragTours';
import DragTourWindow from './pages/DragTourWindow';
import Cruises from './pages/Cruises';
import CruiseWindow from './pages/CruiseWindow';

// images
import newlogo from './images/logomarktwo.png';
import logohq from './images/tgalogo_hq.png';
import defaultImage from './images/defaultImage.png';
import earthImage from './images/earthg.png';



/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
// import '@ionic/react/css/padding.css';
// import '@ionic/react/css/float-elements.css';
// import '@ionic/react/css/text-alignment.css';
// import '@ionic/react/css/text-transformation.css';
// import '@ionic/react/css/flex-utils.css';
// import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/splash.css';
import './theme/toolbars.css';


const appVersion = 14;


const SLOW_CONNECTION_THRESHOLD = 0.5 * 1024 * 1024; // 0.5 Mbps

// Define monthMap outside of the component or at the top level
const monthMap: { [key: string]: number } = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};


setupIonicReact();



const App: React.FC = () => {

  const { isConnected, showToast } = useNetworkStatus();

  const {
    storeIsLoaded,
    firstLoad,
    imagesAreLoaded,
    checkIonicStorageImagePointers,
    storedImages,
    checkExistingNotifsAndDetermineAction,
    viewedNotifications,
    setViewedNotifications,
    viewedNotificationsAreLoaded,
    defCity,
    setDefCity,
    defCityIsLoaded,
    citiesNotifs,
    setCitiesNotifs,
    likedParties,
    setLikedParties,
    likedDragExpos,
    setLikedDragExpos,
    likedBarsAndClubs,
    setLikedBarsAndClubs,
    likedPeople,
    setLikedPeople,
    likedCruises,
    setLikedCruises,
    likedCircFests,
    setLikedCircFests,
    isDownloading,
    downloadProgress,
  } = useStorage();

  const [present] = useIonPicker();

  const [isLanding, setIsLanding] = useState(false);


  const [width, setWidth] = useState(window.innerWidth);
  const [gridNo, setGridNo] = useState<any>(null);
  const [isWide, setIsWide] = useState(false);

  const [isWeb, setIsWeb] = useState(false);

  useEffect(() => {
    
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    }
  },[]);

  useEffect(() => {
    if (width < 290) { setGridNo(0) } else
    if ((width >= 290) && (width < 320) ) { setGridNo(1) } else
    if ((width >= 320) && (width < 510) ) { setGridNo(2) } else
    if ((width >= 510) && (width < 690) ) { setGridNo(3) } else
    if ((width >= 690) && (width < 930)) { setGridNo(4) } else
    if ((width >= 930) && (width < 1200)) { setGridNo(5) } else
    if ((width >= 1200) && (width < 1300)) { setGridNo(4) } else
    if (width >= 1300) { setGridNo(5) }

    if (width < 1200 && isWide) setIsWide(false);
    if (width >= 1200 && !isWide) setIsWide(true);

  },[width]);

useEffect(() => {
  if (!isPlatform('hybrid')) {
    setIsWeb(true);
  }
}, [])

  const [openCitySelector, setOpenCitySelector] = useState(true);
  const [festDataIsLoaded, setFestDataIsLoaded] = useState(false);
  const [partyDataIsLoaded, setPartyDataIsLoaded] = useState(false);
  const [dragDataIsLoaded, setDragDataIsLoaded] = useState(false);
  const [venueDataIsLoaded, setVenueDataIsLoaded] = useState(false);
  const [cruiseDataIsLoaded, setCruiseDataIsLoaded] = useState(false);
  const [peopleDataIsLoaded, setPeopleDataIsLoaded] = useState(false);
  const [tagsDataIsLoaded, setTagsDataIsLoaded] = useState(false);
  const [notificationDataIsLoaded, setNotificationDataIsLoaded] = useState(false);
  const [appIsSuspended, setAppIsSuspended] = useState(false);
  const [versionIsCorrect, setVersionIsCorrect] = useState(true);
  const [cityList, setCityList] = useState<any>([]);
  const [cityListN, setCityListN] = useState<any>(null);
  const [cityCoordinates, setCityCoordinates] = useState<any>([]);
  const [cityZoom, setCityZoom] = useState<any>(null);
  const [citySelected, setCitySelected] = useState<any>(null);
  const [currentDateAndTime, setCurrentDateAndTime] = useState<any>(null);
  const [currentDateAndTimePlusTwoWeeks, setCurrentDateAndTimePlusTwoWeeks] = useState<any>(null);
  const [hawaiiTime, setHawaiiTime] = useState<any>(null);
  const [tempCity, setTempCity] = useState<any>("");
  const [rawCities, setRawCities] = useState<any>([]);
  const [rawNotifications, setRawNotifications] = useState<any>([]);
  const [rawImages, setRawImages] = useState<any>([]);
  const [rawParties, setRawParties] = useState<any>([]);
  const [rawDrag, setRawDrag] = useState<any>([]);
  const [rawVenues, setRawVenues] = useState<any>([]);
  const [rawCruises, setRawCruises] = useState<any>([]);
  const [rawPeople, setRawPeople] = useState<any>([]);
  const [rawFestivals, setRawFestivals] = useState<any>([]);
  const [rawTags, setRawTags] = useState<any>([]);
  const [versionServer, setVersionServer] = useState<any>(null);
  const [allParties, setAllParties] = useState<any>([]);
  const [parties, setParties] = useState<any>(null);
  const [partiesFeat, setPartiesFeat] = useState<any>([]);
  const [tagsParties, setTagsParties] = useState<any>([]);
  const [festivals, setFestivals] = useState<any>([]);
  const [festivalsPr, setFestivalsPr] = useState<any>([]);
  const [festivalsCirc, setFestivalsCirc] = useState<any>([]);
  const [circCountryList, setCircCountryList] = useState<any[]>([]);
  const [prideCountryList, setPrideCountryList] = useState<any[]>([]);
  const [venues, setVenues] = useState<any[]>([]);
  const [barsAndClubs, setBarsAndClubs] = useState<any[]>([]);
  const [tagsBC, setTagsBC] = useState<any>([]);
  const [saunas, setSaunas] = useState<any[]>([]);
  const [dragExpos, setDragExpos] = useState<any>(null);
  const [dragTours, setDragTours] = useState<any[]>([]);
  const [people, setPeople] = useState<any[]>([]);
  const [peopleOth, setPeopleOth] = useState<any[]>([]);
  // const [tvShows, setTvShows] = useState<any[]>([]);
  const [tagsPeople, setTagsPeople] = useState<any>([]);
  const [cruises, setCruises] = useState<any[]>([]);
  const [cruiseCountryList, setCruiseCountryList] = useState<any[]>([]);
  const [tagsCruises, setTagsCruises] = useState<any>([]);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [showSlowNetworkToast, setShowSlowNetworkToast] = useState(false);


  const openCapacitorSite = () => {
    let appIdent = '6472125056';
    if (Capacitor.getPlatform() === "android") {
      appIdent = "io.thegayagenda.app";
    }
    NativeMarket.openStoreListing({
        appId: appIdent,
    });
  };


// Call this function when your app starts
function OneSignalInit(): void {
  // Uncomment to set OneSignal device logging to VERBOSE  
  // OneSignal.Debug.setLogLevel(6);
  
  // Uncomment to set OneSignal visual logging to VERBOSE  
  // OneSignal.Debug.setAlertLevel(6);

  // NOTE: Update the init value below with your OneSignal AppId.
  OneSignal.initialize("4d044987-8e3c-4667-970a-43d27180fb51");
  
  
  const myClickListener = async function(event:any) {
        const notificationData = JSON.stringify(event);
    };
  OneSignal.Notifications.addEventListener("click", myClickListener);
  

  // Prompts the user for notification permissions.
  //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
  OneSignal.Notifications.requestPermission(true).then((accepted: boolean) => {
    console.log("User accepted notifications: " + accepted);
  });
}

  // useEffect(() => {
  //   OneSignalInit();
  // },[]);



  const handleCityChange = (cityclicked: string) => {
    if (cityclicked === tempCity) return;
    setTempCity(cityclicked);
  };


  const changeTimeZone = (date: any, timeZone: any) => {
    if (typeof date === 'string') {
      return new Date(
        new Date(date).toLocaleString('en-US', {
          timeZone,
        }),
      );
    } else {
      return new Date(
        date.toLocaleString('en-US', {
          timeZone,
        }),
      );
    }
  }

const changeCityLogic = (cityclicked:any) => {
            setFestDataIsLoaded(false);
            setPartyDataIsLoaded(false);
            setDragDataIsLoaded(false);
            setVenueDataIsLoaded(false);
            setCruiseDataIsLoaded(false);
            setPeopleDataIsLoaded(false);
            setTagsDataIsLoaded(false);
            setNotificationDataIsLoaded(false);

            const cityMatch = cityList.filter((ele:any) => ele.city === cityclicked)[0];
            const newArrayOfCities:any = cityList.filter((ele:any) => ele.city !== cityclicked);
            if (citySelected) {
              newArrayOfCities.push(citySelected);
            }
            newArrayOfCities.sort((a: any, b: any): number => {
              const cityA = a.city.toLowerCase();
              const cityB = b.city.toLowerCase();
            
              if (cityA < cityB) {
                return -1;
              }
              if (cityA > cityB) {
                return 1;
              }
              return 0;
            });
          
            const todaysDate = new Date();
            const changeDate = changeTimeZone(todaysDate, cityMatch.timezone);
            const todayDatePlusTwoWeeks = new Date(changeDate.getTime() + (14 * ( 3600 * 1000 * 24)));
            const hawTime = new Date(todaysDate.toLocaleString('en-US', {timeZone: 'Pacific/Honolulu'}));
           
            setCityList(newArrayOfCities);
            setCitySelected(cityMatch);
            setCityCoordinates([cityMatch.lng, cityMatch.lat]);
            setCityZoom(cityMatch.zoom);
            setCurrentDateAndTime(changeDate);
            setCurrentDateAndTimePlusTwoWeeks(todayDatePlusTwoWeeks);
            setHawaiiTime(hawTime);
}


  const openPickerSelectCity = async () => {
    const arrayOfCityOptions = [];
    for (let i = 0; i < cityList.length; i++) {
      const newObj = {
        text: cityList[i].city,
        value: cityList[i].city
      }
      arrayOfCityOptions.push(newObj);
    }

    present({
      columns: [
        {
          name: 'cities',
          options: arrayOfCityOptions
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          handler: (value) => {
            if (value.cities.value === citySelected.city) {
              return;
            }

            changeCityLogic(value.cities.value);

          },
        },
      ],
    });
  }


  const firstSetCity = (cityClicked:any) => {

    const cityMatch = cityList.filter((ele:any) => ele.city === cityClicked)[0];
    const newArrayOfCities:any = cityList.filter((ele:any) => ele.city !== cityClicked);
  
    newArrayOfCities.sort((a: any, b: any): number => {
      const cityA = a.city.toLowerCase();
      const cityB = b.city.toLowerCase();
    
      if (cityA < cityB) {
        return -1;
      }
      if (cityA > cityB) {
        return 1;
      }
      return 0;
    });
  

    const todaysDate = new Date();
    const changeDate = changeTimeZone(todaysDate, cityMatch.timezone);
    const todayDatePlusTwoWeeks = new Date(changeDate.getTime() + (14 * ( 3600 * 1000 * 24)));
    const hawTime = new Date(todaysDate.toLocaleString('en-US', {timeZone: 'Pacific/Honolulu'}));

    setDefCity(cityMatch.city);
    setCityList(newArrayOfCities);
    setCitySelected(cityMatch);
    setCityCoordinates([cityMatch.lng, cityMatch.lat]);
    setCityZoom(cityMatch.zoom);
    setCurrentDateAndTime(changeDate);
    setCurrentDateAndTimePlusTwoWeeks(todayDatePlusTwoWeeks);
    setHawaiiTime(hawTime);
    setOpenCitySelector(false);
  }

  
  const handlePullRefresher = (TZParam:any) => {

    const todaysDate = new Date();
    const changeDate = changeTimeZone(todaysDate, TZParam);
    const todayDatePlusTwoWeeks = new Date(changeDate.getTime() + (14 * ( 3600 * 1000 * 24)));
    const hawTime = new Date(todaysDate.toLocaleString('en-US', {timeZone: 'Pacific/Honolulu'}));

    setCurrentDateAndTime(changeDate);
    setCurrentDateAndTimePlusTwoWeeks(todayDatePlusTwoWeeks);
    setHawaiiTime(hawTime);
  }

  
  const handleIncrement = (type:any, hash:any) => {
    const dbRef = ref(database);

    const updates:any = {};
    updates[`data/likes/${type}/${hash}/like_count`] = increment(1);
    
    update(dbRef, updates);
  }

  const handleDecrement = (type:any, hash:any) => {
    const dbRef = ref(database);

    const updates:any = {};
    updates[`data/likes/${type}/${hash}/like_count`] = increment(-1);
    
    update(dbRef, updates);
  }

  const handleIncrClick = (type:any, hash:any) => {
    const dbRef = ref(database);

    const updates:any = {};
    updates[`data/webclicks/${type}/${hash}/click_count`] = increment(1);
    
    update(dbRef, updates);
  }

  const handleClickUpdate = () => {
    const dbRef = ref(database);

    const updates:any = {};
    updates[`data/updates/fourteen/click_count`] = increment(1);
    update(dbRef, updates);
  }



  const addCitiesNotifs = (city: string) => {
    setCitiesNotifs((prevCities: string[]) => {
      const cityIndex = prevCities.indexOf(city);
      if (cityIndex !== -1) {
        // City exists, remove it
        // OneSignal.User.addTag(city, "false");
        return [...prevCities.slice(0, cityIndex), ...prevCities.slice(cityIndex + 1)];
      } else {
        // City doesn't exist, add it
        // OneSignal.User.addTag(city, "true");
        return [...prevCities, city];
      }
    });
  }



  const handleToggleCity = (event: CustomEvent) => {
    addCitiesNotifs(event.detail.value);
  };


  const checkNetworkSpeed = async () => {
    try {
      const startTime = Date.now();
      await fetch('https://www.google.com/favicon.ico', {
        mode: 'no-cors'
      });
  
      const endTime = Date.now();
      const durationInSeconds = (endTime - startTime) / 1000;
  
      // Since mode is 'no-cors', the response body is opaque and we can't access its content,
      // but we can still use the duration to measure network speed
      const estimatedFileSizeInBits = 700 * 8; // Estimate the size of the favicon (700 bytes)
  
      // bits per second
      const speed = estimatedFileSizeInBits / durationInSeconds;
  
      return speed;
    } catch (error) {
      // console.error('Error checking network speed:', error);
      return 0; // Returning 0 or an appropriate value in case of error
    }
  };





useEffect(() => {
  if (isConnected) {

    const now = Date.now();

    const suspendQuery = ref(database, '/production/app/suspend');
    const cityQuery = ref(database, '/production/cities');
    const notificationQuery = ref(database, '/production/notifications');
    const imageQuery = ref(database, '/production/images');
    const partyQuery = ref(database, '/production/events');
    const dragQuery = ref(database, '/production/drag');
    const venueQuery = ref(database, '/production/venues/online');
    const cruiseQuery = ref(database, '/production/cruises');
    const peopleQuery = ref(database, '/production/people');
    const festivalQuery = ref(database, '/production/festivals');
    const tagQuery = ref(database, '/production/tags');
    const versionQuery = ref(database, '/production/app/fourteen');

    const suspendUnsubscribe = onValue(suspendQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setAppIsSuspended(data);
    });

    const cityUnsubscribe = onValue(cityQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setRawCities(Object.values(data));
    });

    const notificationUnsubscribe = onValue(notificationQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setRawNotifications(Object.values(data));
    });

    const imageUnsubscribe = onValue(imageQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setRawImages(Object.values(data));
    });

    const partyUnsubscribe = onValue(partyQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setRawParties(Object.values(data));
    });

    const dragUnsubscribe = onValue(dragQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setRawDrag(Object.values(data));
    });

    const venueUnsubscribe = onValue(venueQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setRawVenues(Object.values(data));
    });

    const cruiseUnsubscribe = onValue(cruiseQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setRawCruises(Object.values(data));
    });

    const peopleUnsubscribe = onValue(peopleQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setRawPeople(Object.values(data));
    });

    const festivalUnsubscribe = onValue(festivalQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setRawFestivals(Object.values(data));
    });

    const tagUnsubscribe = onValue(tagQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) setRawTags(Object.values(data));
    });

    const versionUnsubscribe = onValue(versionQuery, (snapshot) => {
      const data = snapshot.val();
      if (data !== null && data !== undefined) {
        setVersionServer(data);
      } else if (data === undefined) {
        setVersionServer(1);
      }
    });

    return () => {
      suspendUnsubscribe();
      cityUnsubscribe();
      notificationUnsubscribe();
      imageUnsubscribe();
      partyUnsubscribe();
      dragUnsubscribe();
      venueUnsubscribe();
      cruiseUnsubscribe();
      peopleUnsubscribe();
      festivalUnsubscribe();
      tagUnsubscribe();
      versionUnsubscribe();
    };
  }
}, [isConnected]);


  useEffect(() => {
    if (firstLoad) {
      const intervalId = setInterval(async () => {
        const networkSpeed = await checkNetworkSpeed();
        if (networkSpeed < SLOW_CONNECTION_THRESHOLD) {
          setShowSlowNetworkToast(true);
          setTimeout(() => setShowSlowNetworkToast(false), 3000);
        }
      }, 5000); // Check every 5 seconds

      return () => clearInterval(intervalId);
    }
  }, [firstLoad]);


useEffect(() => {
  if (versionServer) {
      if (versionServer !== appVersion) {
        setVersionIsCorrect(false);
      } else if (versionServer === appVersion
        ) {
        setVersionIsCorrect(true);
      }
  }
}, [versionServer]);



  useEffect(() => {

    if (!storeIsLoaded || !rawCities.length || !defCityIsLoaded) {
      return;
    }
    
      const dataArrayCities: any = [];

      for (let i = 0; i < rawCities.length; i++) {
        if (
          (rawCities[i].display)
          ) {
            dataArrayCities.push(rawCities[i]);
        }
      }

      dataArrayCities.sort((a: any, b: any): number => {
        const cityA = a.city.toLowerCase();
        const cityB = b.city.toLowerCase();
      
        if (cityA < cityB) {
          return -1;
        }
        if (cityA > cityB) {
          return 1;
        }
        return 0;
      });

      const cityNames = [];
      for (let i = 0; i < dataArrayCities.length; i++) {
        cityNames.push(dataArrayCities[i].city)
      }

      setCityListN(cityNames);
      checkExistingNotifsAndDetermineAction(cityNames);
      
      if (defCity === "") {
        
          setCityList(dataArrayCities);
        
        return;
      }
      
      if (openCitySelector) {
        const cityMatch = dataArrayCities.filter((ele:any) => ele.city === defCity)[0];
        const newArrayOfCities:any = dataArrayCities.filter((ele:any) => ele.city !== defCity);
        
        newArrayOfCities.sort((a: any, b: any):any => {
                a = a.city;
                b = b.city;
                return a - b
              });
    
        const todaysDate = new Date();
        const changeDate = changeTimeZone(todaysDate, cityMatch.timezone);
        const todayDatePlusTwoWeeks = new Date(changeDate.getTime() + (14 * ( 3600 * 1000 * 24)));
        const hawTime = new Date(todaysDate.toLocaleString('en-US', {timeZone: 'Pacific/Honolulu'}));

        setCityList(newArrayOfCities);
        setCitySelected(cityMatch);
        setCityCoordinates([cityMatch.lng, cityMatch.lat]);
        setCityZoom(cityMatch.zoom);
        setCurrentDateAndTime(changeDate);
        setCurrentDateAndTimePlusTwoWeeks(todayDatePlusTwoWeeks);
        setHawaiiTime(hawTime);
        setOpenCitySelector(false);
      } else {
        const cityMatch = dataArrayCities.filter((ele:any) => ele.city === citySelected.city)[0];
        const newArrayOfCities:any = dataArrayCities.filter((ele:any) => ele.city !== citySelected.city);

        newArrayOfCities.sort((a: any, b: any):any => {
                a = a.city;
                b = b.city;
                return a - b
              });
       
        const todaysDate = new Date();       
        const changeDate = changeTimeZone(todaysDate, cityMatch.timezone);
        const todayDatePlusTwoWeeks = new Date(changeDate.getTime() + (14 * ( 3600 * 1000 * 24)));
        const hawTime = new Date(todaysDate.toLocaleString('en-US', {timeZone: 'Pacific/Honolulu'}));
    
        setCityList(newArrayOfCities);
        setCitySelected(cityMatch);
        setCityCoordinates([cityMatch.lng, cityMatch.lat]);
        setCityZoom(cityMatch.zoom);
        setCurrentDateAndTime(changeDate);
        setCurrentDateAndTimePlusTwoWeeks(todayDatePlusTwoWeeks);
        setHawaiiTime(hawTime);
      }
    
    
  }, [
    storeIsLoaded,
    rawCities,
    defCityIsLoaded,
  ]
  );

  useEffect(() => {
    if (!storeIsLoaded || !rawImages.length) {
      return;
    }

    const dataArrayImages: any = [];

      for (let i = 0; i < rawImages.length; i++) {
        if (
          rawImages[i].display
          ) {
            dataArrayImages.push(rawImages[i]);
        }
      }

      checkIonicStorageImagePointers(dataArrayImages);

  }, [
    storeIsLoaded,
    rawImages,
  ]);



useEffect(() => {
  
  if (!storeIsLoaded || !imagesAreLoaded || !citySelected || !currentDateAndTime || !rawParties.length) {
    return;
  }
 

      // let dataArrayParties: any = [];


      // for (let i = 0; i < rawParties.length; i++) {
      //   if (
      //     rawParties[i].city === citySelected.city
      //     ) {
      //     dataArrayParties.push(rawParties[i]);
      //   }
      // }

      const dataArrayParties = rawParties.filter((party: any) => 
        party.city === citySelected.city && party.display
      );

    
      //  for (let i = 0; i < dataArrayParties.length; i++) {
      //   let datemonth: any;
      //   switch(dataArrayParties[i].finish_month) {
      //     case "January":
      //     datemonth = 0;
      //     break;
      //     case "February":
      //     datemonth = 1;
      //     break;
      //     case "March":
      //     datemonth = 2;
      //     break;
      //     case "April":
      //     datemonth = 3;
      //     break;
      //     case "May":
      //     datemonth = 4;
      //     break;
      //     case "June":
      //     datemonth = 5;
      //     break;
      //     case "July":
      //     datemonth = 6;
      //     break;
      //     case "August":
      //     datemonth = 7;
      //     break;
      //     case "September":
      //     datemonth = 8;
      //     break;
      //     case "October":
      //     datemonth = 9;
      //     break;
      //     case "November":
      //     datemonth = 10;
      //     break;
      //     case "December":
      //     datemonth = 11;
      //   }
      //   const newHourFinish = +dataArrayParties[i].timeorderfinish.toString().slice(0,-2);
      //   const newMinutesFinish = +dataArrayParties[i].timeorderfinish.toString().slice(-2);
      //   const newDateFinish = new Date(+dataArrayParties[i].finish_year, datemonth, +dataArrayParties[i].finish_date, newHourFinish, newMinutesFinish);
      //   dataArrayParties[i]['daterealfinish'] = newDateFinish;
      // }

      const partiesWithFinishDate = dataArrayParties.map((party: any) => {
        
        const datemonth = monthMap[party.finish_month];
        const newHourFinish = Math.floor(party.timeorderfinish / 100);
        const newMinutesFinish = party.timeorderfinish % 100;
        const daterealfinish = new Date(
          +party.finish_year,
          datemonth,
          +party.finish_date,
          newHourFinish,
          newMinutesFinish
        );
      
        return { ...party, daterealfinish };
      });
      
      // dataArrayParties = dataArrayParties.filter((ele: any) => ele.daterealfinish > currentDateAndTime);

      const upcomingParties = partiesWithFinishDate.filter(
        (party: any) => party.daterealfinish > currentDateAndTime
      );

      // for (let i = 0; i < dataArrayParties.length; i++) {
      //   let datemonth: any;
      //   switch(dataArrayParties[i].start_month) {
      //     case "January":
      //     datemonth = 0;
      //     break;
      //     case "February":
      //     datemonth = 1;
      //     break;
      //     case "March":
      //     datemonth = 2;
      //     break;
      //     case "April":
      //     datemonth = 3;
      //     break;
      //     case "May":
      //     datemonth = 4;
      //     break;
      //     case "June":
      //     datemonth = 5;
      //     break;
      //     case "July":
      //     datemonth = 6;
      //     break;
      //     case "August":
      //     datemonth = 7;
      //     break;
      //     case "September":
      //     datemonth = 8;
      //     break;
      //     case "October":
      //     datemonth = 9;
      //     break;
      //     case "November":
      //     datemonth = 10;
      //     break;
      //     case "December":
      //     datemonth = 11;
      //   }
      //   const newHour = +dataArrayParties[i].timeorder.toString().slice(0,-2);
      //   const newMinutes = +dataArrayParties[i].timeorder.toString().slice(-2);
      //   const newDate = new Date(+dataArrayParties[i].start_year, datemonth, +dataArrayParties[i].start_date, newHour, newMinutes);
      //   dataArrayParties[i]['daterealstart'] = newDate;
      // }

      
      const partiesWithStartDate = upcomingParties.map((party: any) => {
      
        const datemonth = monthMap[party.start_month];
        const newHourStart = Math.floor(party.timeorder / 100);
        const newMinutesStart = party.timeorder % 100;
        const daterealstart = new Date(
          +party.start_year,
          datemonth,
          +party.start_date,
          newHourStart,
          newMinutesStart
        );
      
        return { ...party, daterealstart };
      });
      
            
      // for (let i = 0; i < dataArrayParties.length; i++) {
      //   if (!dataArrayParties[i].tags_array) {
      //     const tagsArray = [];
      //     if (dataArrayParties[i].tickets_available) {
      //       tagsArray.push('Tickets left');
      //     }
      //     if (!dataArrayParties[i].template_id) {
      //       tagsArray.push('Special');
      //     }
      //     if (dataArrayParties[i].price === 0) {
      //       tagsArray.push('Free');
      //     }
      //     if (dataArrayParties[i].price < 100) {
      //       tagsArray.push('100');
      //     }
      //     if (dataArrayParties[i].live_music) {
      //       tagsArray.push('Live music');
      //     }
      //     if (dataArrayParties[i].boat_party) {
      //       tagsArray.push('Boat');
      //     }
      //     if (dataArrayParties[i].pool_party) {
      //       tagsArray.push('Pool');
      //     }
      //     if (dataArrayParties[i].beach_party) {
      //       tagsArray.push('Beach');
      //     }
      //     if (dataArrayParties[i].drag) {
      //       tagsArray.push('Drag shows');
      //     }
      //     if (dataArrayParties[i].trivia_or_games) {
      //       tagsArray.push('Trivia/games');
      //     }
      //     if (dataArrayParties[i].karaoke) {
      //       tagsArray.push('Karaoke');
      //     }
      //     if (dataArrayParties[i].underwear_party) {
      //       tagsArray.push('Underwear');
      //     }
      //     if (dataArrayParties[i].fetish_party) {
      //       tagsArray.push('Fetish');
      //     }
      //     if (dataArrayParties[i].bears_party) {
      //       tagsArray.push('Bears');
      //     }
      //     if (dataArrayParties[i].trans_party) {
      //       tagsArray.push('Trans');
      //     }
      //     if (dataArrayParties[i].non_binary) {
      //       tagsArray.push('Non-binary');
      //     }
      //     if (dataArrayParties[i].lesbian_party) {
      //       tagsArray.push('Women');
      //     }
      //     if (dataArrayParties[i].watch_party) {
      //       tagsArray.push('Screening');
      //     }
      //     if (dataArrayParties[i].festival_viewing_party) {
      //       tagsArray.push('Parade');
      //     }
      //     if (dataArrayParties[i].brunch) {
      //       tagsArray.push('Brunch');
      //     }
      //     if (dataArrayParties[i].lunch) {
      //       tagsArray.push('Lunch');
      //     }
      //     if (dataArrayParties[i].dinner) {
      //       tagsArray.push('Dinner');
      //     }
      //     if (tagsArray.indexOf(dataArrayParties[i].city) === -1) {
      //       tagsArray.push(dataArrayParties[i].city)
      //     }
      //     dataArrayParties[i]['tags_array'] = tagsArray;
      //   }
      // }

      const partiesWithTags = partiesWithStartDate.map((party: any) => {
        if (party.tags_array) return party; // If tags_array already exists, skip
        console.log('attention. at least one event without a tags array.');
        const tagsArray = [];
      
        // Add tags based on conditions
        if (party.tickets_available) tagsArray.push('Tickets left');
        if (!party.template_id) tagsArray.push('Special');
        if (party.price === 0) tagsArray.push('Free');
        if (party.price < 100) tagsArray.push('Under $100');
        if (party.live_music) tagsArray.push('Live music');
        if (party.boat_party) tagsArray.push('Boat party');
        if (party.pool_party) tagsArray.push('Pool party');
        if (party.beach_party) tagsArray.push('Beach party');
        if (party.drag) tagsArray.push('Drag shows');
        if (party.trivia_or_games) tagsArray.push('Trivia/games');
        if (party.karaoke) tagsArray.push('Karaoke');
        if (party.underwear_party) tagsArray.push('Underwear party');
        if (party.fetish_party) tagsArray.push('Fetish party');
        if (party.bears_party) tagsArray.push('Bears party');
        if (party.trans_party) tagsArray.push('Trans party');
        if (party.non_binary) tagsArray.push('Non-binary');
        if (party.lesbian_party) tagsArray.push('Women');
        if (party.watch_party) tagsArray.push('Screening');
        if (party.festival_viewing_party) tagsArray.push('Parade');
        if (party.brunch) tagsArray.push('Brunch');
        if (party.lunch) tagsArray.push('Lunch');
        if (party.dinner) tagsArray.push('Dinner');
        if (!tagsArray.includes(party.city)) tagsArray.push(party.city);
      
        return { ...party, tags_array: tagsArray };
      });
      
      // const compare = (a:any,b:any) => {
      //   if (a.daterealstart.getTime() < b.daterealstart.getTime() || (a.start_time == b.start_time && a.daterealfinish.getTime() < b.daterealfinish.getTime()))
      //     return -1;
      //   if (a.daterealstart.getTime() > b.daterealstart.getTime() || (a.start_time == b.start_time && a.daterealfinish.getTime() > b.daterealfinish.getTime()))
      //     return 1;
      //   return 0;
      // }

      // dataArrayParties.sort(compare);

      const sortedParties = [...partiesWithTags].sort((a: any, b: any) => {
        if (a.daterealstart.getTime() !== b.daterealstart.getTime()) {
          return a.daterealstart.getTime() - b.daterealstart.getTime();
        }
        return a.daterealfinish.getTime() - b.daterealfinish.getTime();
      });

      
      // for (let i = 0; i < dataArrayParties.length; i++) {
      //   if (!dataArrayParties[i].timestring) {
      //       if (dataArrayParties[i].start_time.slice(-2) !== dataArrayParties[i].finish_time.slice(-2)) {
      //         const timeString = dataArrayParties[i].start_time + " - " + dataArrayParties[i].finish_time;
      //         dataArrayParties[i]['timestring'] = timeString;
      //       } else {
      //         const timeString = dataArrayParties[i].start_time.slice(0,-2) + " - " + dataArrayParties[i].finish_time;
      //         dataArrayParties[i]['timestring'] = timeString;
      //       }
      //   }
      // }

        const partiesWithTimeString = sortedParties.map((party: any) => {
          if (party.timestring) return party; // If timestring already exists, skip
          console.log('attention. at least one event without a timestring.');
          let timestring;
          if (party.start_time.slice(-2) !== party.finish_time.slice(-2)) {
            timestring = `${party.start_time} - ${party.finish_time}`;
          } else {
            timestring = `${party.start_time.slice(0, -2)} - ${party.finish_time}`;
          }
        
          return { ...party, timestring };
        });

      // for (let i = 0; i < dataArrayParties.length; i++) {
      //     const newImageSrc = storedImages.filter((ele:any) => ele.name === dataArrayParties[i].image_name)[0]?.useableFilePath;
      //     dataArrayParties[i].image_src = newImageSrc;
      //     if (dataArrayParties[i].image_name_feat !== "") {
      //       const newImageSrc2 = storedImages.filter((ele:any) => ele.name === dataArrayParties[i].image_name_feat)[0]?.useableFilePath;
      //     dataArrayParties[i].image_src_feat = newImageSrc2;
      //     }
      // }

      const imageMap = new Map<string, any>();
      storedImages.forEach((image: any) => {
        imageMap.set(image.name, image.useableFilePath);
      });

      const partiesWithImages = partiesWithTimeString.map((party: any) => {
        const image_src = imageMap.get(party.image_name) ?? defaultImage;
        let image_src_feat = undefined;
        if (party.image_name_feat) {
          image_src_feat = imageMap.get(party.image_name_feat) ?? defaultImage;
        }

        return { ...party, image_src, image_src_feat };
      });


      // for (let i = 0; i < dataArrayParties.length; i++) {
      //   if (dataArrayParties[i].festparam === null || dataArrayParties[i].festparam === undefined) {
      //     if (dataArrayParties[i].festival === "") {
      //       dataArrayParties[i]['festparam'] = "";
      //     } else if (dataArrayParties[i].festival !== "") {
      //       const fest1 = dataArrayParties[i].festival.toLowerCase();
      //       const fest2 = fest1.split(" ").join("");
      //       dataArrayParties[i]['festparam'] = fest2;
      //     }
      //   }
      // }

      const finalParties = partiesWithImages.map((party: any) => {
        let festparam = party.festparam;
        // if (!festparam) {
        if (festparam === null || festparam === undefined) {
          console.log('attention. at least one event without a festparam.');
          if (!party.festival) {
            festparam = '';
          } else {
            // festparam = party.festival.toLowerCase().replace(/\s+/g, '');
            festparam = party.festival.toLowerCase().split(' ').join('');
          }
        }
        return { ...party, festparam };
      });
      
    
      // setParties(dataArrayParties);
      // setAllParties(rawParties.filter((party:any) => party.display));

      setParties(finalParties);
      setAllParties(rawParties.filter((party: any) => party.display));

      // const dataArrayPartiesFeat: any = [];

      // for (let i = 0; i < dataArrayParties.length; i++) {
      //   if (
      //     (dataArrayParties[i].featured)
      //     &&
      //     (dataArrayParties[i].daterealfinish > currentDateAndTime)
      //   )
      //    {
      //     dataArrayPartiesFeat.push(dataArrayParties[i])
      //   }
      // }

      const partiesFeat = finalParties.filter(
        (party: any) => party.featured && party.daterealfinish > currentDateAndTime
      );

      // setPartiesFeat(dataArrayPartiesFeat);
      // setPartyDataIsLoaded(true);

      setPartiesFeat(partiesFeat);
      setPartyDataIsLoaded(true);
    

}, [storeIsLoaded, imagesAreLoaded, citySelected, currentDateAndTime, rawParties, storedImages])



useEffect(() => {
  if (!storeIsLoaded || !imagesAreLoaded || !citySelected || !currentDateAndTime || !rawDrag.length) {
    return;
  }

      // let dataArrayDragExpos: any = [];
      // for (let i = 0; i < rawDrag.length; i++) {
      //   if (
      //     rawDrag[i].city === citySelected.city
      //     &&
      //     rawDrag[i].display
      //   ) {
      //     dataArrayDragExpos.push(rawDrag[i]);
      //   }
      // }

        // **1. Filter Drag Expos by City and Display**
        const dataArrayDragExpos = rawDrag.filter(
          (drag: any) => drag.city === citySelected.city && drag.display
        );

      // for (let i = 0; i < dataArrayDragExpos.length; i++) {
      //   let datemonth: any;
      //   switch(dataArrayDragExpos[i].finish_month) {
      //     case "January":
      //     datemonth = 0;
      //     break;
      //     case "February":
      //     datemonth = 1;
      //     break;
      //     case "March":
      //     datemonth = 2;
      //     break;
      //     case "April":
      //     datemonth = 3;
      //     break;
      //     case "May":
      //     datemonth = 4;
      //     break;
      //     case "June":
      //     datemonth = 5;
      //     break;
      //     case "July":
      //     datemonth = 6;
      //     break;
      //     case "August":
      //     datemonth = 7;
      //     break;
      //     case "September":
      //     datemonth = 8;
      //     break;
      //     case "October":
      //     datemonth = 9;
      //     break;
      //     case "November":
      //     datemonth = 10;
      //     break;
      //     case "December":
      //     datemonth = 11;
      //   }
      //   const newHourFinish = +dataArrayDragExpos[i].timeorderfinish.toString().slice(0,-2);
      //   const newMinutesFinish = +dataArrayDragExpos[i].timeorderfinish.toString().slice(-2);
      //   const newDateFinish = new Date(+dataArrayDragExpos[i].finish_year, datemonth, +dataArrayDragExpos[i].finish_date, newHourFinish, newMinutesFinish);
      //   dataArrayDragExpos[i]['daterealfinish'] = newDateFinish;
      // }

      // **2. Add `daterealfinish` to Each Drag Expo**
      const dragExposWithFinishDate = dataArrayDragExpos.map((drag: any) => {
        const datemonth = monthMap[drag.finish_month];
        const newHourFinish = Math.floor(drag.timeorderfinish / 100);
        const newMinutesFinish = drag.timeorderfinish % 100;
        const daterealfinish = new Date(
          +drag.finish_year,
          datemonth,
          +drag.finish_date,
          newHourFinish,
          newMinutesFinish
        );
        return { ...drag, daterealfinish };
      });
   
        // dataArrayDragExpos = dataArrayDragExpos.filter((ele: any) => ele.daterealfinish > currentDateAndTime);


        // **3. Filter Upcoming Drag Expos**
        const upcomingDragExpos = dragExposWithFinishDate.filter(
          (drag: any) => drag.daterealfinish > currentDateAndTime
        );


      // for (let i = 0; i < dataArrayDragExpos.length; i++) {
      //   let datemonth: any;
      //   switch(dataArrayDragExpos[i].start_month) {
      //     case "January":
      //     datemonth = 0;
      //     break;
      //     case "February":
      //     datemonth = 1;
      //     break;
      //     case "March":
      //     datemonth = 2;
      //     break;
      //     case "April":
      //     datemonth = 3;
      //     break;
      //     case "May":
      //     datemonth = 4;
      //     break;
      //     case "June":
      //     datemonth = 5;
      //     break;
      //     case "July":
      //     datemonth = 6;
      //     break;
      //     case "August":
      //     datemonth = 7;
      //     break;
      //     case "September":
      //     datemonth = 8;
      //     break;
      //     case "October":
      //     datemonth = 9;
      //     break;
      //     case "November":
      //     datemonth = 10;
      //     break;
      //     case "December":
      //     datemonth = 11;
      //   }
      //   const newHour = +dataArrayDragExpos[i].timeorder.toString().slice(0,-2);
      //   const newMinutes = +dataArrayDragExpos[i].timeorder.toString().slice(-2);
      //   const newDate = new Date(+dataArrayDragExpos[i].start_year, datemonth, +dataArrayDragExpos[i].start_date, newHour, newMinutes);
      //   dataArrayDragExpos[i]['daterealstart'] = newDate;
      // }

      // **4. Add `daterealstart` to Each Drag Expo**
      const dragExposWithStartDate = upcomingDragExpos.map((drag: any) => {
        const datemonth = monthMap[drag.start_month];
        const newHourStart = Math.floor(drag.timeorder / 100);
        const newMinutesStart = drag.timeorder % 100;
        const daterealstart = new Date(
          +drag.start_year,
          datemonth,
          +drag.start_date,
          newHourStart,
          newMinutesStart
        );
        return { ...drag, daterealstart };
      });

      // const compare = (a:any,b:any) => {
      //   if (a.daterealstart.getTime() < b.daterealstart.getTime() || (a.start_time == b.start_time && a.daterealfinish.getTime() < b.daterealfinish.getTime()))
      //     return -1;
      //   if (a.daterealstart.getTime() > b.daterealstart.getTime() || (a.start_time == b.start_time && a.daterealfinish.getTime() > b.daterealfinish.getTime()))
      //     return 1;
      //   return 0;
      // }

      // dataArrayDragExpos.sort(compare);

      // **5. Sort Drag Expos**
      const sortedDragExpos = [...dragExposWithStartDate].sort((a: any, b: any) => {
        if (a.daterealstart.getTime() !== b.daterealstart.getTime()) {
          return a.daterealstart.getTime() - b.daterealstart.getTime();
        }
        return a.daterealfinish.getTime() - b.daterealfinish.getTime();
      });

      // for (let i = 0; i < dataArrayDragExpos.length; i++) {
      //     const newImageSrc = storedImages.filter((ele:any) => ele.name === dataArrayDragExpos[i].image_name)[0]?.useableFilePath;
      //     dataArrayDragExpos[i].image_src = newImageSrc;
      // }

      // **6. Add `image_src` to Each Drag Expo**
      const imageMap = new Map<string, any>();
      storedImages.forEach((image: any) => {
        imageMap.set(image.name, image.useableFilePath);
      });

      const dragExposWithImages = sortedDragExpos.map((drag: any) => {
        const image_src = imageMap.get(drag.image_name) ?? defaultImage;
        return { ...drag, image_src };
      });


      // for (let i = 0; i < dataArrayDragExpos.length; i++) {
      //   let dateString;
      //   if ((dataArrayDragExpos[i].start_date === dataArrayDragExpos[i].finish_date) && (dataArrayDragExpos[i].start_month === dataArrayDragExpos[i].finish_month)) {
      //     dateString = dataArrayDragExpos[i].start_date + " " + dataArrayDragExpos[i].start_month;
      //   } else if ((dataArrayDragExpos[i].start_date !== dataArrayDragExpos[i].finish_date) && (dataArrayDragExpos[i].start_month === dataArrayDragExpos[i].finish_month)) {
      //     dateString = dataArrayDragExpos[i].start_date + " - " + dataArrayDragExpos[i].finish_date + " " + dataArrayDragExpos[i].start_month;
      //   } else {
      //     dateString = dataArrayDragExpos[i].start_date + " " + dataArrayDragExpos[i].start_month.slice(0,3) + " - " + dataArrayDragExpos[i].finish_date + " " + dataArrayDragExpos[i].finish_month.slice(0,3);
      //   }
      //   dataArrayDragExpos[i]['datestring'] = dateString;
      // }

      // **7. Add `datestring` to Each Drag Expo**
      const dragExposWithDateString = dragExposWithImages.map((drag: any) => {
        if (drag.datestring) return drag; // If datestring already exists, skip
        console.log('attention. at least one drag event without a datestring.');
        let dateString;
        if (drag.start_date === drag.finish_date && drag.start_month === drag.finish_month) {
          dateString = `${drag.start_date} ${drag.start_month}`;
        } else if (drag.start_month === drag.finish_month) {
          dateString = `${drag.start_date} - ${drag.finish_date} ${drag.start_month}`;
        } else {
          dateString = `${drag.start_date} ${drag.start_month.slice(
            0,
            3
          )} - ${drag.finish_date} ${drag.finish_month.slice(0, 3)}`;
        }
        return { ...drag, datestring: dateString };
      });

      // const dataArrayDragTours:any = [];
      // for (let i = 0; i < dataArrayDragExpos.length; i++) {
      //   if (dataArrayDragExpos[i].type === 'Tour') {
      //     dataArrayDragTours.push(dataArrayDragExpos[i])
      //   }
      // }

      // **8. Filter Drag Tours**
      const dataArrayDragTours = dragExposWithDateString.filter(
        (drag: any) => drag.type === 'Tour'
      );

      // **9. Update State**
      setDragExpos(dragExposWithDateString);
      setDragTours(dataArrayDragTours);
      setDragDataIsLoaded(true);
    

}, [storeIsLoaded, imagesAreLoaded, citySelected, currentDateAndTime, rawDrag, storedImages])


useEffect(() => {
  
  if (!storeIsLoaded || !imagesAreLoaded || !citySelected || !rawVenues.length) {
    return;
  }
 
      // const dataArrayBC: any = [];
      // for (let i = 0; i < rawVenues.length; i++) {
      //   if (
      //     (rawVenues[i].city === citySelected.city)
      //     &&
      //     (rawVenues[i].type === "barorclub")
      //     &&
      //     (rawVenues[i].display)
      //     ) {
      //     dataArrayBC.push(rawVenues[i]);
      //   }
      // }

      // **1. Create an Image Map for Quick Lookup**
        const imageMap = new Map<string, any>();
        storedImages.forEach((image: any) => {
          imageMap.set(image.name, image.useableFilePath);
        });

      // for (let i = 0; i < dataArrayBC.length; i++) {
      //   const tagsArray = [];
      //   if (dataArrayBC[i].monday_open !== "") {
      //     tagsArray.push('Monday');
      //   }
      //   if (dataArrayBC[i].tuesday_open !== "") {
      //     tagsArray.push('Tuesday');
      //   }
      //   if (dataArrayBC[i].wednesday_open !== "") {
      //     tagsArray.push('Wednesday');
      //   }
      //   if (dataArrayBC[i].thursday_open !== "") {
      //     tagsArray.push('Thursday');
      //   }
      //   if (dataArrayBC[i].friday_open !== "") {
      //     tagsArray.push('Friday');
      //   }
      //   if (dataArrayBC[i].saturday_open !== "") {
      //     tagsArray.push('Saturday');
      //   }
      //   if (dataArrayBC[i].sunday_open !== "") {
      //     tagsArray.push('Sunday');
      //   }
      //   if (dataArrayBC[i].rooftop_or_balcony) {
      //     tagsArray.push('Rooftop / balcony');
      //   }
      //   if (dataArrayBC[i].terrace_or_beergarden) {
      //     tagsArray.push('Terrace / beer garden');
      //   }
      //   if (dataArrayBC[i].drag_shows) {
      //     tagsArray.push('Drag shows');
      //   }
      //   if (dataArrayBC[i].late) {
      //     tagsArray.push('Late night');
      //   }
      //   if (dataArrayBC[i].lesbian) {
      //     tagsArray.push('Women');
      //   }
      //   if (dataArrayBC[i].gogo_dancers) {
      //     tagsArray.push('Gogo dancers');
      //   }
      //   dataArrayBC[i]['tags'] = tagsArray;
      // }

     // **2. Define a Function to Generate Tags for a Venue**
      const getTagsForVenue = (venue: any) => {
        // If tags_array already exists, return it
        if (venue.tags_array) {
          return venue.tags_array;
        }
        console.log('attention. at least one venue without a tags array.');
        // Otherwise, generate the tags
        const tagsArray = [];
        if (venue.monday_open !== "") tagsArray.push('Monday');
        if (venue.tuesday_open !== "") tagsArray.push('Tuesday');
        if (venue.wednesday_open !== "") tagsArray.push('Wednesday');
        if (venue.thursday_open !== "") tagsArray.push('Thursday');
        if (venue.friday_open !== "") tagsArray.push('Friday');
        if (venue.saturday_open !== "") tagsArray.push('Saturday');
        if (venue.sunday_open !== "") tagsArray.push('Sunday');
        if (venue.rooftop_or_balcony) tagsArray.push('Rooftop / balcony');
        if (venue.terrace_or_beergarden) tagsArray.push('Terrace / beer garden');
        if (venue.drag_shows) tagsArray.push('Drag shows');
        if (venue.late) tagsArray.push('Late night');
        if (venue.lesbian) tagsArray.push('Women');
        if (venue.gogo_dancers) tagsArray.push('Gogo dancers');
        return tagsArray;
      };

      // for (let i = 0; i < dataArrayBC.length; i++) {
      //     const newImageSrc = storedImages.filter((ele:any) => ele.name === dataArrayBC[i].image_name)[0]?.useableFilePath;
      //     dataArrayBC[i].image_src = newImageSrc;
      // }

      // setBarsAndClubs(dataArrayBC);

       // **3. Process Bars and Clubs**
      const dataArrayBC = rawVenues
      .filter(
        (venue:any) =>
          venue.city === citySelected.city &&
          venue.type === 'barorclub' &&
          venue.display
      )
      .map((venue:any) => {
        const tags_array = getTagsForVenue(venue);
        const image_src = imageMap.get(venue.image_name) ?? defaultImage;
        return { ...venue, tags_array, image_src };
      });

    // **4. Sort Bars and Clubs Alphabetically**
      const sortedBarsAndClubs = [...dataArrayBC].sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      setBarsAndClubs(sortedBarsAndClubs);

      // const dataArraySau: any = [];
      // for (let i = 0; i < rawVenues.length; i++) {
      //   if (
      //     (rawVenues[i].city === citySelected.city)
      //     &&
      //     (rawVenues[i].type === "sauna")
      //     &&
      //     (rawVenues[i].display)
      //     ) {
      //       dataArraySau.push(rawVenues[i]);
      //   }
      // }

      // for (let i = 0; i < dataArraySau.length; i++) {
      //     const newImageSrc = storedImages.filter((ele:any) => ele.name === dataArraySau[i].image_name)[0]?.useableFilePath;
      //     dataArraySau[i].image_src = newImageSrc;
      // }

      // setSaunas(dataArraySau);

      // **5. Process Saunas**
        const dataArraySau = rawVenues
        .filter(
          (venue:any) =>
            venue.city === citySelected.city &&
            venue.type === 'sauna' &&
            venue.display
        )
        .map((venue:any) => {
          const image_src = imageMap.get(venue.image_name) ?? defaultImage;
          return { ...venue, image_src };
        });

      // **6. Sort Saunas Alphabetically**
      const sortedSaunas = [...dataArraySau].sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      setSaunas(sortedSaunas);

      // const allVenues = dataArrayBC.concat(dataArraySau);

      // setVenues(allVenues);
      // setVenueDataIsLoaded(true);

     // **7. Combine All Venues and Sort Alphabetically**
      const allVenues = [...sortedBarsAndClubs, ...sortedSaunas];
      const sortedVenues = [...allVenues].sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      setVenues(sortedVenues);
      setVenueDataIsLoaded(true);

  }, [
  storeIsLoaded,
  imagesAreLoaded,
  citySelected,
  rawVenues,
  storedImages
]);



useEffect(() => {
  
  if (!storeIsLoaded || !imagesAreLoaded || !citySelected
    || !parties
    || !dragExpos
    || !rawPeople.length
    || !rawTags.length
  ) {
    return;
  }
  
  // const partiesWithEnt = parties.filter((party: any) => party.entertainers !== "");
  //   const dragWithEnt = dragExpos.filter((drag: any) => drag.entertainers !== "");

  // **1. Create an Image Map for Quick Lookup**
  const imageMap = new Map<string, any>();
  storedImages.forEach((image: any) => {
    imageMap.set(image.name, image.useableFilePath);
  });

  // **2. Filter Parties and Drag Expos with Entertainers**
  const partiesWithEnt = parties.filter(
    (party: any) => party.entertainers && party.entertainers.length > 0
  );
  const dragWithEnt = dragExpos.filter(
    (drag: any) => drag.entertainers && drag.entertainers.length > 0
  );

      // const dataArrayPeople: any = [];
      // for (let i = 0; i < rawPeople.length; i++) {
      //   if (
      //     (rawPeople[i].display)
      //     &&
      //     (
      //     partiesWithEnt.filter((party:any) => party.entertainers.some((entertainer:any) => entertainer === rawPeople[i].name)).length > 0
      //     ||
      //     dragWithEnt.filter((drag:any) => drag.entertainers.some((entertainer:any) => entertainer === rawPeople[i].name)).length > 0
      //     ||
      //     rawPeople[i].force === citySelected.city
      //     )
      //     ) {
      //     dataArrayPeople.push(rawPeople[i]);
      //   }
      // }

      // **3. Build dataArrayPeople**
      const dataArrayPeople = rawPeople.filter((person: any) => {
        const inParty = partiesWithEnt.some((party: any) =>
          party.entertainers.includes(person.name)
        );
        const inDrag = dragWithEnt.some((drag: any) =>
          drag.entertainers.includes(person.name)
        );
        const forced = person.force === citySelected.city;
        return person.display && (inParty || inDrag || forced);
      });

      // for (let i = 0; i < dataArrayPeople.length; i++) {
      //     if (dataArrayPeople[i].tv_shows === "") {
      //       dataArrayPeople[i].tv_shows = [];
      //     }
      // }

     // **4. Ensure tv_shows is an Array**
      const dataArrayPeopleWithTvShows = dataArrayPeople.map((person: any) => {
        const tv_shows = person.tv_shows === '' ? [] : person.tv_shows;
        return { ...person, tv_shows };
      });


  
      // for (let i = 0; i < dataArrayPeople.length; i++) {
      //   const tagsArray = [];
      //   if (dataArrayPeople[i].type.includes("Drag")) {
      //     tagsArray.push('Drag');
      //   }
      //   if (dataArrayPeople[i].type.includes("DJ")) {
      //     tagsArray.push('DJ');
      //   }
      //   if (dataArrayPeople[i].type.includes("Singer")) {
      //     tagsArray.push('Singer');
      //   }
      //   if (dataArrayPeople[i].type.includes("Other")) {
      //     tagsArray.push('Other');
      //   }
      //   if (dataArrayPeople[i].tv_shows.some((show:any) => (show === "RuPaul's Drag Race - Season 1") || (show === "RuPaul's Drag Race - Season 2") || (show === "RuPaul's Drag Race - Season 3"))) {
      //     tagsArray.push('S1-3');
      //   }
      //   if (dataArrayPeople[i].tv_shows.some((show:any) => (show === "RuPaul's Drag Race - Season 4") || (show === "RuPaul's Drag Race - Season 5") || (show === "RuPaul's Drag Race - Season 6"))) {
      //     tagsArray.push('S4-6');
      //   }
      //   if (dataArrayPeople[i].tv_shows.some((show:any) => (show === "RuPaul's Drag Race - Season 7") || (show === "RuPaul's Drag Race - Season 8") || (show === "RuPaul's Drag Race - Season 9"))) {
      //     tagsArray.push('S7-9');
      //   }
      //   if (dataArrayPeople[i].tv_shows.some((show:any) => (show === "RuPaul's Drag Race - Season 10") || (show === "RuPaul's Drag Race - Season 11") || (show === "RuPaul's Drag Race - Season 12"))) {
      //     tagsArray.push('S10-12');
      //   }
      //   if (dataArrayPeople[i].tv_shows.some((show:any) => (show === "RuPaul's Drag Race - Season 13") || (show === "RuPaul's Drag Race - Season 14") || (show === "RuPaul's Drag Race - Season 15") || (show === "RuPaul's Drag Race - Season 16"))) {
      //     tagsArray.push('S13-16');
      //   }
      //   if (dataArrayPeople[i].tv_shows.some((show:any) => (show.includes("RuPaul's Drag Race Down Under")))) {
      //     tagsArray.push('Australia');
      //   }
      //   if (dataArrayPeople[i].tv_shows.some((show:any) => (show.includes("Drag Race Belgique")))) {
      //     tagsArray.push('Belgium');
      //   }
      //   if (dataArrayPeople[i].tv_shows.some((show:any) => (show.includes("Drag Race Brasil")))) {
      //     tagsArray.push('Brazil');
      //   }
      //   if (dataArrayPeople[i].tv_shows.some((show:any) => (show.includes("Canada's Drag Race")))) {
      //     tagsArray.push('Canada');
      //   }
      //   if (dataArrayPeople[i].tv_shows.some((show:any) => (show.includes("Drag Race France")))) {
      //     tagsArray.push('France');
      //   }
      //   if (dataArrayPeople[i].tv_shows.some((show:any) => (show.includes("Drag Race Germany")))) {
      //     tagsArray.push('Germany');
      //   }
      //   if (dataArrayPeople[i].tv_shows.some((show:any) => (show.includes("Drag Race Holland")))) {
      //     tagsArray.push('Holland');
      //   }
      //   if (dataArrayPeople[i].tv_shows.some((show:any) => (show.includes("Drag Race Italia")))) {
      //     tagsArray.push('Italy');
      //   }
      //   if (dataArrayPeople[i].tv_shows.some((show:any) => (show.includes("Drag Race Philippines")))) {
      //     tagsArray.push('Philippines');
      //   }
      //   if (dataArrayPeople[i].tv_shows.some((show:any) => (show.includes("Drag Race España")))) {
      //     tagsArray.push('Spain');
      //   }
      //   if (dataArrayPeople[i].tv_shows.some((show:any) => (show.includes("Drag Race Sverige")))) {
      //     tagsArray.push('Sweden');
      //   }
      //   if (dataArrayPeople[i].tv_shows.some((show:any) => (show.includes("Drag Race Thailand")))) {
      //     tagsArray.push('Thailand');
      //   }
      //   if (dataArrayPeople[i].tv_shows.some((show:any) => (show.includes("RuPaul's Drag Race UK")))) {
      //     tagsArray.push('UK');
      //   }
      //   if (dataArrayPeople[i].tv_shows.some((show:any) => (show.includes("Drag Race México")))) {
      //     tagsArray.push('Mexico');
      //   }
      //   dataArrayPeople[i]['tags'] = tagsArray;
      // }

      // **5. Generate Tags for Each Person or Use Existing tags_array**
      const getTagsForPerson = (person: any) => {
        // If tags_array already exists, return it
        if (person.tags_array) {
          return person.tags_array;
        }
        console.log('attention. at least one person without a tags array.', person);
        // Otherwise, generate tags
        const tagsArray = [];

        if (person.type.includes('Drag')) tagsArray.push('Drag');
        if (person.type.includes('DJ')) tagsArray.push('DJ');
        if (person.type.includes('Singer')) tagsArray.push('Singer');
        if (person.type.includes('Other')) tagsArray.push('Other');

        const tvShows = person.tv_shows;

        if (
          tvShows.some((show: any) =>
            [
              "RuPaul's Drag Race - Season 1",
              "RuPaul's Drag Race - Season 2",
              "RuPaul's Drag Race - Season 3",
            ].includes(show)
          )
        )
          tagsArray.push('S1-3');
        if (
          tvShows.some((show: any) =>
            [
              "RuPaul's Drag Race - Season 4",
              "RuPaul's Drag Race - Season 5",
              "RuPaul's Drag Race - Season 6",
            ].includes(show)
          )
        )
          tagsArray.push('S4-6');
        if (
          tvShows.some((show: any) =>
            [
              "RuPaul's Drag Race - Season 7",
              "RuPaul's Drag Race - Season 8",
              "RuPaul's Drag Race - Season 9",
            ].includes(show)
          )
        )
          tagsArray.push('S7-9');
        if (
          tvShows.some((show: any) =>
            [
              "RuPaul's Drag Race - Season 10",
              "RuPaul's Drag Race - Season 11",
              "RuPaul's Drag Race - Season 12",
            ].includes(show)
          )
        )
          tagsArray.push('S10-12');
        if (
          tvShows.some((show: any) =>
            [
              "RuPaul's Drag Race - Season 13",
              "RuPaul's Drag Race - Season 14",
              "RuPaul's Drag Race - Season 15",
              "RuPaul's Drag Race - Season 16",
            ].includes(show)
          )
        )
          tagsArray.push('S13-16');
        if (tvShows.some((show: any) => show.includes("RuPaul's Drag Race Down Under")))
          tagsArray.push('Australia');
        if (tvShows.some((show: any) => show.includes('Drag Race Belgique')))
          tagsArray.push('Belgium');
        if (tvShows.some((show: any) => show.includes('Drag Race Brasil')))
          tagsArray.push('Brazil');
        if (tvShows.some((show: any) => show.includes("Canada's Drag Race")))
          tagsArray.push('Canada');
        if (tvShows.some((show: any) => show.includes('Drag Race France')))
          tagsArray.push('France');
        if (tvShows.some((show: any) => show.includes('Drag Race Germany')))
          tagsArray.push('Germany');
        if (tvShows.some((show: any) => show.includes('Drag Race Holland')))
          tagsArray.push('Holland');
        if (tvShows.some((show: any) => show.includes('Drag Race Italia')))
          tagsArray.push('Italy');
        if (tvShows.some((show: any) => show.includes('Drag Race Philippines')))
          tagsArray.push('Philippines');
        if (tvShows.some((show: any) => show.includes('Drag Race España')))
          tagsArray.push('Spain');
        if (tvShows.some((show: any) => show.includes('Drag Race Sverige')))
          tagsArray.push('Sweden');
        if (tvShows.some((show: any) => show.includes('Drag Race Thailand')))
          tagsArray.push('Thailand');
        if (tvShows.some((show: any) => show.includes("RuPaul's Drag Race UK")))
          tagsArray.push('UK');
        if (tvShows.some((show: any) => show.includes('Drag Race México')))
          tagsArray.push('Mexico');

        return tagsArray;
      };

      const dataArrayPeopleWithTags = dataArrayPeopleWithTvShows.map((person: any) => {
        const tags_array = getTagsForPerson(person);
        return { ...person, tags_array };
      });

      // dataArrayPeople.sort((a: any, b: any):any => {
      //   if (a.name < b.name) {
      //     return -1;
      //   }
      //   if (a.name > b.name) {
      //     return 1;
      //   }
      //   return 0;
      // });

      // **6. Sort People by Name**
      const sortedDataArrayPeople = [...dataArrayPeopleWithTags].sort(
        (a: any, b: any): number => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        }
      );


      // for (let i = 0; i < dataArrayPeople.length; i++) {
      //     const newImageSrc = storedImages.filter((ele:any) => ele.name === dataArrayPeople[i].image_name)[0]?.useableFilePath;
      //     dataArrayPeople[i].image_src = newImageSrc;
      // }

      // **7. Add image_src to Each Person**
      const dataArrayPeopleWithImages = sortedDataArrayPeople.map((person: any) => {
        const image_src = imageMap.get(person.image_name) ?? defaultImage;
        return { ...person, image_src };
      });
  
      // setPeople(dataArrayPeople);

      // **8. Update State with Processed People Data**
      setPeople(dataArrayPeopleWithImages);

      // const otherPres = [];
      // for (let i = 0; i < dataArrayPeople.length; i++) {
      //     otherPres.push(dataArrayPeople[i])
      // }

      // otherPres.sort((a: any, b: any):any => {
      //   if (a.type[0] < b.type[0]) {
      //     return 1;
      //   }
      //   if (a.type[0] > b.type[0]) {
      //     return -1;
      //   }
      //   return 0;
      // });

      // **9. Create otherPres Sorted by Type**
      const otherPres = [...dataArrayPeopleWithImages].sort(
        (a: any, b: any): number => {
          if (a.type[0] < b.type[0]) return 1;
          if (a.type[0] > b.type[0]) return -1;
          return 0;
        }
      );
      setPeopleOth(otherPres);

      // setPeopleOth(otherPres);

      // const dataArrayTvShows: any = [];
      // for (let i = 0; i < dataArrayPeople.length; i++) {
      //   if (dataArrayPeople[i].tv_shows !== "") {
      //     for (let j = 0; j < dataArrayPeople[i].tv_shows.length; j++) {
      //       if (
      //         dataArrayTvShows.indexOf(dataArrayPeople[i].tv_shows[j]) === -1
      //       ) {
      //         dataArrayTvShows.push(dataArrayPeople[i].tv_shows[j]);
      //       }
      //     }
      //   }
      // }
      // setTvShows(dataArrayTvShows);

      // **10. Compile Unique TV Shows**
      // const tvShowSet = new Set<string>();
      // dataArrayPeopleWithImages.forEach((person: any) => {
      //   if (person.tv_shows && person.tv_shows.length > 0) {
      //     person.tv_shows.forEach((show: string) => {
      //       tvShowSet.add(show);
      //     });
      //   }
      // });
      // const dataArrayTvShows = Array.from(tvShowSet);
      // setTvShows(dataArrayTvShows);
    
      // setPeopleDataIsLoaded(true);

      // **11. Indicate Data Loading is Complete**
      setPeopleDataIsLoaded(true);
    
  }, [
   
  storeIsLoaded,
  imagesAreLoaded,
  citySelected,
  parties,
  dragExpos,
 rawPeople,
//  rawTags,
 storedImages
]);



useEffect(() => {
  
  if (!storeIsLoaded || !imagesAreLoaded || !hawaiiTime || !citySelected || !rawCruises.length) {
    return;
  }

  //1 was create month map which is now defined outside component

  // **2. Create an Image Map for Quick Lookup**
  const imageMap = new Map();
  storedImages.forEach((image) => {
    imageMap.set(image.name, image.useableFilePath);
  });


                  // let dataArrayCruises: any = [];
                  // for (let i = 0; i < rawCruises.length; i++) {
                  //   if (
                  //     (rawCruises[i].display)
                  //   ) {
                  //     dataArrayCruises.push(rawCruises[i]);
                  //   }
                  // }

  // **3. Filter Cruises that are Displayed**
  const dataArrayCruises = rawCruises.filter((cruise:any) => cruise.display);

                //  for (let i = 0; i < dataArrayCruises.length; i++) {
                //   let datemonth: any;
                //   switch(dataArrayCruises[i].start_month) {
                //     case "January":
                //     datemonth = 0;
                //     break;
                //     case "February":
                //     datemonth = 1;
                //     break;
                //     case "March":
                //     datemonth = 2;
                //     break;
                //     case "April":
                //     datemonth = 3;
                //     break;
                //     case "May":
                //     datemonth = 4;
                //     break;
                //     case "June":
                //     datemonth = 5;
                //     break;
                //     case "July":
                //     datemonth = 6;
                //     break;
                //     case "August":
                //     datemonth = 7;
                //     break;
                //     case "September":
                //     datemonth = 8;
                //     break;
                //     case "October":
                //     datemonth = 9;
                //     break;
                //     case "November":
                //     datemonth = 10;
                //     break;
                //     case "December":
                //     datemonth = 11;
                //   }

                //   const newHour = +dataArrayCruises[i].timeorder.toString().slice(0,-2);
                //   const newMinutes = +dataArrayCruises[i].timeorder.toString().slice(-2);
                //   const newDate = new Date(+dataArrayCruises[i].start_year, datemonth, +dataArrayCruises[i].start_date, newHour, newMinutes);
                //   dataArrayCruises[i]['daterealstart'] = newDate;
                // }

                // dataArrayCruises = dataArrayCruises.filter((ele: any) => ele.daterealstart > hawaiiTime);



                // **4. Add `daterealstart` to Each Cruise**
                const cruisesWithStartDate = dataArrayCruises.map((cruise:any) => {
                  const monthIndex = monthMap[cruise.start_month];
                  const timeString = cruise.timeorder.toString().padStart(4, '0');
                  const hours = parseInt(timeString.slice(0, -2), 10);
                  const minutes = parseInt(timeString.slice(-2), 10);
                  const daterealstart = new Date(
                    parseInt(cruise.start_year, 10),
                    monthIndex,
                    parseInt(cruise.start_date, 10),
                    hours,
                    minutes
                  );
                  return { ...cruise, daterealstart };
                });

                // **5. Filter Cruises Starting After `hawaiiTime`**
                const upcomingCruises = cruisesWithStartDate.filter(
                  (cruise:any) => cruise.daterealstart > hawaiiTime
                );


                //  for (let i = 0; i < dataArrayCruises.length; i++) {
                //   let datemonth: any;
                //   switch(dataArrayCruises[i].finish_month) {
                //     case "January":
                //     datemonth = 0;
                //     break;
                //     case "February":
                //     datemonth = 1;
                //     break;
                //     case "March":
                //     datemonth = 2;
                //     break;
                //     case "April":
                //     datemonth = 3;
                //     break;
                //     case "May":
                //     datemonth = 4;
                //     break;
                //     case "June":
                //     datemonth = 5;
                //     break;
                //     case "July":
                //     datemonth = 6;
                //     break;
                //     case "August":
                //     datemonth = 7;
                //     break;
                //     case "September":
                //     datemonth = 8;
                //     break;
                //     case "October":
                //     datemonth = 9;
                //     break;
                //     case "November":
                //     datemonth = 10;
                //     break;
                //     case "December":
                //     datemonth = 11;
                //   }

                //   const newHour = +dataArrayCruises[i].timeorderfinish.toString().slice(0,-2);
                //   const newMinutes = +dataArrayCruises[i].timeorderfinish.toString().slice(-2);
                //   const newDate = new Date(+dataArrayCruises[i].start_year, datemonth, +dataArrayCruises[i].start_date, newHour, newMinutes);  
                //   dataArrayCruises[i]['daterealfinish'] = newDate;
                // }

                // **6. Add `daterealfinish` to Each Cruise**
                const cruisesWithFinishDate = upcomingCruises.map((cruise:any) => {
                  const monthIndex = monthMap[cruise.finish_month];
                  const timeString = cruise.timeorderfinish.toString().padStart(4, '0');
                  const hours = parseInt(timeString.slice(0, -2), 10);
                  const minutes = parseInt(timeString.slice(-2), 10);
                  const daterealfinish = new Date(
                    parseInt(cruise.finish_year, 10),
                    monthIndex,
                    parseInt(cruise.finish_date, 10),
                    hours,
                    minutes
                  );
                  return { ...cruise, daterealfinish };
                });

                // for (let i = 0; i < dataArrayCruises.length; i++) {
                //   const tagsArray = [];
                //   if (dataArrayCruises[i].tickets_available) {
                //     tagsArray.push('Tickets left');
                //   }
                //   if (dataArrayCruises[i].open_bar) {
                //     tagsArray.push('Open bar');
                //   }
                //   if (dataArrayCruises[i].pool) {
                //     tagsArray.push('Pool');
                //   }
                //   if (dataArrayCruises[i].lesbian) {
                //     tagsArray.push('Women');
                //   }
                //   for (let j = 0; j < dataArrayCruises[i].other_destinations.length; j++) {
                //     if (tagsArray.indexOf(dataArrayCruises[i].other_destinations[j][1]) === -1) {
                //       tagsArray.push(dataArrayCruises[i].other_destinations[j][1]);
                //     }
                //   }
                //   dataArrayCruises[i]['tags'] = tagsArray;
                // }

                // **7. Generate Tags for Each Cruise**
                const cruisesWithTags = cruisesWithFinishDate.map((cruise:any) => {
                  // Use existing tags_array if it exists
                  const tags_array = cruise.tags_array || [];

                  // Generate tags if tags_array doesn't exist
                  if (!cruise.tags_array) {
                    console.log('attention. at least one cruise without a tags array.');
                    if (cruise.tickets_available) tags_array.push('Tickets left');
                    if (cruise.open_bar) tags_array.push('Open bar');
                    if (cruise.pool) tags_array.push('Pool');
                    if (cruise.lesbian) tags_array.push('Women');

                    cruise.other_destinations.forEach((destination:any) => {
                      if (!tags_array.includes(destination[1])) {
                        tags_array.push(destination[1]);
                      }
                    });
                  }

                  return { ...cruise, tags_array };
                });
            
                // dataArrayCruises.sort((a:any, b:any):any => {
                //   a = a.daterealstart.getTime();
                //   b = b.daterealstart.getTime();
                //   return a - b
                // });

                // **8. Sort Cruises by `daterealstart`**
                const sortedCruises = [...cruisesWithTags].sort(
                  (a, b) => a.daterealstart - b.daterealstart
                );

              // for (let i = 0; i < dataArrayCruises.length; i++) {
              //     const newImageSrc = storedImages.filter((ele:any) => ele.name === dataArrayCruises[i].image_name)[0]?.useableFilePath;
              //     dataArrayCruises[i].image_src = newImageSrc;
              // }

              // **9. Add `image_src` to Each Cruise**
              const cruisesWithImages = sortedCruises.map((cruise) => {
                const image_src = imageMap.get(cruise.image_name) ?? defaultImage;
                return { ...cruise, image_src };
              });

              // for (let i = 0; i < dataArrayCruises.length; i++) {
              //   let dateString;
              //   if ((dataArrayCruises[i].start_date === dataArrayCruises[i].finish_date) && (dataArrayCruises[i].start_month === dataArrayCruises[i].finish_month)) {
              //     dateString = dataArrayCruises[i].start_date + " " + dataArrayCruises[i].start_month;
              //   } else if ((dataArrayCruises[i].start_date !== dataArrayCruises[i].finish_date) && (dataArrayCruises[i].start_month === dataArrayCruises[i].finish_month)) {
              //     dateString = dataArrayCruises[i].start_date + " - " + dataArrayCruises[i].finish_date + " " + dataArrayCruises[i].start_month.slice(0,3);
              //   } else {
              //     dateString = dataArrayCruises[i].start_date + " " + dataArrayCruises[i].start_month.slice(0,3) + " - " + dataArrayCruises[i].finish_date + " " + dataArrayCruises[i].finish_month.slice(0,3);
              //   }
              //   dataArrayCruises[i]['datestring'] = dateString;
              // }

              // **10. Add `datestring` to Each Cruise**
              const cruisesWithDateString = cruisesWithImages.map((cruise) => {
                if (cruise.datestring) return cruise; // If timestring already exists, skip
                console.log('attention. at least one cruise without a datestring.');
                let dateString;
                if (
                  cruise.start_date === cruise.finish_date &&
                  cruise.start_month === cruise.finish_month
                ) {
                  dateString = `${cruise.start_date} ${cruise.start_month}`;
                } else if (cruise.start_month === cruise.finish_month) {
                  dateString = `${cruise.start_date} - ${cruise.finish_date} ${cruise.start_month.slice(0, 3)}`;
                } else {
                  dateString = `${cruise.start_date} ${cruise.start_month.slice(0, 3)} - ${cruise.finish_date} ${cruise.finish_month.slice(0, 3)}`;
                }
                return { ...cruise, datestring: dateString };
              });

              // const arrayOfCountries:any = [];
              // for (let i = 0; i < dataArrayCruises.length; i++) {
              //   for (let j = 0; j < dataArrayCruises[i].other_destinations.length; j++) {
              //     if (arrayOfCountries.indexOf(dataArrayCruises[i].other_destinations[j][1]) === -1) {
              //       arrayOfCountries.push(dataArrayCruises[i].other_destinations[j][1])
              //     }
              //   }
              // }
              // setCruiseCountryList(arrayOfCountries);

              // **11. Compile List of Countries**
              const cruiseCountrySet = new Set();
              cruisesWithDateString.forEach((cruise) => {
                cruise.other_destinations.forEach((destination:any) => {
                  cruiseCountrySet.add(destination[1]);
                });
              });
              const cruiseCountryList = Array.from(cruiseCountrySet);
              setCruiseCountryList(cruiseCountryList);

      //         for (let i = 0; i < dataArrayCruises.length; i++) {

      //           const newGeoJSONObject:any = {
      //             "features": []
      //           }

      //           for (let j = 0; j < dataArrayCruises[i].other_destinations.length; j++) {
      //             const newObj = {
      //               "type": "Feature",
      //               "properties": {
      //                 "title": dataArrayCruises[i].name,
      //                 "description": j + 1
      //               },
      //               "geometry": {
      //                 "coordinates": [dataArrayCruises[i].other_destinations[j][3], dataArrayCruises[i].other_destinations[j][4]],
      //                 "type": "Point"
      //               }
      //             }
      //             newGeoJSONObject.features.push(newObj);
      //         }

      //         dataArrayCruises[i].geojson_data = newGeoJSONObject;

      //         }

      //         setCruises(dataArrayCruises);
      // setCruiseDataIsLoaded(true);

      // **12. Add `geojson_data` to Each Cruise**
          const cruisesWithGeoJSON = cruisesWithDateString.map((cruise) => {
            const geojson_data = {
              features: cruise.other_destinations.map((destination:any, index:number) => ({
                type: 'Feature',
                properties: {
                  title: cruise.name,
                  description: index + 1,
                },
                geometry: {
                  type: 'Point',
                  coordinates: [destination[3], destination[4]],
                },
              })),
            };
            return { ...cruise, geojson_data };
          });

          // **13. Update State**
          setCruises(cruisesWithGeoJSON);
          setCruiseDataIsLoaded(true);
   
  }, [
  storeIsLoaded,
  imagesAreLoaded,
  hawaiiTime,
  citySelected,
 rawCruises,
 storedImages
]);




useEffect(() => {
  
  if (!storeIsLoaded || !imagesAreLoaded || !hawaiiTime || !citySelected || !rawFestivals.length) {
    return;
  }

  // 1. was month mapping

  // **2. Create an Image Map for Quick Lookup**
  const imageMap = new Map();
  storedImages.forEach((image) => {
    imageMap.set(image.name, image.useableFilePath);
  });
  
      // let dataArrayFestivals: any = [];
      //   for (let i = 0; i < rawFestivals.length; i++) {
      //     if (
      //       (rawFestivals[i].display)
      //     ) {
      //       dataArrayFestivals.push(rawFestivals[i]);
      //     }
      //   }

      // **3. Filter Festivals that are Displayed**
    const displayedFestivals = rawFestivals.filter((festival:any) => festival.display);

        // for (let i = 0; i < dataArrayFestivals.length; i++) {
        //   let datemonth: any;
        //   switch(dataArrayFestivals[i].finish_month) {
        //     case "January":
        //     datemonth = 0;
        //     break;
        //     case "February":
        //     datemonth = 1;
        //     break;
        //     case "March":
        //     datemonth = 2;
        //     break;
        //     case "April":
        //     datemonth = 3;
        //     break;
        //     case "May":
        //     datemonth = 4;
        //     break;
        //     case "June":
        //     datemonth = 5;
        //     break;
        //     case "July":
        //     datemonth = 6;
        //     break;
        //     case "August":
        //     datemonth = 7;
        //     break;
        //     case "September":
        //     datemonth = 8;
        //     break;
        //     case "October":
        //     datemonth = 9;
        //     break;
        //     case "November":
        //     datemonth = 10;
        //     break;
        //     case "December":
        //     datemonth = 11;
        //   }
          
        //   const newDate = new Date(+dataArrayFestivals[i].finish_year, datemonth, +dataArrayFestivals[i].finish_date);
        //   dataArrayFestivals[i]['daterealfinish'] = newDate;
        // }


        // dataArrayFestivals = dataArrayFestivals.filter((ele: any) => ele.daterealfinish > hawaiiTime);

        // **4. Add `daterealfinish` to Each Festival**
        const festivalsWithFinishDate = displayedFestivals.map((festival:any) => {
          const monthIndex = monthMap[festival.finish_month];
          const daterealfinish = new Date(
            parseInt(festival.finish_year, 10),
            monthIndex,
            parseInt(festival.finish_date, 10)
          );
          return { ...festival, daterealfinish };
        });

        // **5. Filter Festivals Finishing After `hawaiiTime`**
        const upcomingFestivals = festivalsWithFinishDate.filter(
          (festival:any) => festival.daterealfinish > hawaiiTime
        );

        // for (let i = 0; i < dataArrayFestivals.length; i++) {
        //   let datemonth: any;
        //   switch(dataArrayFestivals[i].start_month) {
        //     case "January":
        //     datemonth = 0;
        //     break;
        //     case "February":
        //     datemonth = 1;
        //     break;
        //     case "March":
        //     datemonth = 2;
        //     break;
        //     case "April":
        //     datemonth = 3;
        //     break;
        //     case "May":
        //     datemonth = 4;
        //     break;
        //     case "June":
        //     datemonth = 5;
        //     break;
        //     case "July":
        //     datemonth = 6;
        //     break;
        //     case "August":
        //     datemonth = 7;
        //     break;
        //     case "September":
        //     datemonth = 8;
        //     break;
        //     case "October":
        //     datemonth = 9;
        //     break;
        //     case "November":
        //     datemonth = 10;
        //     break;
        //     case "December":
        //     datemonth = 11;
        //   }
        //   const newDate = new Date(+dataArrayFestivals[i].start_year, datemonth, +dataArrayFestivals[i].start_date);
        //   dataArrayFestivals[i]['daterealstart'] = newDate;
        // }

        // **6. Add `daterealstart` to Each Festival**
        const festivalsWithStartDate = upcomingFestivals.map((festival:any) => {
          const monthIndex = monthMap[festival.start_month];
          const daterealstart = new Date(
            parseInt(festival.start_year, 10),
            monthIndex,
            parseInt(festival.start_date, 10)
          );
          return { ...festival, daterealstart };
        });

        // for (let i = 0; i < dataArrayFestivals.length; i++) {
        //   let dateString;
        //   if ((dataArrayFestivals[i].start_date === dataArrayFestivals[i].finish_date) && (dataArrayFestivals[i].start_month === dataArrayFestivals[i].finish_month)) {
        //     dateString = dataArrayFestivals[i].start_date + " " + dataArrayFestivals[i].start_month;
        //   } else if ((dataArrayFestivals[i].start_date !== dataArrayFestivals[i].finish_date) && (dataArrayFestivals[i].start_month === dataArrayFestivals[i].finish_month)) {
        //     dateString = dataArrayFestivals[i].start_date + " - " + dataArrayFestivals[i].finish_date + " " + dataArrayFestivals[i].start_month;
        //   } else {
        //     dateString = dataArrayFestivals[i].start_date + " " + dataArrayFestivals[i].start_month.slice(0,3) + " - " + dataArrayFestivals[i].finish_date + " " + dataArrayFestivals[i].finish_month.slice(0,3);
        //   }
        //   dataArrayFestivals[i]['datestring'] = dateString;
        // }

        // **7. Add `datestring` to Each Festival**
        const festivalsWithDateString = festivalsWithStartDate.map((festival: any) => {
          let dateString = festival.datestring; // Check if datestring already exists
          
          // If datestring is not already set, generate it
          if (!dateString) {
            console.log('attention. at least one festival without a datestring.');
            if (
              festival.start_date === festival.finish_date &&
              festival.start_month === festival.finish_month
            ) {
              dateString = `${festival.start_date} ${festival.start_month}`;
            } else if (festival.start_month === festival.finish_month) {
              dateString = `${festival.start_date} - ${festival.finish_date} ${festival.start_month}`;
            } else {
              dateString = `${festival.start_date} ${festival.start_month.slice(
                0,
                3
              )} - ${festival.finish_date} ${festival.finish_month.slice(0, 3)}`;
            }
          }
        
          // Return the updated festival object, either with existing or newly generated datestring
          return { ...festival, datestring: dateString };
        });

        // for (let i = 0; i < dataArrayFestivals.length; i++) {
        //   const fest1 = dataArrayFestivals[i].name.toLowerCase();
        //   const fest2 = fest1.split(" ").join("");
        //   dataArrayFestivals[i]['festparam'] = fest2;
        // }

        // **8. Add `festparam` to Each Festival**
        const festivalsWithParam = festivalsWithDateString.map((festival: any) => {
          // Check if festparam already exists, otherwise generate a new one
          // const festparam = festival.festparam ?? festival.name.toLowerCase().split(' ').join('');
          if (!festival.festparam) console.log("attention. at least one festival without a festparam.")
          const festparam = festival.festparam || festival.name.toLowerCase().split(' ').join('');
          return { ...festival, festparam };
        });

        // for (let i = 0; i < dataArrayFestivals.length; i++) {
        //   if (dataArrayFestivals[i].type === "Circuit" || dataArrayFestivals[i].type === "Pride") {
        //   const newImageSrc = storedImages.filter((ele:any) => ele.name === dataArrayFestivals[i].image_name)[0]?.useableFilePath;
        //   dataArrayFestivals[i].image_src = newImageSrc;
        //   }
        // }

        // **9. Add `image_src` to Each Festival**
        const festivalsWithImages = festivalsWithParam.map((festival:any) => {
          let image_src = festival.image_src;
          if (festival.type === 'Circuit' || festival.type === 'Pride') {
            image_src = imageMap.get(festival.image_name) ?? defaultImage;
          }
          return { ...festival, image_src };
        });
        //alert check

        // for (let i = 0; i < dataArrayFestivals.length; i++) {
        //   const tagsArray = [];
        //   if (dataArrayFestivals[i].tickets_available) {
        //     tagsArray.push('Tickets left');
        //   }
        //     tagsArray.push(dataArrayFestivals[i].country);
        //   tagsArray.push(dataArrayFestivals[i].start_month);
        //   tagsArray.push(dataArrayFestivals[i].finish_month);
         
        //   dataArrayFestivals[i]['tags'] = tagsArray;
        // }

        // **10. Generate Tags for Each Festival**
        const festivalsWithTags = festivalsWithImages.map((festival:any) => {
          // Use existing tags_array if it exists
          const tags_array = festival.tags_array || [];

          // Generate tags if tags_array doesn't exist
          if (!festival.tags_array) {
            console.log('attention. at least one festival without a tags array.');
            if (festival.tickets_available) {
              tags_array.push('Tickets left');
            }
            tags_array.push(festival.country);
            tags_array.push(festival.start_month);
            tags_array.push(festival.finish_month);
          }

          return { ...festival, tags_array };
        });

        // dataArrayFestivals.sort((a:any, b:any):any => {
        //   a = a.daterealstart.getTime();
        //   b = b.daterealstart.getTime();
        //   return a - b
        // });

        // **11. Sort Festivals by `daterealstart`**
        const sortedFestivals = [...festivalsWithTags].sort(
          (a, b) => a.daterealstart - b.daterealstart
        );

        // setFestivals(dataArrayFestivals);
        setFestivals(sortedFestivals);


        // const dataArrayCirc = dataArrayFestivals.filter((fest:any) => fest.type === "Circuit");
        
        // setFestivalsCirc(dataArrayCirc);


        // **12. Separate and Set Circuit Festivals**
        const dataArrayCirc = sortedFestivals.filter(
          (festival) => festival.type === 'Circuit'
        );
        setFestivalsCirc(dataArrayCirc);


        // const arrayOfCountriesC:any = [];
        // for (let i = 0; i < dataArrayCirc.length; i++) {
          
        //     if (arrayOfCountriesC.indexOf(dataArrayCirc[i].country) === -1) {
        //       arrayOfCountriesC.push(dataArrayCirc[i].country)
        //     }
          
        // }
        // setCircCountryList(arrayOfCountriesC);

        // **13. Compile List of Countries for Circuit Festivals**
        const circCountrySet = new Set();
        dataArrayCirc.forEach((festival) => {
          circCountrySet.add(festival.country);
        });
        const arrayOfCountriesC = Array.from(circCountrySet);
        setCircCountryList(arrayOfCountriesC);

        // const dataArrayPr = dataArrayFestivals.filter((fest:any) => fest.type === "Pride");

        // setFestivalsPr(dataArrayPr);

        // **14. Separate and Set Pride Festivals**
        const dataArrayPr = sortedFestivals.filter(
          (festival) => festival.type === 'Pride'
        );
        setFestivalsPr(dataArrayPr);

        // const arrayOfCountriesPr:any = [];
        // for (let i = 0; i < dataArrayPr.length; i++) {
          
        //     if (arrayOfCountriesPr.indexOf(dataArrayPr[i].country) === -1) {
        //       arrayOfCountriesPr.push(dataArrayPr[i].country)
        //     }
          
        // }
        // setPrideCountryList(arrayOfCountriesPr);

        // **15. Compile List of Countries for Pride Festivals**
        const prideCountrySet = new Set();
        dataArrayPr.forEach((festival) => {
          prideCountrySet.add(festival.country);
        });
        const arrayOfCountriesPr = Array.from(prideCountrySet);
        setPrideCountryList(arrayOfCountriesPr);

        // setFestDataIsLoaded(true);
        // **16. Indicate Data Loading is Complete**
        setFestDataIsLoaded(true);
  }, [
  storeIsLoaded,
  imagesAreLoaded,
  hawaiiTime,
  citySelected,
 rawFestivals,
 storedImages
]);





useEffect(() => {
  if (!storeIsLoaded || !citySelected || !rawTags.length) {
    return;
  }
  
              const dataArrayTagsParties: any = [];

              for (let i = 0; i < rawTags.length; i++) {
                if (
                  (rawTags[i].display)
                  &&
                  (rawTags[i].category === "parties")
                ) {
                  dataArrayTagsParties.push(rawTags[i]);
                }
              }

              dataArrayTagsParties.sort((a:any, b:any):any => {
                a = a.order;
                b = b.order;
                return a - b
              });

              setTagsParties(dataArrayTagsParties);
              

              const dataArrayTagsBC: any = [];

              for (let i = 0; i < rawTags.length; i++) {
                if (
                  (rawTags[i].display)
                  &&
                  (rawTags[i].category === "barsandclubs")
                ) {
                  dataArrayTagsBC.push(rawTags[i]);
                }
              }

              setTagsBC(dataArrayTagsBC);


              const dataArrayTagsPeople: any = [];

              for (let i = 0; i < rawTags.length; i++) {
                if (
                  (rawTags[i].display)
                  &&
                  (rawTags[i].category === "people")
                ) {
                  dataArrayTagsPeople.push(rawTags[i]);
                }
              }
              
              dataArrayTagsPeople.sort((a: any, b: any):any => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              });

              dataArrayTagsPeople.sort((a:any, b:any):any => {
                a = a.order;
                b = b.order;
                return a - b
              });

              setTagsPeople(dataArrayTagsPeople);



              const dataArrayTagsCruises: any = [];

              for (let i = 0; i < rawTags.length; i++) {
                if (
                  (rawTags[i].display)
                  &&
                  (rawTags[i].category === "cruises")
                ) {
                  dataArrayTagsCruises.push(rawTags[i]);
                }
              }

              setTagsCruises(dataArrayTagsCruises);
        setTagsDataIsLoaded(true);
    
   
  }, [
  storeIsLoaded,
  citySelected,
  rawTags,
]);


useEffect(() => {
  if (!storeIsLoaded || !citySelected || !rawNotifications.length) {
    return;
  }
  
        const dataArrayNotifications: any = [];
      for (let i = 0; i < rawNotifications.length; i++) {
        if (
          (rawNotifications[i].display)
        ) {
          dataArrayNotifications.push(rawNotifications[i]);
        }
      }

      for (let i = 0; i < dataArrayNotifications.length; i++) {
        let datemonth: any;
        switch(dataArrayNotifications[i].month) {
          case "January":
          datemonth = 0;
          break;
          case "February":
          datemonth = 1;
          break;
          case "March":
          datemonth = 2;
          break;
          case "April":
          datemonth = 3;
          break;
          case "May":
          datemonth = 4;
          break;
          case "June":
          datemonth = 5;
          break;
          case "July":
          datemonth = 6;
          break;
          case "August":
          datemonth = 7;
          break;
          case "September":
          datemonth = 8;
          break;
          case "October":
          datemonth = 9;
          break;
          case "November":
          datemonth = 10;
          break;
          case "December":
          datemonth = 11;
        }
        const newHour = +dataArrayNotifications[i].timeorder.toString().slice(0,-2);
        const newMinutes = +dataArrayNotifications[i].timeorder.toString().slice(-2);
        const newDate = new Date(+dataArrayNotifications[i].year, datemonth, +dataArrayNotifications[i].date, newHour, newMinutes);
        dataArrayNotifications[i]['datereal'] = newDate;
      }

      dataArrayNotifications.sort((a:any, b:any):any => {
        a = a.datereal.getTime();
        b = b.datereal.getTime();
        return b - a
      });

      dataArrayNotifications.sort((a:any, b:any):any => {
        a = a.locked;
        b = b.locked;
        return b - a
      });

      setNotifications(dataArrayNotifications);
      setNotificationDataIsLoaded(true);
  }, [
  storeIsLoaded,
  citySelected,
  rawNotifications
]);

  useEffect(() => {
    if (!citySelected) {
      return;
    }
    const intervalId = setInterval(() => {
      handlePullRefresher(citySelected.timezone);
      return;
    }, 30000);
    return () => {
      clearInterval(intervalId);
    };
  }, [citySelected]);


  // useEffect(() => {
        //   console.log("what is changing 0", currentDateAndTime);

        //   console.log("what is changing 1", cityList);
        //   console.log("what is changing 2", cityListN);
        //   console.log("what is changing 3", citySelected);
        //   console.log("what is changing 4", storeIsLoaded);
        //   console.log("what is changing 5", imagesAreLoaded);
        //   console.log("what is changing 7", festDataIsLoaded);
        //   console.log("what is changing 8", partyDataIsLoaded);
        //   console.log("what is changing 9", dragDataIsLoaded);
        //   console.log("what is changing 10", venueDataIsLoaded);
        //   console.log("what is changing 11", cruiseDataIsLoaded);
        //   console.log("what is changing 12", peopleDataIsLoaded);
        //   console.log("what is changing 13", tagsDataIsLoaded);
        //   console.log("what is changing 14", notificationDataIsLoaded);
        //   console.log("what is changing 15", openCitySelector);
        //   console.log('what is changing 15.2',openCitySelector
        //   && 
        //   ((!imagesAreLoaded) || !cityListN));
        //   console.log('what is changing 15.4',openCitySelector && imagesAreLoaded && cityListN);
        //   console.log('what is changing 16',((!openCitySelector
        //     &&
        //     (!festDataIsLoaded || !partyDataIsLoaded || !dragDataIsLoaded || !venueDataIsLoaded || !cruiseDataIsLoaded || !peopleDataIsLoaded || !tagsDataIsLoaded || !notificationDataIsLoaded || !imagesAreLoaded)
        //     )
            
        //    ));

        //    console.log('what is changing 17',((!openCitySelector && festDataIsLoaded && partyDataIsLoaded && dragDataIsLoaded && venueDataIsLoaded && cruiseDataIsLoaded && peopleDataIsLoaded && tagsDataIsLoaded && notificationDataIsLoaded)
        //    &&
        //    imagesAreLoaded))
        // })


  return (
    <IonApp>

    {/* <IonToast
        isOpen={showToast}
        message="No internet connection. Please check your network."
        duration={3000}
        position="top"
        color="dark"
      />
      <IonToast
        isOpen={showSlowNetworkToast}
        message="Slow internet connection detected. App initialisation may take longer."
        duration={3000}
        position="top"
        color="dark"
        onDidDismiss={() => setShowSlowNetworkToast(false)}
      /> */}
      
      {
        appIsSuspended
        &&
        <div className="suspendscreen">
           
            <IonModal
            isOpen={appIsSuspended}
            backdropDismiss={false}
            className="custommod"
            >
              <div className="updatebox">
                <div className="updatehead">App Suspended</div>
                <div className="updatemsg">The Gay Agenda is currently undergoing maintenance and will be back online as soon as possible. Apologies for the inconvenience.</div>
              </div>
            </IonModal>
            <div className="abstribox">
              <img className="tripink" src={newlogo} alt="" />
             
            </div>
           
        </div>
      }

    
      {
        !versionIsCorrect
        &&
        <div className="suspendscreen">
          <IonAlert
          isOpen={!versionIsCorrect}
          backdropDismiss={false}
          
          header="Update Required"
          message="Please update the app to the latest version."
          className="customalert"
          buttons={[
            {
              text: 'Update',
              role: 'confirm',
              handler: () => {
                handleClickUpdate();
                openCapacitorSite();
                return false;
              }
            }
          ]}
          >
          </IonAlert>
            <div className="splashupper"></div>
            <div className="abstribox">
              <img className="tripink" src={newlogo} alt="" />
            </div>
        </div>
      }

      


      {
      ((!imagesAreLoaded) || !cityListN)
      &&
      <div className="splashscreen">
            <div className="splashupper"></div>
            <div className="abstribox">
              <img className="tripink" src={newlogo} alt="" />
              </div>
                  <div className="splashlower">
                    {/* Loading */}
                  {
                  firstLoad
                  &&
                        <div className="loadingtxt">{downloadProgress}%</div>
                  }
                   {
                  firstLoad
                  &&
                        <div className="containerforprogressbar">  
                                <div className="innerbar" style={{ width: `${downloadProgress}%` }}></div>
                        </div>
                  }
                  </div>
      </div>
      }

        {
          isLanding
          &&
          <LandingPage
          setIsLanding={setIsLanding}
          />
        }


      {!isLanding &&
      openCitySelector && imagesAreLoaded && cityListN
      &&
      <div className="splashscreen"> 
          <div className="fadeanim">
            <div className="splashupper"></div>
              <div className="abstribox">
              <img className="tripink" src={newlogo} alt="" />
            </div>
              <div className="splashlower">
                {/* Loading */}
                  {
                  firstLoad
                  &&
                        <div className="loadingtxt">{downloadProgress}%</div>
                  }
                   {
                  firstLoad
                  &&
                        <div className="containerforprogressbar">
                                <div className="innerbar" style={{ width: `${downloadProgress}%` }}></div>
                        </div>
                  }
                  </div>
          </div>
          {/* <div className="image-container">
            <img src={earthImage} />
        </div> */}
          <div className="pickcitybox">
          <div className="cityonethrd">
              <img className="triang2" src={newlogo} alt="" />
                <div className="pickcity">Select a city:</div>
            <IonContent className="contmin">
              <div className="innerwrap">
                  {
                  cityListN.map((city:any, index:number) => {
                    return <div key={index} className={tempCity === city ? "citybb addpnk" : "citybb"}
                    onClick={() => handleCityChange(city)}
                    >{city}</div>
                  })
                }
              </div>
            
            </IonContent>
                    <div className="contbtm">
                    {
                        tempCity === ""
                        &&
                            <div className="splashbtn">
                                    <IonIcon icon={arrowForwardOutline} className="contarrow small" />
                          </div>
                      }
                      {
                        tempCity !== ""
                        &&
                            <div className="splashbtn2"
                            onClick={() => firstSetCity(tempCity)}
                            >
                                    <IonIcon icon={arrowForwardOutline} className="contarrow" />
                          </div>
                      }
                    </div>
            </div>
            </div>
            
      </div>
      }



        



          {
            !isLanding
            &&
          imagesAreLoaded
          &&
          !isDownloading
          &&
          (!openCitySelector
          &&
          cityListN
          &&
          (!festDataIsLoaded || !partyDataIsLoaded || !dragDataIsLoaded || !venueDataIsLoaded || !cruiseDataIsLoaded || !peopleDataIsLoaded || !tagsDataIsLoaded || !notificationDataIsLoaded)
          )
          &&
          <>
          <div className="loadingdiv">
                    LOADING
                    {/* <IonLoading spinner="circles"/> */}
              </div>
          <IonHeader>
            <IonToolbar className="transparenttoolbar">
            <div className="toolbar2">
                      <div className="abscontionmenu">
                              <IonIcon
                              icon={reorderThreeOutline}
                              className="toolbariconfade2"
                              />
                      </div>
                      <div className="abscontionmenu">
                              <IonIcon
                              icon={notificationsOutline}
                              className="toolbariconfade"
                              />
                      </div>
              <div className="abscontionmenu">
                <div className="toolbarother">
                          <img className="trihq" src={logohq} alt="" />
                </div>
              </div>
              <div className="abscontionmenu">
                                <IonIcon
                                icon={searchOutline}
                                className="toolbariconfade"
                                />    
              </div>
              <div className="abscontionmenu">
                            <IonIcon
                            icon={options}
                            className="toolbariconfade"              
                            />
                </div>
            </div>
                <div className="mgdatesoth">
                <div className="gridviewsectionheader6">
                          <div className="extbox pinkcol">
                            </div>
                  </div>
                </div>
            </IonToolbar>
          </IonHeader>
          </>
          }
            {
              !isLanding
              &&
            (!openCitySelector && festDataIsLoaded && partyDataIsLoaded && dragDataIsLoaded && venueDataIsLoaded && cruiseDataIsLoaded && peopleDataIsLoaded && tagsDataIsLoaded && notificationDataIsLoaded)
            &&
            imagesAreLoaded
            &&
            <IonReactRouter>
              <IonSplitPane when={width >= 1200} contentId="main">
              <Menu
              isWeb={isWeb}
              citySelected={citySelected.city}
              cityListN={cityListN}
              citiesNotifs={citiesNotifs}
              handleToggleCity={handleToggleCity}
              openPickerSelectCity={openPickerSelectCity}
              setDefCity={setDefCity}
              defCity={defCity}
              parties={parties}
              barsAndClubs={barsAndClubs}
              saunas={saunas}
              festivals={festivals}
              festivalsCirc={festivalsCirc}
              festivalsPr={festivalsPr}
              people={people}
              dragExpos={dragExpos}
              dragTours={dragTours}
              cruises={cruises}
              />
              <IonRouterOutlet id="main">
                <Route path="/" exact={true}>
                  <Redirect to="/parties" />
                </Route>
                <Route path="/parties" exact={true}>
                  <Parties
                  isWeb={isWeb}
                  text={"Events"}
                  gridNo={gridNo}
                  isWide={isWide}
                  parties={parties}
                  partiesFeat={partiesFeat}
                  citySelected={citySelected}
                  currentDateAndTime={currentDateAndTime}
                  likedParties={likedParties}
                  setLikedParties={setLikedParties}
                  tagsParties={tagsParties}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  viewedNotifications={viewedNotifications}
                  setViewedNotifications={setViewedNotifications}
                  viewedNotificationsAreLoaded={viewedNotificationsAreLoaded}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  />
                </Route>
               
                <Route path="/parties-fest/:festival" exact={true}>
                  <PartiesFest
                  isWeb={isWeb}
                  isWide={isWide}
                  festivals={festivals}
                  parties={parties}
                  citySelected={citySelected}
                  currentDateAndTime={currentDateAndTime}
                  likedParties={likedParties}
                  setLikedParties={setLikedParties}
                  tagsParties={tagsParties}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  viewedNotifications={viewedNotifications}
                  setViewedNotifications={setViewedNotifications}
                  viewedNotificationsAreLoaded={viewedNotificationsAreLoaded}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  />
                </Route>
                <Route path="/circuit" exact={true}>
                  <CircFests
                  isWeb={isWeb}
                  text={"Circuit Parties"}
                  isWide={isWide}
                  festivalsCirc={festivalsCirc}
                  citySelected={citySelected}
                  circCountryList={circCountryList}
                  cityCoordinates={cityCoordinates}
                  cityZoom={cityZoom}
                  currentDateAndTime={currentDateAndTime}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  viewedNotifications={viewedNotifications}
                  setViewedNotifications={setViewedNotifications}
                  viewedNotificationsAreLoaded={viewedNotificationsAreLoaded}
                  likedCircFests={likedCircFests}
                  setLikedCircFests={setLikedCircFests}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  pride={false}
                  />
                </Route>
                <Route path="/pride" exact={true}>
                  <CircFests
                  isWeb={isWeb}
                  text={"Pride Festivals"}
                  isWide={isWide}
                  festivalsCirc={festivalsPr}
                  citySelected={citySelected}
                  circCountryList={prideCountryList}
                  cityCoordinates={cityCoordinates}
                  cityZoom={cityZoom}
                  currentDateAndTime={currentDateAndTime}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  viewedNotifications={viewedNotifications}
                  setViewedNotifications={setViewedNotifications}
                  viewedNotificationsAreLoaded={viewedNotificationsAreLoaded}
                  likedCircFests={likedCircFests}
                  setLikedCircFests={setLikedCircFests}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  pride={true}
                  />
                </Route>
                <Route exact path="/parties/:id">
                  <PartyWindow
                  isWeb={isWeb}
                  parties={parties}
                  barsAndClubs={venues}
                  likedParties={likedParties}
                  setLikedParties={setLikedParties}
                  peopleOth={peopleOth}
                  currentDateAndTime={currentDateAndTime}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  handleIncrClick={handleIncrClick}
                  />
                </Route>
                <Route exact path="/circuit/:id">
                  <CircWind
                  isWeb={isWeb}
                  festivalsCirc={festivalsCirc}
                  parties={allParties}
                  barsAndClubs={venues}
                  likedParties={likedCircFests}
                  setLikedParties={setLikedCircFests}
                  peopleOth={peopleOth}
                  currentDateAndTime={currentDateAndTime}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  handleIncrClick={handleIncrClick}
                  cityListN={cityListN}
                  changeCityLogic={changeCityLogic}
                  citySelected={citySelected}
                  />
                </Route>
                <Route exact path="/pride/:id">
                  <CircWind
                  isWeb={isWeb}
                  festivalsCirc={festivalsPr}
                  parties={allParties}
                  barsAndClubs={venues}
                  likedParties={likedCircFests}
                  setLikedParties={setLikedCircFests}
                  peopleOth={peopleOth}
                  currentDateAndTime={currentDateAndTime}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  handleIncrClick={handleIncrClick}
                  cityListN={cityListN}
                  changeCityLogic={changeCityLogic}
                  citySelected={citySelected}
                  />
                </Route>
                <Route exact path="/barsandclubs">
                  <BarsAndClubs
                  isWeb={isWeb}
                  text={"Bars & Clubs"}
                  isWide={isWide}
                  barsAndClubs={barsAndClubs}
                  citySelected={citySelected}
                  cityCoordinates={cityCoordinates}
                  cityZoom={cityZoom}
                  tagsBC={tagsBC}
                  setTagsBC={setTagsBC}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  viewedNotifications={viewedNotifications}
                  setViewedNotifications={setViewedNotifications}
                  viewedNotificationsAreLoaded={viewedNotificationsAreLoaded}
                  likedBarsAndClubs={likedBarsAndClubs}
                  setLikedBarsAndClubs={setLikedBarsAndClubs}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  />
                </Route>
                <Route exact path="/saunas">
                  <Saunas
                  text={"Saunas"}
                  saunas={saunas}
                  citySelected={citySelected}
                  cityCoordinates={cityCoordinates}
                  cityZoom={cityZoom}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  viewedNotifications={viewedNotifications}
                  setViewedNotifications={setViewedNotifications}
                  viewedNotificationsAreLoaded={viewedNotificationsAreLoaded}
                  />
                </Route>
                <Route exact path="/barsandclubs/:id">
                  <BarAndClubWindow
                  isWeb={isWeb}
                  gridNo={gridNo}
                  barsAndClubs={barsAndClubs}
                  parties={parties}
                  currentDateAndTime={currentDateAndTime}
                  currentDateAndTimePlusTwoWeeks={currentDateAndTimePlusTwoWeeks}
                  likedBarsAndClubs={likedBarsAndClubs}
                  setLikedBarsAndClubs={setLikedBarsAndClubs}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  />
                </Route>
                <Route exact path="/saunas/:id">
                  <SaunaWindow
                  gridNo={gridNo}
                  saunas={saunas}
                  parties={parties}
                  currentDateAndTime={currentDateAndTime}
                  currentDateAndTimePlusTwoWeeks={currentDateAndTimePlusTwoWeeks}
                  />
                </Route>
                <Route exact path="/people">
                  <People
                  isWeb={isWeb}
                  isWide={isWide}
                  text={"Performers"}
                  people={people}
                  citySelected={citySelected}
                  tagsPeople={tagsPeople}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  viewedNotifications={viewedNotifications}
                  setViewedNotifications={setViewedNotifications}
                  viewedNotificationsAreLoaded={viewedNotificationsAreLoaded}
                  likedPeople={likedPeople}
                  setLikedPeople={setLikedPeople}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  parties={parties}
                  dragExpos={dragExpos}
                  />
                </Route>
                <Route exact path="/people/:id">
                  <PersonWindow
                  isWeb={isWeb}
                  gridNo={gridNo}
                  people={people}
                  parties={parties}
                  festivalsCirc={festivalsCirc}
                  festivalsPr={festivalsPr}
                  dragExpos={dragExpos}
                  dragTours={dragTours}
                  cruises={cruises}
                  likedPeople={likedPeople}
                  setLikedPeople={setLikedPeople}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  citySelected={citySelected}
                  />
                </Route>
                <Route exact path="/drag">
                  <DragTours
                  text={"Drag Events"}
                  dragExpos={dragExpos}
                  citySelected={citySelected}
                  currentDateAndTime={currentDateAndTime}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  viewedNotifications={viewedNotifications}
                  setViewedNotifications={setViewedNotifications}
                  viewedNotificationsAreLoaded={viewedNotificationsAreLoaded}
                  likedDragExpos={likedDragExpos}
                  setLikedDragExpos={setLikedDragExpos}
                  />
                </Route>
                <Route exact path="/drag/:id">
                  <DragTourWindow
                  dragExpos={dragExpos}
                  barsAndClubs={venues}
                  likedDragExpos={likedDragExpos}
                  setLikedDragExpos={setLikedDragExpos}
                  // tvShows={tvShows}
                  people={people}
                  peopleOth={peopleOth}
                  currentDateAndTime={currentDateAndTime}
                  handleIncrClick={handleIncrClick}
                  />
                </Route>
                <Route exact path="/cruises">
                  <Cruises
                  isWeb={isWeb}
                  text={"Cruises"}
                  isWide={isWide}
                  cruises={cruises}
                  citySelected={citySelected}
                  currentDateAndTime={currentDateAndTime}
                  tagsCruises={tagsCruises}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  viewedNotifications={viewedNotifications}
                  setViewedNotifications={setViewedNotifications}
                  viewedNotificationsAreLoaded={viewedNotificationsAreLoaded}
                  likedCruises={likedCruises}
                  setLikedCruises={setLikedCruises}
                  cruiseCountryList={cruiseCountryList}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  />
                </Route>
                <Route exact path="/cruises/:id">
                  <CruiseWindow
                  isWeb={isWeb}
                  gridNo={gridNo}
                  cruises={cruises}
                  likedCruises={likedCruises}
                  setLikedCruises={setLikedCruises}
                  people={people}
                  currentDateAndTime={currentDateAndTime}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  handleIncrClick={handleIncrClick}
                  />
                </Route>
              </IonRouterOutlet>
              </IonSplitPane>
            </IonReactRouter>       
            }
    </IonApp>
 
  );
};

export default App;
